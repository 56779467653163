import React from 'react'
import { Col, } from "react-bootstrap";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
function ProductSkeleton() {

    return (
        <>
            <Col className='d-flex flex-direction-row flex-wrap justify-content-center  '>

                <div className="card card-custom mb-4 me-sm-0 me-md-4  " style={{borderRadius:'25px',}} >
                    <Skeleton style={{ height: '400px', width: '290px', }} ></Skeleton>
                </div>
                <div className="card card-custom mb-4 me-sm-0 me-md-4  " style={{borderRadius:'25px',}} >
                    <Skeleton style={{ height: '400px', width: '290px', }} ></Skeleton>
                </div>
                <div className="card card-custom mb-4 me-sm-0 me-md-4  " style={{borderRadius:'25px',}} >
                    <Skeleton style={{ height: '400px', width: '290px', }} ></Skeleton>
                </div>
                <div className="card card-custom mb-4 me-sm-0 me-md-4  " style={{borderRadius:'25px',}} >
                    <Skeleton style={{ height: '400px', width: '290px', }} ></Skeleton>
                </div>
                <div className="card card-custom mb-4 me-sm-0 me-md-4  " style={{borderRadius:'25px',}} >
                    <Skeleton style={{ height: '400px', width: '290px', }} ></Skeleton>
                </div>
                <div className="card card-custom mb-4 me-sm-0 me-md-4  " style={{borderRadius:'25px',}} >
                    <Skeleton style={{ height: '400px', width: '290px', }} ></Skeleton>
                </div>
                <div className="card card-custom mb-4 me-sm-0 me-md-4  " style={{borderRadius:'25px',}} >
                    <Skeleton style={{ height: '400px', width: '290px', }} ></Skeleton>
                </div>
                <div className="card card-custom mb-4 me-sm-0 me-md-4  " style={{borderRadius:'25px',}} >
                    <Skeleton style={{ height: '400px', width: '290px', }} ></Skeleton>
                </div>

            </Col>
        </>
    )
}
export default ProductSkeleton;