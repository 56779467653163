import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { IoLocationSharp } from "react-icons/io5";
import { GoMail } from "react-icons/go";
import { FiPhoneCall } from "react-icons/fi";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
import "./Contact.css";
function Contact() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [addData, setAddData] = useState({
    name: "",
    nameerr: "",
    email: "",
    emailerr: "",
    number: null,
    phone_number: "",
    phone_numbererr: "",
    message: "",
    messageerr: "",
  });

  // let navigate = useNavigate()

  // API for contact us
  const handleSubmit = (e) => {
    e.preventDefault();
    let error = {
      status: 0,
      name: "",
      phone_number: "",
      email: "",
      message: "",
    };
    if (addData.name === "") {
      error.name = "Please enter full name";
      error.status = 1;
    } else {
      error.name = "";
    }
    if (addData.phone_number === "") {
      error.phone_number = "Please enter phone number";
      error.status = 1;
    } else {
      error.phone_number = "";
    }
    if (addData.message === "") {
      error.message = "Please enter Message";
      error.status = 1;
    } else {
      error.message = "";
    }
    if (addData.email <= 0 || addData.email === "") {
      error.email = "Please enter email";
      error.status = 1;
    } else {
      error.email = "";
    }
    setAddData({
      ...addData,
      nameerr: error.name,
      emailerr: error.email,
      phone_numbererr: error.phone_number,
      messageerr: error.message,
    });
    if (error.status === 0) {
      setLoading(true);

      let url = process.env.REACT_APP_BASEURL + "contact_us/";
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json",
        },
      };
      let data = {
        fullname: addData.name,
        email: addData.email,
        phone_no: addData.phone_number,
        message: addData.message,
      };
      axios
        .post(url, data, config)
        .then((res) => {
          setLoading(false);
          toast.success("Form Submit successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
            theme: "colored",
          });
          window.location.reload();
        })
        .catch((err) => {
          setLoading(false);
          toast.error(`${err.response.data.error}`, {
            autoClose: 1000,
            theme: "colored",
          });
        });
    }
  };
  return (
    <>
      <div className="pt-5 contact-bg" style={{ paddingBottom: "75px" }}></div>
      <Container className="mt-5 p-0">
        <div>
          <h2 className="text-center mt-5 ">Contact Us</h2>
          <div className="d-flex justify-content-center mt-5">
            <Row>
              {/* <Col className='d-flex justify-content-center' >
                                <Card style={{ width: '18rem', margin: '10px', boxShadow: '1px 2px 9px 0px #808080db' }} data-aos="fade-down" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="600">
                                    <div className='d-flex justify-content-center mt-3'>
                                        <IoLocationSharp style={{ color: '#C8811A', fontSize: '50px', border: '4px dotted #C8811A', borderRadius: '50%', padding: '8px' }}></IoLocationSharp>
                                    </div>

                                    <Card.Body className='text-center'>
                                        <Card.Title>{t("contactpage.address_heading")}</Card.Title>
                                        {t("contactpage.address")}

                                    </Card.Body>
                                </Card>
                            </Col> */}
              <Col className="d-flex justify-content-center">
                <Card
                  style={{
                    width: "58rem",
                    margin: "10px",
                    boxShadow: "1px 2px 9px 0px #808080db",
                    height: "10rem",
                  }}
                  className="sm_contact"
                >
                  <div className="d-flex justify-content-center mt-3">
                    <GoMail
                      style={{
                        color: "#C8811A",
                        fontSize: "50px",
                        border: "4px dotted #C8811A",
                        borderRadius: "50%",
                        padding: "8px",
                      }}
                    />
                  </div>

                  <Card.Body className="text-center">
                    <Card.Title>Email Us </Card.Title>
                    {/* <Card.Text> */}
                    <a href="mailto:info@poornasatya.com" target="_blank">
                      info@poornasatya.com
                    </a>
                    {/* </Card.Text> */}
                  </Card.Body>
                </Card>
              </Col>
              {/* <Col className='d-flex justify-content-center' >

                                <Card style={{ width: '18rem', margin: '10px', boxShadow: '1px 2px 9px 0px #808080db' }} >
                                    <div className='d-flex justify-content-center mt-3'>
                                        <FiPhoneCall style={{ color: '#C8811A', fontSize: '50px', border: '4px dotted #C8811A', borderRadius: '50%', padding: '8px' }} />
                                    </div>

                                    <Card.Body className='text-center'>
                                        <Card.Title>{t("contactpage.call_us")}</Card.Title>
                                        +91 9205222770<br></br>

                                    </Card.Body>
                                </Card>
                            </Col> */}
            </Row>
          </div>
          <Row className="mt-5 p-3">
            <Col lg={6} md={12} className="mt-3 mb-5">
              <iframe
                class="mb-4 me-md-3 me-sm-0  mb-lg-0"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d377565.5946626543!2d77.04157704532086!3d28.454198800142823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0x52c2b7494e204dce!2sNew%20Delhi%2C%20Delhi!5e0!3m2!1sen!2sin!4v1677491129413!5m2!1sen!2sin"
                frameborder="0"
                style={{
                  border: "0",
                  width: "100%",
                  height: "530px",
                  boxShadow: "1px 2px 9px 0px #808080db",
                }}
                allowfullscreen
              ></iframe>
            </Col>

            <Col lg={6} md={12} className="mt-3 mb-5">
              <Card
                className="ms-md-4 ms-sm-0"
                style={{ boxShadow: "1px 2px 9px 0px #808080db" }}
              >
                <Card.Body>
                  <h4>Get in Touch</h4>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter full name"
                        value={addData.name}
                        onChange={(e) => {
                          e.target.value !== ""
                            ? setAddData({
                                ...addData,
                                name: e.target.value,
                                nameerr: "",
                              })
                            : setAddData({
                                ...addData,
                                name: "",
                                nameerr: "Full name  is required",
                              });
                        }}
                      />
                      {addData.nameerr && (
                        <small className="text-danger">
                          {addData.nameerr}
                          <br />
                        </small>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={addData.email}
                        onChange={(e) => {
                          e.target.value !== ""
                            ? setAddData({
                                ...addData,
                                email: e.target.value,
                                emailerr: "",
                              })
                            : setAddData({
                                ...addData,
                                email: "",
                                emailerr: "Email  is required",
                              });
                        }}
                      />
                      {addData.emailerr && (
                        <small small className="text-danger">
                          {addData.emailerr}
                          <br />
                        </small>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Phone Number</Form.Label>
                      {/* value={number} maxLength={10} onInput={(e) => { if (e.target.value.length > e.target.maxLength) { e.target.value = e.target.value.slice(0, e.target.maxLength) }; setNumber(e.target.value) }}  */}
                      <Form.Control
                        type="number"
                        placeholder="Enter phone number"
                        maxLength={10}
                        value={addData.phone_number}
                        onChange={(e) => {
                          e.target.value !== "" ||
                          e.target.value.length > e.target.maxLength
                            ? setAddData({
                                ...addData,
                                phone_number: e.target.value.slice(
                                  0,
                                  e.target.maxLength
                                ),
                                phone_numbererr: "",
                              })
                            : setAddData({
                                ...addData,
                                phone_number: "",
                                phone_numbererr: "Phone number  is required",
                              });
                        }}
                      />
                      {addData.phone_numbererr && (
                        <small className="text-danger">
                          {addData.phone_numbererr}
                          <br />
                        </small>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Message</Form.Label>
                      <textarea
                        className="form-control p-4"
                        placeholder="Enter Message"
                        value={addData.message}
                        onChange={(e) => {
                          e.target.value !== ""
                            ? setAddData({
                                ...addData,
                                message: e.target.value,
                                messageerr: "",
                              })
                            : setAddData({
                                ...addData,
                                message: "",
                                messageerr: "Message  is required",
                              });
                        }}
                      ></textarea>
                      {addData.messageerr && (
                        <small small className="text-danger">
                          {addData.messageerr}
                          <br />
                        </small>
                      )}
                    </Form.Group>
                    <div className="d-flex justify-content-center">
                      {loading ? (
                        <Button
                          style={{
                            background: "#034053",
                            border: "#034053",
                            paddingLeft: "30px",
                            paddingRight: "30px",
                            paddingTop: "14px",
                            paddingBottom: "14px",
                          }}
                          type="submit"
                          disabled
                        >
                          {" "}
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          Submit...
                        </Button>
                      ) : (
                        <Button
                          style={{
                            background: "#034053",
                            border: "#034053",
                            paddingLeft: "30px",
                            paddingRight: "30px",
                            paddingTop: "14px",
                            paddingBottom: "14px",
                          }}
                          type="submit"
                        >
                          Submit
                        </Button>
                      )}
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}
export default Contact;
