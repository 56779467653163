import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom";
import MetaTable from "./components/Table/MetaTable";
import Products from "./components/Products/Products";
import RootLayout from "./components/RootLayout";
import Signup from "./components/Signup/Signup";
import VerifyOtp from "./components/VerifyOtp/VerifyOtp";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import SetNewPasseord from "./components/SetNewPassword/SetNewPasseord";
import ProductDetails from "./components/ProductDetails/ProductDetails";
import "react-toastify/dist/ReactToastify.css";
import Homepage from "./components/Homepage/Homepage";
import "react-loading-skeleton/dist/skeleton.css";
import VerifyOtpMob from "./components/VerifyOtpMob/VerifyOtpMob";
import UpdateProfile from "./components/UpdateProfile/UpdateProfile";
import Compare from "./components/Compare/Compare";
import NotFound from "./components/404/NotFound";
import PrivateRoutes from "./components/Routes/PrivateRoutes";
import PrivateBeforeLogin from "./components/Routes/PrivateBeforeLogin";
import Contact from "./components/Contact/Contact";
import LoggingInModal from "./components/LoggingInModal/LoggingInModal";
import FullLayout from "./components/FullLayout";
import About from "./components/About/About";
import { Privacypolicy } from "./components/PrivacyPolicy/Privacypolicy";
import Blogs from "./components/Blogs/Blogs";
import BlogInfo from "./components/Blogs/BlogInfo";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<RootLayout />}>
        {/* here index means the very first component to load when somone visits "/" */}
        <Route index element={<Homepage />} />
        {/* <Route path="table/:public_id" element={<MetaTable />} /> */}
        {/* <Route path="table" element={<MetaTable />} /> */}
        <Route path="contact" element={<Contact />} />
        <Route path="blogs" element={<Blogs />} />
        <Route path="bloginfo/:value" element={<BlogInfo />} />
        <Route path="about" element={<About />} />
        <Route path="privacypolicy" element={<Privacypolicy />} />
        <Route path="products/:string" element={<Products />} />
        {/* <Route path="reset-password" element={<ResetPassword />} /> */}
        {/* <Route path="set-password/:token" element={<SetNewPasseord />} /> */}

        {/*Private Routes only accessible after login  */}
        <Route element={<PrivateRoutes />}>
          <Route path="update-profile" element={<UpdateProfile />} />
          {/* <Route path="set-password" element={<SetNewPasseord />} /> */}
          <Route
            path="product/:public_id/:attr_id"
            element={<ProductDetails />}
          />
          <Route path="product/:public_id" element={<ProductDetails />} />
          <Route path="compare/:id/:id/*" element={<Compare />} />
        </Route>
        {/* Routes only accessible before login  */}
        <Route element={<PrivateBeforeLogin />}>
          {/* <Route path="signup" element={<Signup />} /> */}
          {/* <Route path='/login' element={<Login />} /> */}
          <Route path="login" element={<LoggingInModal />} />
          {/* <Route path="verify-otp" element={<VerifyOtp />} /> */}
          <Route path="verify-mob-otp/:public_id" element={<VerifyOtpMob />} />
        </Route>
      </Route>
      <Route path="/notfound" element={<NotFound />} />
      <Route path="*" element={<Navigate to={"/notfound"} />} />
    </>
  )
  // Need to basename here
  // {
  //   basename: "/hoomee",
  // }
);

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
