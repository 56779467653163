import React from 'react'
import { Modal } from 'react-bootstrap'
import { CircleLoader } from 'react-spinners'

function LoggingInModal(props) {
    return (
        <Modal
            show={props?.flag}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Body className='p-3'>
                <div className='d-flex justify-content-center align-items-center'>
                    <CircleLoader
                        color="#d67e36"
                        size={60}
                    />
                </div>
                <h5 className='m-0 text-center mt-3 '>
                    Logging in...
                </h5>
            </Modal.Body>

        </Modal>
    )
}

export default LoggingInModal