import React from "react";
import { Nav, NavLink } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { useTranslation } from "react-i18next";
import LogoShort from "../../assets/images/footerImglogo.png";
import "./Footer.css";
import { BsFacebook, BsYoutube, BsInstagram } from "react-icons/bs";
import { AiFillTwitterCircle, AiOutlineInstagram } from "react-icons/ai";
import { FaLinkedinIn, FaQuora } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

function Footer() {
  const { t } = useTranslation();
  let navigate = useNavigate();

  return (
    <>
      <div style={{ background: " #D6D6D6" }}>
        <marquee width="100%" direction="left">
          <span style={{ fontWeight: "bold", fontSize: "16px" }}>
            Disclaimer :-
          </span>{" "}
          All information displayed on this website/ app. has been collated on
          the basis of information provided on the website of the manufacturer,
          reseller or the labelling of the product itself and is for reference
          purpose only. While we at "True Lineage India Pvt. Ltd." take due care
          to ensure the accuracy of information, we disclaim all legal liability
          in case any information is found to be incorrect due to factors beyond
          our control.
        </marquee>
        <Navbar expand="lg" style={{ background: " #D6D6D6" }}>
          <Container className="sm_scr">
            <Nav className="sm_scr_logo d-inline-block align-top">
              <NavLink>
                <Link
                  className="p sm_scr_p"
                  to="/privacypolicy"
                  onClick={() => {
                    navigate("/privacypolicy");
                  }}
                >
                  Privacy Policy
                </Link>
              </NavLink>
              <Navbar.Brand>
                <img alt="Short logo" src={LogoShort} />
              </Navbar.Brand>
            </Nav>
            <Nav>
              <div className="row text-center">
                <div
                  className="h6 col-12"
                  style={{ color: "black", marginBottom: "10px" }}
                >
                  Contact us: Questions or collaboration? Reach out to us at{" "}
                  <a href="mailto:info@poornasatya.com" target="_blank">
                    info@poornasatya.com
                  </a>
                </div>
                <div className="h6 col-12" style={{ color: "black" }}>
                  Copyright © {new Date().getFullYear()} All Rights Reserved.
                </div>
                <div className="h6 col-12" style={{ color: "black" }}>
                 Proudly owned by True Lineage India Private Limited.
                </div>
              </div>
            </Nav>
            <Nav className="d-flex flex-row">
              <a
                href="https://www.instagram.com/ps_thecompletetruth/"
                style={{
                  textDecoration: "none",
                  color: "#DE8A0E",
                  fontSize: "20px",
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <BsInstagram className="me-2" />
              </a>
              <a
                href="https://www.linkedin.com/company/poorna-satya"
                style={{
                  textDecoration: "none",
                  color: "#DE8A0E",
                  fontSize: "20px",
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn className="me-2" />
              </a>
              <a
                href="https://www.quora.com/profile/Poorna-Satya-1"
                style={{
                  textDecoration: "none",
                  color: "#DE8A0E",
                  fontSize: "20px",
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaQuora className="me-2" />
              </a>
              <a
                href="https://www.youtube.com/@PoornaSatyaTheCompleteTruth/featured"
                style={{
                  textDecoration: "none",
                  color: "#DE8A0E",
                  fontSize: "20px",
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <BsYoutube className="me-2" />
              </a>
              <a
                href="https://www.facebook.com/ps.thecompletetruth/"
                style={{
                  textDecoration: "none",
                  color: "#DE8A0E",
                  fontSize: "20px",
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <BsFacebook className="me-2" />
              </a>
              <a
                href="https://twitter.com/poorna__satya"
                style={{
                  textDecoration: "none",
                  color: "#DE8A0E",
                  fontSize: "20px",
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillTwitterCircle className="me-2" />
              </a>
            </Nav>
          </Container>
        </Navbar>
      </div>
    </>
  );
}

export default Footer;
