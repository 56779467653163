import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import ProductDetailSkeleton from "../skeleton/productDetailSkeleton";
import ProductPlaceholderRound from "../../assets/images/prod_placeholder_round.png";
import "./ProductDetails.css";
import { toast } from "react-toastify";
import { globalCheckErr } from "../../Check401";
import moment from "moment";
import Accordion from "react-bootstrap/Accordion";
import { AiOutlineSearch } from "react-icons/ai";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";

function ProductDetails() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [allergyInfo, setAllergyInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [certificationInformation, setCertificationInformation] = useState("");
  let { public_id, attr_id } = useParams();
  const [searchParams] = useSearchParams();
  // API for product details
  const getSpecificData = () => {
    setLoading(true);
    if (secureLocalStorage.getItem("prod_id")) {
      secureLocalStorage.removeItem("prod_id");
    }
    let url =
      process.env.REACT_APP_BASEURL +
      `products/${public_id}/?attr_id=${attr_id}`;
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
        Authorization: secureLocalStorage.getItem("token"),
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        setLoading(false);
        setData(res.data);
        var arr = res.data[0].allergen_info.split(",");
        setAllergyInfo(arr);
        if (res.data[0]?.certification_information !== null) {
          var certficationInformation =
            (res.data[0]?.certification_information).replace(/<br>/g, "");
          setCertificationInformation(certficationInformation);
        } else if (res.data[0]?.certification_information === null) {
          setCertificationInformation("Not Available");
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 401) {
          globalCheckErr();
          navigate("/");
        } else {
          toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
        }
      });
  };
  useEffect(getSpecificData, [public_id]);
  const { t } = useTranslation();

  const handleVeg = () => {
    navigate(
      `/products/${localStorage.getItem("search")}?veg-nonveg=${
        data[0]?.product_veg_nonveg
      }`
    );
    localStorage.setItem("product_veg/non-veg", data[0]?.product_veg_nonveg);
  };

  const handleNonveg = () => {
    navigate(
      `/products/${localStorage.getItem("search")}?veg-nonveg=${
        data[0]?.product_veg_nonveg
      }`
    );
    localStorage.setItem("product_veg/non-veg", data[0]?.product_veg_nonveg);
  };
  const handlerelgiousSentiments = () => {
    navigate(
      `/products/${localStorage.getItem("search")}?religious_sentiments=${
        data[0]?.religious_sentiments
      }`
    );
    localStorage.setItem("religious_sentiments", data[0]?.religious_sentiments);
  };

  const handleFoodCategory = (foodCategory) => {
    localStorage.setItem("food_category", foodCategory);
    navigate(`/products/${localStorage.getItem("food_category")}`);
  };

  return (
    <>
      <div className="pt-5 contact-bg" style={{ paddingBottom: "75px" }}></div>
      <Container fluid className="p-0 m-0  product-background pt-4  ">
        {loading ? (
          <ProductDetailSkeleton />
        ) : (
          <>
            <Container className=" mb-2  d-flex justify-content-center py-5">
              <Row
                style={{
                  background: "#F4F4F4",
                  padding: "30px",
                  border: "1px solid #C9C9C9",
                  borderRadius: "5px",
                }}
              >
                <div className="d-flex justify-content-between">
                  <div>
                    <button
                      className="btn btn-sm text-white"
                      onClick={() => {
                        localStorage.removeItem("homePageFlag");
                        if (searchParams.get("homePageFlag")) {
                          navigate("/");
                        } else {
                          navigate(
                            `/products/${localStorage.getItem("search")}`
                          );
                        }
                      }}
                      style={{ backgroundColor: "#5CB85C" }}
                    >
                      Go Back
                    </button>
                  </div>
                  <div>
                    {data[0]?.product_veg_nonveg === "veg" ? (
                      <img
                        src={require("../../assets/images/veg-btn.png")}
                      ></img>
                    ) : data[0]?.product_veg_nonveg === "non" ? (
                      <img src={require("../../assets/images/non.png")}></img>
                    ) : null}
                  </div>
                </div>
                <Col
                  className="d-flex  justify-content-lg-start justify-content-center align-items-center"
                  lg={5}
                >
                  {/* <div className='div-border-custom'> */}
                  {/* <Col className='d-flex justify-content-center  '>
                                    <ul style={{ listStyle: 'none', }} > */}
                  {/* <a href='#nutrition'> <li ><Button style={{ backgroundColor: '#034053', border: 'none' ,width:'100%' }}>Carbohydrate</Button></li></a>
                                        <a href='#nutrition'> <li className='mt-2'><Button style={{ backgroundColor: '#034053', border: 'none' ,width:'100%' }}>Sodium</Button></li></a>
                                        <a href='#nutrition'> <li className='mt-2'><Button style={{ backgroundColor: '#034053', border: 'none' ,width:'100%' }}>Sugar </Button></li></a> */}

                  {/* {data[0]?.product_veg_nonveg === "veg" ? <a >   <li className='mt-2'><Button style={{ backgroundColor: '#008640', border: 'none', width: '100%' }} onClick={() => { handleVeg() }} >Veg</Button></li></a> : data[0]?.product_veg_nonveg === "non" ? <a >   <li className='mt-2'><Button style={{ backgroundColor: '#840808', border: 'none', width: '100%' }} onClick={() => { handleNonveg() }} >Non-veg</Button></li></a> : null}
                                        {
                                            data[0]?.religious_sentiments === "Yes" ? <a >   <li className='mt-2'><Button style={{ backgroundColor: '#840808', border: 'none', width: '100%' }} onClick={() => { handlerelgiousSentiments() }}>Trans Fat </Button></li></a> : data[0]?.religious_sentiments === "No" ? <a >   <li className='mt-2'><Button style={{ backgroundColor: '#008640', border: 'none', width: '100%' }} onClick={() => { handlerelgiousSentiments() }}>Non-trans Fat </Button></li></a> : null
                                        }

                                    </ul>
                                </Col> */}
                  <div className="img-bg">
                    <img
                      alt="Product "
                      src={
                        data[0]?.img !== null || ""
                          ? data[0]?.img
                          : ProductPlaceholderRound
                      }
                      className="img-size"
                    />
                  </div>

                  {/* </div> */}
                </Col>
                <Col
                  className="d-flex justify-content-start  align-items-center mt-2 p-3 ps-lg-5 ps-0"
                  lg={7}
                >
                  {/* **************  Product name ************** */}
                  <Col>
                    <Row>
                      <Col>
                        <span
                          style={{
                            color: "#003366",
                            fontSize: "23px",
                            fontWeight: "500",
                          }}
                        >
                          Product Name{" "}
                        </span>
                      </Col>
                      {/* <Col className='justify-content-end d-flex p-0'>
                                            <button className='btn ' style={{ maxHeight: '20px', paddingTop: '0px', paddingBottom: '0px', background: '#f5e5e2', border: '1px solid #f5e5e2', fontSize: '10px', color: '#808080a1' }}>Last Update :- {moment(data[0]?.created_at).format("DD/MM/YYYY")}  </button>
                                        </Col> */}
                    </Row>
                    <Row>
                      <p style={{ fontSize: "26px", fontWeight: "500" }}>
                        {data[0]?.product_name}{" "}
                      </p>
                    </Row>
                    <Row className="mt-4">
                      <span
                        style={{
                          color: "#5CB85C",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        Product Description
                      </span>
                    </Row>
                    <Row className="mt-2">
                      <p style={{ color: "#877979" }}>
                        {data[0]?.product_description === "Not Available"
                          ? data[0].product_name
                          : data[0]?.product_description}
                      </p>
                    </Row>
                    <Row className="mt-4">
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#5CB85C",
                        }}
                      >
                        Brand-
                        <span className="text-uppercase">
                          {data[0]?.brand_name
                            ? data[0]?.brand_name
                            : "Not Available"}{" "}
                        </span>
                        {data[0]?.brand_logo ? (
                          <img
                            src={data[0]?.brand_logo}
                            style={{
                              width: "30px",
                              height: "30px",
                              marginLeft: "5px",
                            }}
                          ></img>
                        ) : (
                          <img
                            src={require("../../assets/images/no-logo-featured.png")}
                            style={{
                              width: "30px",
                              height: "30px",
                              marginLeft: "5px",
                            }}
                          ></img>
                        )}
                      </span>
                      {/* <p>{data[0]?.product_veg_nonveg === "veg" ? <>Veg</> : data[0]?.product_veg_nonveg === "non" ? <>Non-veg</> : null}
                                        </p> */}
                      <Col className="d-flex flex-lg-row flex-md-row flex-column mt-3 jusrify-content-start">
                        {data[0]?.food_category === "Not Available" ? null : (
                          <div
                            className="d-flex justify-content-center  align-items-center p-4 ps-2 py-1 m-2 "
                            style={{
                              minWidth: "80px",
                              height: "auto",
                              minHeight: "auto",
                              backgroundColor: "#fff",
                              flexGrow: 0,
                              flexShrink: 0,
                              flexBasis: "auto",
                              borderRadius: "4px",
                            }}
                          >
                            <img
                              src={require("../../assets/images/food_icon.png")}
                              className="me-2"
                            ></img>
                            <div className="d-flex flex-column">
                              <div>
                                {" "}
                                <span style={{ color: "#003366" }}>
                                  Food Category
                                </span>
                              </div>
                              <div>
                                <span style={{ color: "#6C6965" }}>
                                  {data[0]?.food_category
                                    ? data[0]?.food_category
                                    : "Not Available"}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                        {data[0]?.religious_sentiments ===
                        "Not Available" ? null : (
                          <div
                            className="d-flex justify-content-center align-items-center p-4 py-1 m-2"
                            style={{
                              minWidth: "80px",
                              height: "auto",
                              backgroundColor: "#fff",
                              flexGrow: 0,
                              flexShrink: 0,
                              flexBasis: "auto",
                              borderRadius: "4px",
                            }}
                          >
                            <img
                              src={require("../../assets/images/transfat_icon.png")}
                              className="me-2"
                            ></img>
                            <div className="d-flex flex-column">
                              <div>
                                {" "}
                                <span style={{ color: "#003366" }}>
                                  {data[0]?.religious_sentiments === "No"
                                    ? "Halal"
                                    : data[0]?.religious_sentiments === "Yes"
                                    ? "Non Halal"
                                    : "Not Available"}
                                </span>
                                {data[0]?.halal_certification_type ===
                                "nan" ? null : (
                                  <Tooltip
                                    title={
                                      data[0]?.halal_certification_type ===
                                      "nan"
                                        ? null
                                        : data[0]?.halal_certification_type
                                    }
                                  >
                                    <IconButton style={{ color: "#5CB85C" }}>
                                      <InfoIcon className="mt-0" />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </Col>
                      {/* <p>

                                            {
                                                data[0]?.religious_sentiments === "Yes" ? <>
                                                    Trans fat
                                                </> : <>
                                                    Non-trans fat
                                                </>
                                            }
                                        </p> */}
                    </Row>
                    <Row className="mt-2 justify-content-center justify-content-lg-start ">
                      {/* {
                                            allergyInfo.map((item, index) => {
                                                return (

                                                    <div className='d-flex justify-content-center align-items-center text-white me-3  flex-wrap' style={{ width: "auto", minWidth: '150px', height: "47px", backgroundColor: '#C8811A', borderRadius: "8px", margin: '5px' }}>
                                                        {item}
                                                    </div>
                                                )
                                            })
                                        } */}
                      <Col className="justify-content-end d-flex p-0 mt-3">
                        <img
                          src={require("../../assets/images/update_icon.png")}
                          className="me-1"
                        ></img>{" "}
                        <span
                          style={{
                            maxHeight: "20px",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            fontSize: "14px",
                            color: "#958383",
                          }}
                        >
                          Last Update :-{" "}
                          {moment(data[0]?.created_at).format("DD/MM/YYYY")}{" "}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Col>
              </Row>
            </Container>
            <Container
              className="mb-5"
              style={{ paddingRight: "52px", paddingLeft: "52px" }}
            >
              <Row className="d-flex justify-content-center align-items-center">
                {data[0]?.product_veg_nonveg === "Not Available" ? null : (
                  <Col lg={4}>
                    <div className="d-flex justify-content-start align-items-center p-4 fs-5 border border-2 border-secondary rounded-4 mt-2 hover_bg">
                      <AiOutlineSearch />
                      <span
                        onClick={() => {
                          if (data[0]?.product_veg_nonveg === "veg") {
                            handleVeg();
                          } else if (data[0]?.product_veg_nonveg === "non") {
                            handleNonveg();
                          }
                        }}
                      >
                        <Link className="ms-2 text-decoration-none hover_bg_tx">
                          {data[0]?.product_veg_nonveg === "veg"
                            ? "Veg"
                            : data[0]?.product_veg_nonveg === "non"
                            ? "Non-veg"
                            : null}
                        </Link>
                      </span>
                    </div>
                  </Col>
                )}
                {data[0]?.religious_sentiments === "Not Available" ? null : (
                  <Col lg={4}>
                    <div className="d-flex justify-content-start align-items-center p-4 fs-5 border border-2 border-secondary ms-1 rounded-4 mt-2 hover_bg">
                      <AiOutlineSearch />
                      <span
                        onClick={() => {
                          handlerelgiousSentiments();
                        }}
                      >
                        <Link className="ms-2 text-decoration-none hover_bg_tx">
                          {data[0]?.religious_sentiments === "Yes"
                            ? "Non Halal"
                            : data[0]?.religious_sentiments === "No"
                            ? "Halal"
                            : ""}
                        </Link>
                      </span>
                    </div>
                  </Col>
                )}
                {data[0]?.food_category === "Not Available" ? null : (
                  <Col lg={4}>
                    <div className="d-flex justify-content-start align-items-center p-4 fs-5 border border-2 border-secondary ms-1 rounded-4 mt-2 hover_bg">
                      <AiOutlineSearch />
                      <span
                        onClick={() => {
                          handleFoodCategory(data[0]?.food_category);
                        }}
                      >
                        <Link className="ms-2 text-decoration-none hover_bg_tx ">
                          {data[0]?.food_category}
                        </Link>
                      </span>
                    </div>
                  </Col>
                )}
              </Row>
            </Container>
            {certificationInformation === "nan" &&
            data[0]?.lic_no === "Not Available" ? null : (
              <Container
                fluid
                className="mb-4 d-flex justify-content-center"
                id="nutrition"
                // style={{ paddingRight: '65px', paddingLeft: '65px' }}
              >
                <Col lg={8}>
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <Row>
                          <span
                            style={{ fontSize: "20px", fontWeight: "500" }}
                            className="text-center"
                          >
                            Certification
                          </span>
                        </Row>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row className="d-flex  mt-2">
                          {/* **************  Fassi Certification ************** */}
                          <Col>
                            <div className="d-flex  justify-content-around flex-wrap">
                              {certificationInformation === "nan" ? null : (
                                <div className="d-flex justify-content-center  align-items-center mt-2 mt-md-0 m-3">
                                  <div
                                    className="d-flex justify-content-center  align-items-center card"
                                    style={{
                                      backgroundColor: "#fff",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <div className="d-flex flex-column ">
                                      <span
                                        className="text-center pt-2"
                                        style={{
                                          color: "#003366",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Certification Detail
                                      </span>
                                      <span
                                        className="text-center"
                                        style={{ color: "#6C6965" }}
                                      >
                                        {certificationInformation}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {data[0]?.lic_no === "Not Available" ? null : (
                                <div className="d-flex justify-content-center  align-items-center sm-mt-4 mt-4 mt-md-0 m-3">
                                  <div
                                    className="d-flex justify-content-center align-items-center card "
                                    style={{
                                      minWidth: "150px",
                                      height: "55px",
                                      backgroundColor: "#fff",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <div className="d-flex flex-column ">
                                      <span
                                        style={{
                                          color: "#003366",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {" "}
                                        Lic. No.
                                      </span>
                                      <span
                                        className="text-center"
                                        style={{ color: "#6C6965" }}
                                      >
                                        {data[0]?.lic_no}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
              </Container>
            )}
            {/* **************  Nutrition information ************** */}
            {data[0]?.energy === "Not Available" &&
            data[0]?.protein === "Not Available" &&
            data[0]?.total_sugar === "Not Available" &&
            data[0]?.carbs === "Not Available" &&
            data[0]?.sugar === "Not Available" &&
            data[0]?.fiber === "Not Available" &&
            data[0]?.total_fat === "Not Available" &&
            data[0]?.saturated_fat === "Not Available" &&
            data[0]?.trans_fat === "Not Available" &&
            data[0]?.cholesterol === "Not Available" &&
            data[0]?.sodium === "Not Available" &&
            data[0]?.iron === "Not Available" ? null : (
              <Container fluid className=" d-flex justify-content-center mb-4">
                <Col lg={8}>
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <Row>
                          <span
                            style={{ fontSize: "20px", fontWeight: "500" }}
                            className="text-center"
                          >
                            Nutrition Information (Per 100g)
                          </span>
                        </Row>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row className="d-flex ">
                          <Col>
                            <div className="d-flex  justify-content-center flex-wrap">
                              {data[0]?.energy === "Not Available" ? null : (
                                <div className="d-flex justify-content-center  align-items-center mt-2  me-2">
                                  <div
                                    className="d-flex justify-content-center  align-items-center card"
                                    style={{
                                      minWidth: "120px",
                                      height: "55px",
                                      backgroundColor: "#fff",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <div className="d-flex flex-column ">
                                      <span
                                        style={{
                                          color: "#003366",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Energy
                                      </span>
                                      <span style={{ color: "#6C6965" }}>
                                        {data[0]?.energy} kcal
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {data[0]?.protein === "Not Available" ? null : (
                                <div className="d-flex justify-content-center  align-items-center mt-2  me-2">
                                  <div
                                    className="d-flex justify-content-center align-items-center card"
                                    style={{
                                      minWidth: "120px",
                                      height: "55px",
                                      backgroundColor: "#fff",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <div className="d-flex flex-column ">
                                      <span
                                        style={{
                                          color: "#003366",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Protein
                                      </span>
                                      <span style={{ color: "#6C6965" }}>
                                        {data[0]?.protein} g
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {data[0]?.total_sugar ===
                              "Not Available" ? null : (
                                <div className="d-flex justify-content-center  align-items-center mt-2  me-2">
                                  <div
                                    className="d-flex justify-content-center align-items-center card"
                                    style={{
                                      minWidth: "120px",
                                      height: "55px",
                                      backgroundColor: "#fff",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <div className="d-flex flex-column ">
                                      <span
                                        style={{
                                          color: "#003366",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Total Sugar
                                      </span>
                                      <span style={{ color: "#6C6965" }}>
                                        {data[0]?.total_sugar} g
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {data[0]?.carbs === "Not Available" ? null : (
                                <div className="d-flex justify-content-center  align-items-center mt-2  me-2">
                                  <div
                                    className="d-flex justify-content-center align-items-center card"
                                    style={{
                                      minWidth: "150px",
                                      height: "55px",
                                      backgroundColor: "#fff",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <div className="d-flex flex-column ">
                                      <span
                                        style={{
                                          color: "#003366",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Carbohydrate
                                      </span>
                                      <span style={{ color: "#6C6965" }}>
                                        {data[0]?.carbs} g
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {data[0]?.sugar === "Not Available" ? null : (
                                <div className="d-flex justify-content-center  align-items-center mt-2  me-2">
                                  <div
                                    className="d-flex justify-content-center align-items-center card"
                                    style={{
                                      minWidth: "155px",
                                      height: "55px",
                                      backgroundColor: "#fff",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <div className="d-flex flex-column ">
                                      <span
                                        style={{
                                          color: "#003366",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Sugar (Sucrose)
                                      </span>
                                      <span style={{ color: "#6C6965" }}>
                                        {data[0]?.sugar} g
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {data[0]?.fiber === "Not Available" ? null : (
                                <div className="d-flex justify-content-center  align-items-center mt-2  me-2">
                                  <div
                                    className="d-flex justify-content-center align-items-center card"
                                    style={{
                                      minWidth: "120px",
                                      height: "55px",
                                      backgroundColor: "#fff",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <div className="d-flex flex-column ">
                                      <span
                                        style={{
                                          color: "#003366",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Fiber
                                      </span>
                                      <span style={{ color: "#6C6965" }}>
                                        {data[0]?.fiber} g
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {data[0]?.total_fat === "Not Available" ? null : (
                                <div className="d-flex justify-content-center  align-items-center mt-2  me-2">
                                  <div
                                    className="d-flex justify-content-center align-items-center card"
                                    style={{
                                      minWidth: "130px",
                                      height: "55px",
                                      backgroundColor: "#fff",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <div className="d-flex flex-column ">
                                      <span
                                        style={{
                                          color: "#003366",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Total Fat
                                      </span>
                                      <span style={{ color: "#6C6965" }}>
                                        {data[0]?.total_fat} g
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {data[0]?.saturated_fat ===
                              "Not Available" ? null : (
                                <div className="d-flex justify-content-center  align-items-center mt-2  me-2">
                                  <div
                                    className="d-flex justify-content-center align-items-center card"
                                    style={{
                                      minWidth: "150px",
                                      height: "55px",
                                      backgroundColor: "#fff",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <div className="d-flex flex-column ">
                                      <span
                                        style={{
                                          color: "#003366",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Saturated Fat
                                      </span>
                                      <span style={{ color: "#6C6965" }}>
                                        {data[0]?.saturated_fat} g
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {data[0]?.trans_fat === "Not Available" ? null : (
                                <div className="d-flex justify-content-center  align-items-center mt-2  me-2">
                                  <div
                                    className="d-flex justify-content-center align-items-center card"
                                    style={{
                                      minWidth: "130px",
                                      height: "55px",
                                      backgroundColor: "#fff",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <div className="d-flex flex-column ">
                                      <span
                                        style={{
                                          color: "#003366",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Trans Fat
                                      </span>
                                      <span style={{ color: "#6C6965" }}>
                                        {data[0]?.trans_fat} g
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {data[0]?.cholesterol ===
                              "Not Available" ? null : (
                                <div className="d-flex justify-content-center  align-items-center mt-2  me-2">
                                  <div
                                    className="d-flex justify-content-center align-items-center card"
                                    style={{
                                      minWidth: "130px",
                                      height: "55px",
                                      backgroundColor: "#fff",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <div className="d-flex flex-column ">
                                      <span
                                        style={{
                                          color: "#003366",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Cholestrol
                                      </span>
                                      <span style={{ color: "#6C6965" }}>
                                        {data[0]?.cholesterol} mg
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {data[0]?.sodium === "Not Available" ? null : (
                                <div className="d-flex justify-content-center  align-items-center mt-2  me-2">
                                  <div
                                    className="d-flex justify-content-center align-items-center card"
                                    style={{
                                      minWidth: "130px",
                                      height: "55px",
                                      backgroundColor: "#fff",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <div className="d-flex flex-column ">
                                      <span
                                        style={{
                                          color: "#003366",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Sodium
                                      </span>
                                      <span style={{ color: "#6C6965" }}>
                                        {data[0]?.sodium} mg
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {data[0]?.iron === "Not Available" ? null : (
                                <div className="d-flex justify-content-center  align-items-center mt-2  me-2">
                                  <div
                                    className="d-flex justify-content-center align-items-center card"
                                    style={{
                                      minWidth: "130px",
                                      height: "55px",
                                      backgroundColor: "#fff",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <div className="d-flex flex-column ">
                                      <span
                                        style={{
                                          color: "#003366",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Iron
                                      </span>
                                      <span style={{ color: "#6C6965" }}>
                                        {data[0]?.iron} mg
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
              </Container>
            )}
            {/* ************** Ingredients ************** */}
            {data[0]?.flour === "Not Available" &&
            data[0]?.type_of_oil === "Not Available" &&
            //  data[0]?.color === "Not Available" &&
            data[0]?.added_flavour === "Not Available" ? null : (
              <Container fluid className=" mb-4 d-flex justify-content-center">
                <Col lg={8}>
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <Row>
                          <span
                            style={{ fontSize: "20px", fontWeight: "500" }}
                            className="text-center"
                          >
                            Ingredients
                          </span>
                        </Row>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row className="d-flex">
                          <Col>
                            <div className="d-flex  justify-content-around flex-wrap ">
                              {data[0]?.flour === "Not Available" ? null : (
                                <div className="d-flex justify-content-center  align-items-center ">
                                  <div
                                    className="d-flex justify-content-center  align-items-center card"
                                    style={{
                                      minWidth: "150px",
                                      height: "55px",
                                      backgroundColor: "#fff",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <div className="d-flex flex-column ">
                                      <span
                                        style={{
                                          color: "#003366",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Flour
                                      </span>
                                      <span style={{ color: "#6C6965" }}>
                                        {data[0]?.flour}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {data[0]?.type_of_oil ===
                              "Not Available" ? null : (
                                <div className="d-flex justify-content-center  align-items-center mt-2">
                                  <div
                                    className="d-flex justify-content-center align-items-center card"
                                    style={{
                                      minWidth: "150px",
                                      height: "55px",
                                      backgroundColor: "#fff",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <div className="d-flex flex-column ">
                                      <span
                                        style={{
                                          color: "#003366",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Type of oil
                                      </span>
                                      <span style={{ color: "#6C6965" }}>
                                        {data[0]?.type_of_oil}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {/* {data[0]?.color === "Not Available" ? null : (
                                <div className="d-flex justify-content-center  align-items-center mt-2">
                                  <div
                                    className="d-flex justify-content-center align-items-center card"
                                    style={{
                                      minWidth: "150px",
                                      height: "55px",
                                      backgroundColor: "#fff",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <div className="d-flex flex-column ">
                                      <span
                                        style={{
                                          color: "#003366",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Food color
                                      </span>
                                      <span style={{ color: "#6C6965" }}>
                                        {data[0]?.color}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )} */}
                              {data[0]?.added_flavour ===
                              "Not Available" ? null : (
                                <div className="d-flex justify-content-center  align-items-center mt-2">
                                  <div
                                    className="d-flex justify-content-center align-items-center card"
                                    style={{
                                      minWidth: "150px",
                                      height: "55px",
                                      backgroundColor: "#fff",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <div className="d-flex flex-column ">
                                      <span
                                        style={{
                                          color: "#003366",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Added Flavour
                                      </span>
                                      <span style={{ color: "#6C6965" }}>
                                        {data[0]?.added_flavour}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
              </Container>
            )}
            {data[0]?.test_flavour === "Not Available" &&
            data[0]?.shelf_life === "Not Available" &&
            data[0]?.cooking_time === "Not Available" ? null : (
              <Container fluid className="d-flex justify-content-center mb-5">
                <Col lg={8}>
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <Row>
                          <span
                            style={{ fontSize: "20px", fontWeight: "500" }}
                            className="text-center"
                          >
                            Other
                          </span>
                        </Row>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row className="d-flex">
                          <Col>
                            <div className="d-flex  justify-content-around flex-wrap">
                              {data[0]?.test_flavour ===
                              "Not Available" ? null : (
                                <div className="d-flex justify-content-center  align-items-center ">
                                  <div
                                    className="d-flex justify-content-center  align-items-center card"
                                    style={{
                                      minWidth: "150px",
                                      height: "55px",
                                      backgroundColor: "#fff",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <div className="d-flex flex-column ">
                                      <span
                                        style={{
                                          color: "#003366",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Taste
                                      </span>
                                      <span style={{ color: "#6C6965" }}>
                                        {data[0]?.test_flavour}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {data[0]?.shelf_life ===
                              "Not Available" ? null : (
                                <div className="d-flex justify-content-center  align-items-center mt-2">
                                  <div
                                    className="d-flex justify-content-center align-items-center card"
                                    style={{
                                      minWidth: "180px",
                                      height: "55px",
                                      backgroundColor: "#fff",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <div className="d-flex flex-column ">
                                      <span
                                        style={{
                                          color: "#003366",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Shelf Life (Best before)
                                      </span>
                                      <span style={{ color: "#6C6965" }}>
                                        {data[0]?.shelf_life}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {data[0]?.cooking_time ===
                              "Not Available" ? null : (
                                <div className="d-flex justify-content-center  align-items-center mt-2">
                                  <div
                                    className="d-flex justify-content-center align-items-center card"
                                    style={{
                                      minWidth: "150px",
                                      height: "55px",
                                      backgroundColor: "#fff",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <div className="d-flex flex-column ">
                                      <span
                                        style={{
                                          color: "#003366",
                                          fontWeight: "500",
                                        }}
                                      >
                                        Cooking Time
                                      </span>
                                      <span style={{ color: "#6C6965" }}>
                                        {data[0]?.cooking_time}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
              </Container>
            )}
          </>
        )}
      </Container>
    </>
  );
}

export default ProductDetails;
