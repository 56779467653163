import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Container,
  Modal,
  Row,
  Form,
  FormControl,
  Card,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import TextTruncate from "react-text-truncate";
import ProductSkeleton from "../skeleton/productSkeleton";
import ProductPlaceholder from "../../assets/images/prod_placeholder.png";
import "./Products.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";
import { globalCheckErr } from "../../Check401";
import { BiSearchAlt } from "react-icons/bi";
import LoginOtpMob from "../LoginOtpMob/LoginOtpMob";
import Accordion from "react-bootstrap/Accordion";
import CryptoJS from "crypto-js";
import { GrPowerReset } from "react-icons/gr";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import showImg from "../../assets/images/open_box.svg";

function Products() {
  let location = useLocation();
  let { string } = useParams();
  let brandName = location.state === null ? string : location.state;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [count, setCount] = useState(0);
  const [compareProd, setCompareProd] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [sendPublicId, setSendPublicId] = useState("");
  const [logIn, setLogIng] = useState(false);
  const [vegNonVeg, setVegNonVeg] = useState("");
  const [certification, setCertification] = useState("");
  const [brands, setBrands] = useState([]);
  const [brandFilter, setBrandFilter] = useState("");
  const [religious, setReligious] = useState("");
  const [foodCategoryFilter, setFoodCategoryFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState();
  const [brandIndex, setBrandIndex] = useState();
  const [foodCategory, setFoodCategory] = useState();
  const [productCategory, setProductCategory] = useState();
  const [priorityFlag, setPriorityFlag] = useState("True");

  // Search called when using URL
  const getProducts = () => {
    setIsLoading(true);

    if (
      secureLocalStorage.getItem("isGeolocationAvailable") &&
      secureLocalStorage.getItem("isGeolocationAvailable") === true &&
      secureLocalStorage.getItem("coords") !== null &&
      (secureLocalStorage.getItem("coords").latitude ||
        secureLocalStorage.getItem("coords").longitude !== undefined)
    ) {
      const url = `${
        process.env.REACT_APP_BASEURL
      }ESearch/${brandName.toLowerCase()}/?lat=${JSON.stringify(
        secureLocalStorage.getItem("coords").latitude
      )}&long=${JSON.stringify(
        secureLocalStorage.getItem("coords").longitude
      )}&priority_flag=${priorityFlag}&color=True&has_certification=${certification.trim()}&veg_nonveg=${vegNonVeg.trim()}&brand_name=${brandFilter.trim()}&Religious_sentiments=${religious.trim()}&food_category=${foodCategoryFilter}&page=${page}&size=4`;
      axios
        .get(url)
        .then((res) => {
          setIsLoading(false);

          const key = CryptoJS.enc.Base64.parse(res.data.key);
          const dataBytes = CryptoJS.enc.Base64.parse(res.data.data);
          const iv = CryptoJS.lib.WordArray.create(dataBytes.words.slice(0, 4));
          const ciphertext = CryptoJS.lib.WordArray.create(
            dataBytes.words.slice(4)
          );
          const decryptedBytes = CryptoJS.AES.decrypt({ ciphertext }, key, {
            iv,
          });
          const decryptedText = CryptoJS.enc.Utf8.stringify(decryptedBytes);

          const newProductData = [];
          const parseProductsData = JSON.parse(decryptedText);
          parseProductsData.map((item) => {
            if (item.img !== "") {
              return newProductData.push(item);
            }
          });
          addUniqueProducts(newProductData);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            globalCheckErr();
            navigate("/");
          } else {
            toast.error("Something went wrong", {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
            });
          }
        });
    } else {
      const url = `${
        process.env.REACT_APP_BASEURL
      }ESearch/${brandName.toLowerCase()}?priority_flag=${priorityFlag}&color=True&has_certification=${certification.trim()}&veg_nonveg=${vegNonVeg.trim()}&brand_name=${brandFilter.trim()}&Religious_sentiments=${religious.trim()}&food_category=${foodCategoryFilter}&page=${page}&size=4`;
      axios
        .get(url)
        .then((res) => {
          setIsLoading(false);

          const key = CryptoJS.enc.Base64.parse(res.data.key);
          const dataBytes = CryptoJS.enc.Base64.parse(res.data.data);
          const iv = CryptoJS.lib.WordArray.create(dataBytes.words.slice(0, 4));
          const ciphertext = CryptoJS.lib.WordArray.create(
            dataBytes.words.slice(4)
          );
          const decryptedBytes = CryptoJS.AES.decrypt({ ciphertext }, key, {
            iv,
          });
          const decryptedText = CryptoJS.enc.Utf8.stringify(decryptedBytes);
          const newProductData = [];
          const parseProductsData = JSON.parse(decryptedText);
          parseProductsData.map((item) => {
            if (item.img !== "") {
              return newProductData.push(item);
            }
          });
          addUniqueProducts(newProductData);
        })
        .catch((err) => {
          toast.error("Something went wrong, Try again", {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
          setIsLoading(false);
        });
    }
  };

  // Search called using search bar
  const searchProducts = (data) => {
    setIsLoading(true);
    if (
      secureLocalStorage.getItem("isGeolocationAvailable") &&
      secureLocalStorage.getItem("isGeolocationAvailable") === true &&
      secureLocalStorage.getItem("coords") !== null &&
      (secureLocalStorage.getItem("coords").latitude ||
        secureLocalStorage.getItem("coords").longitude !== undefined)
    ) {
      const url = `${
        process.env.REACT_APP_BASEURL
      }ESearch/${search.toLowerCase()}/?lat=${JSON.stringify(
        secureLocalStorage.getItem("coords").latitude
      )}&long=${JSON.stringify(
        secureLocalStorage.getItem("coords").longitude
      )}&priority_flag=${priorityFlag}&color=True&has_certification=${certification.trim()}&veg_nonveg=${vegNonVeg.trim()}&brand_name=${brandFilter.trim()}&Religious_sentiments=${religious.trim()}&food_category=${foodCategoryFilter}&page=${
        data === 1 ? data : page
      }&size=4`;
      axios
        .get(url)
        .then((res) => {
          setIsLoading(false);
          const key = CryptoJS.enc.Base64.parse(res.data.key);
          const dataBytes = CryptoJS.enc.Base64.parse(res.data.data);
          const iv = CryptoJS.lib.WordArray.create(dataBytes.words.slice(0, 4));
          const ciphertext = CryptoJS.lib.WordArray.create(
            dataBytes.words.slice(4)
          );
          const decryptedBytes = CryptoJS.AES.decrypt({ ciphertext }, key, {
            iv,
          });
          const decryptedText = CryptoJS.enc.Utf8.stringify(decryptedBytes);
          const newProductData = [];
          const parseProductsData = JSON.parse(decryptedText);
          parseProductsData.map((item) => {
            if (item.img !== "") {
              return newProductData.push(item);
            }
          });
          addUniqueProducts(newProductData);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            globalCheckErr();
            navigate("/");
          } else {
            toast.error("Something went wrong", {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
            });
          }
        });
    } else {
      const url = `${
        process.env.REACT_APP_BASEURL
      }ESearch/${search.toLowerCase()}?priority_flag=${priorityFlag}&color=True&has_certification=${certification.trim()}&veg_nonveg=${vegNonVeg.trim()}&brand_name=${brandFilter.trim()}&Religious_sentiments=${religious.trim()}&food_category=${foodCategoryFilter}&page=${page}&size=4`;
      axios
        .get(url)
        .then((res) => {
          setIsLoading(false);
          const key = CryptoJS.enc.Base64.parse(res.data.key);
          const dataBytes = CryptoJS.enc.Base64.parse(res.data.data);
          const iv = CryptoJS.lib.WordArray.create(dataBytes.words.slice(0, 4));
          const ciphertext = CryptoJS.lib.WordArray.create(
            dataBytes.words.slice(4)
          );
          const decryptedBytes = CryptoJS.AES.decrypt({ ciphertext }, key, {
            iv,
          });
          const decryptedText = CryptoJS.enc.Utf8.stringify(decryptedBytes);
          const newProductData = [];
          const parseProductsData = JSON.parse(decryptedText);
          parseProductsData.map((item) => {
            if (item.img !== "") {
              return newProductData.push(item);
            }
          });
          // console.log("4", newProductData);
          addUniqueProducts(newProductData);
        })
        .catch((err) => {
          toast.error("Something went wrong, Try again", {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
          setIsLoading(false);
        });
    }
  };

  const checkUser = (item) => {
    if (secureLocalStorage.getItem("token") !== null) {
      navigate(`/product/${item.public_id}/${item.attr_id}`);
    } else {
      setShowModal(true);
      secureLocalStorage.setItem("prod_id", `${item.public_id}`);
      secureLocalStorage.setItem("attr_id", `${item.attr_id}`);
    }
  };
  const checkUserCompare = () => {
    if (secureLocalStorage.getItem("token") !== null) {
      const compareData = compareProd.map((obj) => obj.public_id).join("/");
      navigate(`/compare/${compareData}`);
      setSendPublicId(compareData);
    } else {
      setShowModal(true);
      const compareData = compareProd.map((obj) => obj.public_id).join("/");
      secureLocalStorage.setItem("public_id", JSON.stringify(compareData));
    }
  };

  // Function for logic of selection of checkbox
  const checkboxClick = (item, e) => {
    if (e.target.checked === true) {
      setCount(count + 1);
      setCompareProd((compareProd) => [...compareProd, item]);
    } else if (e.target.checked === false) {
      setCount(count - 1);
      let arr = compareProd.filter(function (value) {
        return value !== item;
      });
      setCompareProd(arr);
    }
  };

  let handleSubmit = (e) => {
    e.preventDefault();
    const pattern = /^[A-Za-z0-9_'" ]+$/;
    if (pattern.test(search) !== "" && pattern.test(brandName) !== "") {
      setPage(1);
      setProducts([]);
      // searchProducts();
      navigate(`/products/${search}`);
      localStorage.setItem("search", search);
    }
    localStorage.removeItem("product_veg/non-veg");
    localStorage.removeItem("religious_sentiments");
    if (localStorage.getItem("foodCategory")) {
      localStorage.removeItem("foodCategory");
      setFoodCategoryFilter("");
    }
  };
  useEffect(() => {
    if (
      !localStorage.getItem("product_veg/non-veg") &&
      !localStorage.getItem("religious_sentiments")
    ) {
      if (search) {
        searchProducts();
      }
    }
    // API called with filter product_veg/non-veg when user back from product details page.

    if (localStorage.getItem("product_veg/non-veg")) {
      setIsLoading(true);
      if (
        secureLocalStorage.getItem("isGeolocationAvailable") &&
        secureLocalStorage.getItem("isGeolocationAvailable") === true &&
        secureLocalStorage.getItem("coords") !== null &&
        (secureLocalStorage.getItem("coords").latitude ||
          secureLocalStorage.getItem("coords").longitude !== undefined)
      ) {
        const url = `${
          process.env.REACT_APP_BASEURL
        }ESearch/${search}/?lat=${JSON.stringify(
          secureLocalStorage.getItem("coords").latitude
        )}&long=${JSON.stringify(
          secureLocalStorage.getItem("coords").longitude
        )}&priority_flag=${priorityFlag}&color=True&veg_nonveg=${localStorage.getItem(
          "product_veg/non-veg"
        )}&page=${page}&size=4`;
        axios
          .get(url)
          .then((res) => {
            setIsLoading(false);
            const key = CryptoJS.enc.Base64.parse(res.data.key);
            const dataBytes = CryptoJS.enc.Base64.parse(res.data.data);
            const iv = CryptoJS.lib.WordArray.create(
              dataBytes.words.slice(0, 4)
            );
            const ciphertext = CryptoJS.lib.WordArray.create(
              dataBytes.words.slice(4)
            );
            const decryptedBytes = CryptoJS.AES.decrypt({ ciphertext }, key, {
              iv,
            });
            const decryptedText = CryptoJS.enc.Utf8.stringify(decryptedBytes);
            const newProductData = [];
            const parseProductsData = JSON.parse(decryptedText);
            parseProductsData.map((item) => {
              if (item.img !== "") {
                return newProductData.push(item);
              }
            });

            addUniqueProducts(newProductData);
          })
          .catch((err) => {
            if (err.response.status === 401) {
              globalCheckErr();
              navigate("/");
            } else {
              toast.error("Something went wrong", {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
              });
            }
          });
      } else {
        const url = `${
          process.env.REACT_APP_BASEURL
        }ESearch/${search}?priority_flag=${priorityFlag}&color=True&veg_nonveg=${localStorage.getItem(
          "product_veg/non-veg"
        )}&page=${page}&size=4`;
        axios
          .get(url)
          .then((res) => {
            setIsLoading(false);
            const key = CryptoJS.enc.Base64.parse(res.data.key);
            const dataBytes = CryptoJS.enc.Base64.parse(res.data.data);
            const iv = CryptoJS.lib.WordArray.create(
              dataBytes.words.slice(0, 4)
            );
            const ciphertext = CryptoJS.lib.WordArray.create(
              dataBytes.words.slice(4)
            );
            const decryptedBytes = CryptoJS.AES.decrypt({ ciphertext }, key, {
              iv,
            });
            const decryptedText = CryptoJS.enc.Utf8.stringify(decryptedBytes);
            const newProductData = [];
            const parseProductsData = JSON.parse(decryptedText);
            parseProductsData.map((item) => {
              if (item.img !== "") {
                return newProductData.push(item);
              }
            });

            addUniqueProducts(newProductData);
          })
          .catch((err) => {
            toast.error("Something went wrong, Try again", {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
            });
            setIsLoading(false);
          });
      }
    }
    // API called with filter religious_sentiments when user back from product details page.
    if (localStorage.getItem("religious_sentiments")) {
      setIsLoading(true);
      if (
        secureLocalStorage.getItem("isGeolocationAvailable") &&
        secureLocalStorage.getItem("isGeolocationAvailable") === true &&
        secureLocalStorage.getItem("coords") !== null &&
        (secureLocalStorage.getItem("coords").latitude ||
          secureLocalStorage.getItem("coords").longitude !== undefined)
      ) {
        const url = `${
          process.env.REACT_APP_BASEURL
        }ESearch/${search}/?lat=${JSON.stringify(
          secureLocalStorage.getItem("coords").latitude
        )}&long=${JSON.stringify(
          secureLocalStorage.getItem("coords").longitude
        )}&priority_flag=${priorityFlag}&color=True&Religious_sentiments=${localStorage.getItem(
          "religious_sentiments"
        )}&page=${page}&size=4`;
        axios
          .get(url)
          .then((res) => {
            setIsLoading(false);
            const key = CryptoJS.enc.Base64.parse(res.data.key);
            const dataBytes = CryptoJS.enc.Base64.parse(res.data.data);
            const iv = CryptoJS.lib.WordArray.create(
              dataBytes.words.slice(0, 4)
            );
            const ciphertext = CryptoJS.lib.WordArray.create(
              dataBytes.words.slice(4)
            );
            const decryptedBytes = CryptoJS.AES.decrypt({ ciphertext }, key, {
              iv,
            });
            const decryptedText = CryptoJS.enc.Utf8.stringify(decryptedBytes);
            const newProductData = [];
            const parseProductsData = JSON.parse(decryptedText);
            parseProductsData.map((item) => {
              if (item.img !== "") {
                return newProductData.push(item);
              }
            });

            addUniqueProducts(newProductData);
          })
          .catch((err) => {
            if (err.response.status === 401) {
              globalCheckErr();
              navigate("/");
            } else {
              toast.error("Something went wrong", {
                position: "top-right",
                autoClose: 3000,
                theme: "colored",
              });
            }
          });
      } else {
        const url = `${
          process.env.REACT_APP_BASEURL
        }ESearch/${search}?priority_flag=${priorityFlag}&color=True&Religious_sentiments=${localStorage.getItem(
          "religious_sentiments"
        )}&page=${page}&size=4`;
        axios
          .get(url)
          .then((res) => {
            setIsLoading(false);
            const key = CryptoJS.enc.Base64.parse(res.data.key);
            const dataBytes = CryptoJS.enc.Base64.parse(res.data.data);
            const iv = CryptoJS.lib.WordArray.create(
              dataBytes.words.slice(0, 4)
            );
            const ciphertext = CryptoJS.lib.WordArray.create(
              dataBytes.words.slice(4)
            );
            const decryptedBytes = CryptoJS.AES.decrypt({ ciphertext }, key, {
              iv,
            });
            const decryptedText = CryptoJS.enc.Utf8.stringify(decryptedBytes);
            const newProductData = [];
            const parseProductsData = JSON.parse(decryptedText);
            parseProductsData.map((item) => {
              if (item.img !== "") {
                return newProductData.push(item);
              }
            });

            addUniqueProducts(newProductData);
          })
          .catch((err) => {
            toast.error("Something went wrong, Try again", {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
            });
            setIsLoading(false);
          });
      }
    }
  }, [
    page,
    vegNonVeg,
    religious,
    certification,
    brandFilter,
    foodCategoryFilter,
  ]);

  // This useEffect work when user search and filter products by selecting products from products navigation Item.

  useEffect(() => {
    if (localStorage.getItem("foodCategory")) {
      const data = localStorage.getItem("foodCategory");
      const modifiedString = data.replace(" and ", " & ");
      setFoodCategoryFilter(encodeURIComponent(modifiedString));
      setSearch(brandName);
      setProducts([]);
      setPage(1);
      // getProducts();
    } else {
      setSearch(brandName);
      setProducts([]);
      setPage(1);
      getProducts();
    }
  }, [brandName]);

  // API for brand list rendered to filter brand
  const handleBrands = () => {
    setLoading(true);
    let url = process.env.REACT_APP_BASEURL + `brandsList/${search}/`;
    axios
      .get(url)
      .then((res) => {
        setLoading(false);
        if (res.data) {
          setBrands(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err) {
          toast.error("Something went wrong, Try again", {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
        }
      });
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
    setBrands([]);
    localStorage.removeItem("product_veg/non-veg");
    localStorage.removeItem("religious_sentiments");
    if (localStorage.getItem("foodCategory")) {
      localStorage.removeItem("foodCategory");
      setFoodCategoryFilter("");
    }
  };

  const handleReset = () => {
    navigate("/");
  };
  // API for food category list rendered to filter products
  const getFoodCategory = () => {
    setLoading(true);
    let url = process.env.REACT_APP_BASEURL + "foodCategory/";
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
        Authorization: secureLocalStorage.getItem("token"),
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        setLoading(false);
        setFoodCategory(res.data.sort());
      })
      .catch((err) => {
        if (err) {
          setLoading(false);
          toast.error(
            "Getting some issue while fetching list of food category. Please try again!",
            {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
            }
          );
        }
      });
  };
  // Function to add new unique items and update state
  const addUniqueProducts = (newProductData) => {
    // Use a set to keep track of unique item identifiers
    const uniqueItemIds = new Set(products.map((item) => item.id));

    // Filter the new items and add only those that don't exist in the state
    const filteredNewItems = newProductData.filter(
      (newItem) => !uniqueItemIds.has(newItem.id)
    );

    // If there are new items to add, update the state
    if (filteredNewItems.length > 0) {
      setProducts([...products, ...filteredNewItems]);
    }
    if (filteredNewItems.length === 0) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  };

  return (
    <>
      <div className="pt-5 contact-bg" style={{ paddingBottom: "75px" }}></div>
      <Container fluid style={{ fontFamily: "sans-serif" }} className="mt-5 ">
        <Modal
          show={logIn}
          onHide={() => setLogIng(false)}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="p-0"
        >
          <Modal.Body>
            <div className="d-flex justify-content-center align-items-center">
              <LoginOtpMob closemodal={setLogIng}></LoginOtpMob>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="contained-modal-title-vcenter"
              style={{ color: "#034053" }}
            >
              Login to continue
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="text-center pt-3 fs-5">
              In order to view the details please login first.
            </p>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button
              style={{ width: 120 }}
              className="signin-button "
              onClick={() => {
                setLogIng(true);
              }}
            >
              Login
            </Button>
          </Modal.Footer>
        </Modal>
        <Row style={{ marginTop: 30 }}>
          <Col>
            <Container fluid>
              <Form>
                <Row className="text-center">
                  <Col
                    className="d-flex justify-content-center align-items-start mt-2"
                    lg={2}
                    md={2}
                  >
                    <p className="h3 text-start me-3">Products</p>
                  </Col>
                  <Col lg={2} md={1}></Col>
                  <Col className="mt-2" lg={5} md={4}>
                    <FormControl
                      type="text"
                      placeholder="Search for Products or Brand"
                      onChange={handleChange}
                      autoFocus
                      value={search ? search : ""}
                      style={{ boxShadow: "none" }}
                      className="search-inputs"
                    />
                  </Col>
                  <Col sm className="text-center mt-2 " lg={1} md={2}>
                    <button
                      type="submit"
                      className="search-button  btn-sm  "
                      onClick={handleSubmit}
                    >
                      Search
                    </button>
                  </Col>
                  <Col sm className="text-center mt-2 ms-2" lg={1} md={2}>
                    <button
                      type="button"
                      className="reset-button btn-sm"
                      onClick={handleReset}
                    >
                      <GrPowerReset />
                    </button>
                  </Col>
                </Row>
              </Form>
            </Container>
          </Col>
          <div className="mb-3 mt-5 ">
            <div className="row">
              <div className="col-lg-3 col-sm-12"></div>
              <div className="col-lg-8 col-sm-12 text-center">
                <h5 className="m-auto mb-3 me-3">
                  Showing results of {search ? search : ""}
                </h5>
              </div>
            </div>
          </div>
          <InfiniteScroll
            dataLength={products?.length}
            next={() => setPage(page + 1)}
            hasMore={hasMore}
            // Loader message
            loader={
              <div className="d-flex flex-column justify-content-center align-items-center">
                <h4 className="text-center">
                  <span
                    className="spinner-border text-danger "
                    role="status"
                  ></span>{" "}
                  Loading...
                </h4>
              </div>
            }
            // List end message
            endMessage={
              products.length !== 0 && (
                <>
                  <Container>
                    <Row style={{ textAlign: "center" }}>
                      <div className="col-lg-3 col-md-12 col-sm-12"></div>
                      <Col className="col-lg-8 col-md-12 col-sm-12 text-center">
                        <div className="ms-2 mb-2">
                          <small style={{ color: "#003366", fontSize: "12px" }}>
                            That’s All We Got For Now.
                          </small>
                        </div>
                        <Button
                          onClick={() => {
                            // setProducts([]);
                            setPriorityFlag("False");
                            setPage(1);
                          }}
                          className="ms-2 mb-3 pt-2 text-white border-0"
                          style={{
                            backgroundColor: "#5CB85C",
                            fontSize: "13px",
                          }}
                        >
                          <img src={showImg} alt="" /> Show Related Products
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </>
              )
            }
          >
            {
              <Row className="d-flex justify-content-center ">
                <Col className="col-lg-2 col-md-2 col-sm-12 mb-4 ">
                  {window.innerWidth <= 425 ? null : (
                    <span className="h6">
                      <p className="m-2">Filters</p>
                    </span>
                  )}
                  {window.innerWidth <= 425 ? (
                    <Accordion defaultActiveKey={[]}>
                      <Accordion.Item eventKey="5">
                        <Accordion.Header>Filters</Accordion.Header>
                        <Accordion.Body>
                          <Accordion
                            // alwaysOpen={true}
                            // defaultActiveKey={["0", "1", "2"]}
                            alwaysOpen={window.innerWidth <= 425 ? false : true}
                            defaultActiveKey={
                              window.innerWidth <= 425 ? [] : ["0", "1", "2"]
                            }
                          >
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>Veg / Non-Veg</Accordion.Header>
                              <Accordion.Body>
                                <input
                                  type="checkbox"
                                  value="veg"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      localStorage.removeItem(
                                        "product_veg/non-veg"
                                      );
                                      setProducts([]);
                                      setPage(1);
                                      setVegNonVeg(e.target.value);
                                    } else {
                                      setProducts([]);
                                      setVegNonVeg("");
                                    }
                                  }}
                                  id="veg"
                                />
                                <label for="veg" className="ms-3 ">
                                  Veg
                                </label>{" "}
                                <br />
                                <input
                                  type="checkbox"
                                  value="non"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      localStorage.removeItem(
                                        "product_veg/non-veg"
                                      );
                                      setProducts([]);
                                      setPage(1);
                                      setVegNonVeg(e.target.value);
                                    } else {
                                      setProducts([]);
                                      setVegNonVeg("");
                                    }
                                  }}
                                  id="non-veg"
                                />
                                <label for="non-veg" className="ms-3 ">
                                  Non-veg
                                </label>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                              <Accordion.Header>
                                Lifestyle Choice
                              </Accordion.Header>
                              <Accordion.Body>
                                <input
                                  type="checkbox"
                                  value="yes"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setProducts([]);
                                      setPage(1);
                                      setReligious(e.target.value);
                                    } else {
                                      setProducts([]);
                                      setReligious("");
                                    }
                                  }}
                                  id="yes1"
                                />
                                <label for="yes1" className="ms-3 ">
                                  Non Halal
                                </label>{" "}
                                <br />
                                <input
                                  type="checkbox"
                                  value="no"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setProducts([]);
                                      setPage(1);
                                      setReligious(e.target.value);
                                    } else {
                                      setProducts([]);
                                      setReligious("");
                                    }
                                  }}
                                  id="no1"
                                />
                                <label for="no1" className="ms-3 ">
                                  Halal
                                </label>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                              <Accordion.Header>Certifcation</Accordion.Header>
                              <Accordion.Body>
                                <input
                                  type="checkbox"
                                  value="YES"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setProducts([]);
                                      setPage(1);
                                      setCertification(e.target.value);
                                    } else {
                                      setCertification("");
                                    }
                                  }}
                                  id="yes"
                                />
                                <label for="yes" className="ms-3 ">
                                  Yes
                                </label>{" "}
                                <br />
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                              <Accordion.Header
                                onClick={() => {
                                  handleBrands();
                                }}
                              >
                                Brand
                              </Accordion.Header>
                              <Accordion.Body className="scrolltable mt-3">
                                {loading ? (
                                  <p className="text-center mt-5">
                                    {" "}
                                    Loading...
                                  </p>
                                ) : (
                                  brands?.length > 0 &&
                                  brands.map((item, index) => {
                                    return (
                                      <>
                                        <input
                                          type="radio"
                                          id={item}
                                          value={item}
                                          checked={brandIndex === index}
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              setProducts([]);
                                              setPage(1);
                                              setBrandFilter(e.target.value);
                                              setBrandIndex(index);
                                            } else {
                                              setBrandFilter("");
                                              setBrandIndex("");
                                            }
                                          }}
                                        />
                                        <label
                                          for={item}
                                          className="ms-3 small"
                                        >
                                          {item?.length > 12 ? (
                                            <>{item.slice(0, 12)}...</>
                                          ) : (
                                            item
                                          )}
                                        </label>
                                        <br />
                                      </>
                                    );
                                  })
                                )}
                                {!loading && brands?.length === 0 ? (
                                  <p className="text-center mt-5">
                                    No data found!
                                  </p>
                                ) : null}
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                              <Accordion.Header
                                onClick={() => {
                                  getFoodCategory();
                                }}
                              >
                                Product{" "}
                              </Accordion.Header>
                              <Accordion.Body className="scrolltable mt-3">
                                {loading ? (
                                  <p className="text-center mt-5">
                                    {" "}
                                    Loading...
                                  </p>
                                ) : (
                                  foodCategory?.length > 0 &&
                                  foodCategory.map((item, index) => {
                                    return (
                                      <>
                                        <input
                                          type="radio"
                                          id={item}
                                          value={item}
                                          checked={productCategory === index}
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              setProducts([]);
                                              setPage(1);
                                              setFoodCategoryFilter(
                                                e.target.value
                                              );
                                              setProductCategory(index);
                                            } else {
                                              setFoodCategoryFilter("");
                                              setProductCategory("");
                                            }
                                          }}
                                        />
                                        <label
                                          for={item}
                                          className="ms-3 small"
                                        >
                                          {item?.length > 20 ? (
                                            <>{item.slice(0, 20)}...</>
                                          ) : (
                                            item
                                          )}
                                        </label>
                                        <br />
                                      </>
                                    );
                                  })
                                )}
                                {!loading && foodCategory?.length === 0 ? (
                                  <p className="text-center mt-5">
                                    No data found!
                                  </p>
                                ) : null}
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  ) : (
                    <Accordion
                      // alwaysOpen={true}
                      // defaultActiveKey={["0", "1", "2"]}
                      alwaysOpen={window.innerWidth <= 425 ? false : true}
                      defaultActiveKey={
                        window.innerWidth <= 425 ? [] : ["0", "1", "2"]
                      }
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Veg / Non-Veg</Accordion.Header>
                        <Accordion.Body>
                          <input
                            type="checkbox"
                            value="veg"
                            onChange={(e) => {
                              if (e.target.checked) {
                                localStorage.removeItem("product_veg/non-veg");
                                setProducts([]);
                                setPage(1);
                                setVegNonVeg(e.target.value);
                              } else {
                                setProducts([]);
                                setVegNonVeg("");
                              }
                            }}
                            id="veg"
                          />
                          <label for="veg" className="ms-3 ">
                            Veg
                          </label>{" "}
                          <br />
                          <input
                            type="checkbox"
                            value="non"
                            onChange={(e) => {
                              if (e.target.checked) {
                                localStorage.removeItem("product_veg/non-veg");
                                setProducts([]);
                                setPage(1);
                                setVegNonVeg(e.target.value);
                              } else {
                                setProducts([]);
                                setVegNonVeg("");
                              }
                            }}
                            id="non-veg"
                          />
                          <label for="non-veg" className="ms-3 ">
                            Non-veg
                          </label>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Lifestyle Choice</Accordion.Header>
                        <Accordion.Body>
                          <input
                            type="checkbox"
                            value="yes"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setProducts([]);
                                setPage(1);
                                setReligious(e.target.value);
                              } else {
                                setProducts([]);
                                setReligious("");
                              }
                            }}
                            id="yes1"
                          />
                          <label for="yes1" className="ms-3 ">
                            Non Halal
                          </label>{" "}
                          <br />
                          <input
                            type="checkbox"
                            value="no"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setProducts([]);
                                setPage(1);
                                setReligious(e.target.value);
                              } else {
                                setProducts([]);
                                setReligious("");
                              }
                            }}
                            id="no1"
                          />
                          <label for="no1" className="ms-3 ">
                            Halal
                          </label>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>Certifcation</Accordion.Header>
                        <Accordion.Body>
                          <input
                            type="checkbox"
                            value="YES"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setProducts([]);
                                setPage(1);
                                setCertification(e.target.value);
                              } else {
                                setCertification("");
                              }
                            }}
                            id="yes"
                          />
                          <label for="yes" className="ms-3 ">
                            Yes
                          </label>{" "}
                          <br />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header
                          onClick={() => {
                            handleBrands();
                          }}
                        >
                          Brand
                        </Accordion.Header>
                        <Accordion.Body className="scrolltable mt-3">
                          {loading ? (
                            <p className="text-center mt-5"> Loading...</p>
                          ) : (
                            brands?.length > 0 &&
                            brands.map((item, index) => {
                              return (
                                <>
                                  <input
                                    type="radio"
                                    id={item}
                                    value={item}
                                    checked={brandIndex === index}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setProducts([]);
                                        setPage(1);
                                        setBrandFilter(e.target.value);
                                        setBrandIndex(index);
                                      } else {
                                        setBrandFilter("");
                                        setBrandIndex("");
                                      }
                                    }}
                                  />
                                  <label for={item} className="ms-3 small">
                                    {item?.length > 12 ? (
                                      <>{item.slice(0, 12)}...</>
                                    ) : (
                                      item
                                    )}
                                  </label>
                                  <br />
                                </>
                              );
                            })
                          )}
                          {!loading && brands?.length === 0 ? (
                            <p className="text-center mt-5">No data found!</p>
                          ) : null}
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                        <Accordion.Header
                          onClick={() => {
                            getFoodCategory();
                          }}
                        >
                          Product{" "}
                        </Accordion.Header>
                        <Accordion.Body className="scrolltable mt-3">
                          {loading ? (
                            <p className="text-center mt-5"> Loading...</p>
                          ) : (
                            foodCategory?.length > 0 &&
                            foodCategory.map((item, index) => {
                              return (
                                <>
                                  <input
                                    type="radio"
                                    id={item}
                                    value={item}
                                    checked={productCategory === index}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setProducts([]);
                                        setPage(1);
                                        setFoodCategoryFilter(e.target.value);
                                        setProductCategory(index);
                                      } else {
                                        setFoodCategoryFilter("");
                                        setProductCategory("");
                                      }
                                    }}
                                  />
                                  <label for={item} className="ms-3 small">
                                    {item?.length > 20 ? (
                                      <>{item.slice(0, 20)}...</>
                                    ) : (
                                      item
                                    )}
                                  </label>
                                  <br />
                                </>
                              );
                            })
                          )}
                          {!loading && foodCategory?.length === 0 ? (
                            <p className="text-center mt-5">No data found!</p>
                          ) : null}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  )}
                </Col>

                <Col
                  className="col-lg-9 d-flex flex-direction-row flex-wrap justify-content-center "
                  id="scrolling"
                >
                  {isLoading && page === 1 ? (
                    <ProductSkeleton></ProductSkeleton>
                  ) : (
                    products.map((item, index) => {
                      return (
                        <>
                          <Card
                            key={item.public_id}
                            style={{
                              width: "300px",
                              borderRadius: "10px",
                              border: "1px solid #e0e0e0e",
                              height: "400px",
                              fontFamily: "sans-serif",
                            }}
                            className="card  card-hover mb-4 me-sm-0 me-md-4"
                          >
                            {item.Religious_sentiments === "Yes" ? (
                              <div
                                className="boxheight"
                                style={{
                                  position: "absolute",
                                  // background: "#cb1616",
                                  top: "0px",
                                  left: "0px",
                                  borderBottomRightRadius: "10px",
                                  borderTopLeftRadius: "10px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  boxShadow: "#808080fc 2px 2px 11px 0px",
                                  padding: "5px",
                                }}
                              >
                                <span
                                  style={{
                                    color: "black",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  &nbsp;Non Halal&nbsp;
                                </span>
                              </div>
                            ) : (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0px",
                                  left: "0px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: "5px",
                                  background: "#cb1616",
                                  borderBottomRightRadius: "10px",
                                  borderTopLeftRadius: "10px",
                                  boxShadow: "#808080fc 2px 2px 11px 0px",
                                }}
                              >
                                <span
                                  style={{
                                    color: "white",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  &nbsp;Halal&nbsp;
                                </span>
                              </div>
                            )}
                            <div className="mx-3 my-2 d-flex justify-content-end  align-items-center  ">
                              {count >= 4 ? (
                                <small
                                  className="text-center me-2 fw-bold  "
                                  style={{ marginTop: "25px" }}
                                >
                                  You can compare upto 4 items
                                </small>
                              ) : null}
                              {count <= 1 ? (
                                <div className="d-flex 100vh align-items-center  ">
                                  {" "}
                                  <p className="m-0">Compare</p>{" "}
                                </div>
                              ) : count >= 2 &&
                                compareProd.some(
                                  (e) => e.public_id === item.public_id
                                ) ? (
                                <button
                                  className="btn btn-primary signin-button"
                                  onClick={() => {
                                    checkUserCompare();
                                  }}
                                >
                                  {" "}
                                  Compare
                                </button>
                              ) : null}
                              &nbsp;
                              <div className="d-flex align-items-center">
                                {}
                                {count < 4 ? (
                                  <input
                                    type="checkbox"
                                    className="checkbox-custom"
                                    onChange={(e) => {
                                      checkboxClick(item, e);
                                    }}
                                  />
                                ) : count === 4 &&
                                  compareProd.some(
                                    (e) => e.public_id === item.public_id
                                  ) ? (
                                  <input
                                    type="checkbox"
                                    className="checkbox-custom"
                                    onChange={(e) => {
                                      checkboxClick(item, e);
                                    }}
                                  />
                                ) : null}
                              </div>
                            </div>
                            <div
                              key={item.public_id}
                              onClick={() => {
                                checkUser(item);
                              }}
                              className=""
                              style={{
                                background: `url(${
                                  item.img ? item.img : ProductPlaceholder
                                })`,
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                backgroundSize: "contain",
                                width: "100%",
                                height: "300px",
                                cursor: "pointer",
                              }}
                            ></div>

                            <Card.Body
                              className="card-body-hover"
                              onClick={() => {
                                checkUser(item);
                              }}
                            >
                              <hr style={{ width: "100%" }}></hr>
                              <Row>
                                <Col
                                  style={{
                                    color: "#003366",
                                    fontFamily: "",
                                    textAlign: "left",
                                    fontSize: "14px",
                                    fontWeight: "BOLD",
                                  }}
                                >
                                  Brand:{" "}
                                  {item.brand_name ? item.brand_name : "N/A"}{" "}
                                </Col>
                              </Row>
                              <Card.Text
                                style={{
                                  color: "#434343",
                                  textAlign: "left",
                                  fontSize: "12px",
                                  fontWeight: "BOLD",
                                }}
                              >
                                {item.product_name &&
                                item.product_name.length > 100
                                  ? `${item.product_name.slice(0, 100)}...`
                                  : item.product_name}

                                <div className="d-flex justify-content-between ">
                                  <p
                                    style={{
                                      color: "#8D8D8D",
                                      textAlign: "left",
                                      fontSize: "15px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {item?.product_description === "nan" ||
                                    !item?.product_description
                                      ? ""
                                      : `${(item?.product_description).slice(
                                          0,
                                          40
                                        )}...`}
                                  </p>
                                </div>
                                <Row className="d-flex justify-content-between">
                                  <Col className="d-flex justify-content-start">
                                    {item?.product_veg_nonveg === "veg" ? (
                                      <img
                                        src={require("../../assets/images/veg-btn.png")}
                                        style={{
                                          height: "30px",
                                          width: "80px",
                                        }}
                                      ></img>
                                    ) : item?.product_veg_nonveg === "non" ? (
                                      <img
                                        src={require("../../assets/images/non.png")}
                                        style={{
                                          height: "30px",
                                          width: "80px",
                                        }}
                                      ></img>
                                    ) : null}
                                  </Col>
                                  <Col className="d-flex justify-content-end">
                                    {item?.halal_certification_type ===
                                    "nan" ? null : (
                                      <Tooltip
                                        title={
                                          item?.halal_certification_type ===
                                          "nan"
                                            ? null
                                            : item?.halal_certification_type
                                        }
                                      >
                                        <IconButton
                                          style={{ color: "#003366" }}
                                        >
                                          <InfoIcon className="mt-0" />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </Col>
                                </Row>
                              </Card.Text>
                            </Card.Body>
                          </Card>{" "}
                        </>
                      );
                    })
                  )}
                  {products.length === 0 && isLoading !== true ? (
                    <h3>No results found</h3>
                  ) : null}
                </Col>
              </Row>
            }
          </InfiniteScroll>
        </Row>
      </Container>
    </>
  );
}

export default Products;
