import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Image1 from "../../assets/images/blog1.jpg";
import Image2 from "../../assets/images/blog2.jpg";
import Image3 from "../../assets/images/blog3.jpg";
import Image4 from "../../assets/images/blog4.jpg";
import Image5 from "../../assets/images/blog5.png";
import Image6 from "../../assets/images/blog6.jpg";
import { Link } from "react-router-dom";

const Blogs = () => {
  return (
    <>
      <div className="pt-5" style={{ paddingBottom: "75px" }}></div>
      <Container className="mt-5 p-0">
        <Row>
          <Col
            lg={12}
            md={12}
            sm={12}
            className="d-flex justify-content-center mb-4 card border-0 shadow rounded-4"
          >
            <div className="row p-5">
              <div className="col-lg-6 col-md-12 d-flex justify-content-center">
                <img
                  src={Image6}
                  className="img-fluid rounded-4"
                  width="92%"
                ></img>
              </div>
              <div className="col-lg-6 col-md-12 d-flex justify-content-start">
                <div className="mt-5 mb-5 text-black pe-3">
                  <h3 style={{ color: "#003366" }}>
                    Halal vs. Satvik Food Choices: A Tasty Dive into Tradition
                  </h3>
                  <span className="lh-lg">
                    In the global smorgasbord of culinary delights, two dietary
                    paths, Halal and Satvik, stand out like flavorful bookends.
                    They're not just about what you eat; they're about
                    tradition, culture, and even a bit of soul food. Join us a
                    delicious journey as we explore the unique characteristics
                    of Halal and Satvik food …
                  </span>
                  <div className="pt-3">
                    <Link
                      to={"/bloginfo/1"}
                      className="text-decoration-none fw-semibold"
                    >
                      Read More »
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col
            lg={12}
            md={12}
            sm={12}
            className="d-flex justify-content-center mb-4 card border-0 shadow rounded-4"
          >
            <div className="row p-5">
              <div className="col-lg-6 col-md-12 d-flex justify-content-center">
                <img
                  src={Image1}
                  className="img-fluid rounded-4"
                  width="92%"
                ></img>
              </div>
              <div className="col-lg-6 col-md-12 d-flex justify-content-start">
                <div className="mt-5 mb-5 text-black pe-3">
                  <h3 style={{ color: "#003366" }}>
                    Poorna Satya: Your Gateway to Healthier Choices, Delivered
                    to Your Doorstep
                  </h3>
                  <span className="lh-lg">
                    In today’s fast-paced world, making healthy food choices can
                    often feel like a challenge. We’re bombarded with an array
                    of products, each claiming to be a healthier option. But how
                    can you be sure? This is where Poorna Satya steps in,
                    revolutionizing the way we approach nutrition and wellness.
                    Poorna Satya is not just a …
                  </span>
                  <div className="pt-3">
                    <Link
                      to={"/bloginfo/2"}
                      className="text-decoration-none fw-semibold"
                    >
                      Read More »
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col
            lg={12}
            md={12}
            sm={12}
            className="d-flex justify-content-center mb-4 card border-0 shadow rounded-4"
          >
            <div className="row p-5">
              <div className="col-lg-6 col-md-12 d-flex justify-content-center">
                <img
                  src={Image2}
                  className="img-fluid rounded-4"
                  width="92%"
                ></img>
              </div>
              <div className="col-lg-6 col-md-12 d-flex justify-content-start">
                <div className="mt-5 mb-5 text-black pe-3">
                  <h3 style={{ color: "#003366" }}>
                    Smart Shopping: Decoding Healthy Packaged Products on
                    Grocery Shelves
                  </h3>
                  <span className="lh-lg">
                    Every supermarket aisle is a labyrinth of choices. From
                    snacks to condiments, the array of packaged products is
                    overwhelming. However, when it comes to making healthier
                    choices, the real challenge lies in deciphering which
                    packaged products are truly the best for your well-being. In
                    this blog, we’ll delve into the difficulties of discerning
                    the healthiest …
                  </span>
                  <div className="pt-3">
                    <Link
                      to={"/bloginfo/3"}
                      className="text-decoration-none fw-semibold"
                    >
                      Read More »
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col
            lg={12}
            md={12}
            sm={12}
            className="d-flex justify-content-center mb-4 card border-0 shadow rounded-4"
          >
            <div className="row p-5">
              <div className="col-lg-6 col-md-12 d-flex justify-content-center">
                <img
                  src={Image3}
                  className="img-fluid rounded-4"
                  width="92%"
                ></img>
              </div>
              <div className="col-lg-6 col-md-12 d-flex justify-content-start">
                <div className="mt-5 mb-5 text-black pe-3">
                  <h3 style={{ color: "#003366" }}>
                    Weight Management Using food Labels: Tips and Tricks
                  </h3>
                  <span className="lh-lg">
                    Weight management is of paramount importance for overall
                    health and well-being. Maintaining a healthy weight not only
                    contributes to a more active and fulfilling life but also
                    plays a pivotal role in reducing the risk of numerous
                    chronic diseases, including heart disease, diabetes, and
                    certain types of cancer. Beyond physical health, weight
                    management can have …
                  </span>
                  <div className="pt-3">
                    <Link
                      to={"/bloginfo/4"}
                      className="text-decoration-none fw-semibold"
                    >
                      Read More »
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col
            lg={12}
            md={12}
            sm={12}
            className="d-flex justify-content-center mb-4 card border-0 shadow rounded-4"
          >
            <div className="row p-5">
              <div className="col-lg-6 col-md-12 d-flex justify-content-center">
                <img
                  src={Image4}
                  className="img-fluid rounded-4"
                  width="92%"
                ></img>
              </div>
              <div className="col-lg-6 col-md-12 d-flex justify-content-start">
                <div className="mt-5 mb-5 text-black pe-3">
                  <h3 style={{ color: "#003366" }}>
                    Veganism vs. Vegetarianism: A Food Education Journey
                  </h3>
                  <span className="lh-lg">
                    In a country as diverse as India, food choices are deeply
                    intertwined with culture, tradition, and beliefs. The
                    decision to adopt a vegetarian or vegan lifestyle goes
                    beyond personal health; it often reflects ethical,
                    environmental, and philosophical values. In this blog, we’ll
                    explore the distinctions between veganism and vegetarianism,
                    emphasizing the importance of food education …
                  </span>
                  <div className="pt-3">
                    <Link
                      to={"/bloginfo/5"}
                      className="text-decoration-none fw-semibold"
                    >
                      Read More »
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col
            lg={12}
            md={12}
            sm={12}
            className="d-flex justify-content-center mb-4 card border-0 shadow rounded-4"
          >
            <div className="row p-5">
              <div className="col-lg-6 col-md-12 d-flex justify-content-center">
                <img
                  src={Image5}
                  className="img-fluid rounded-4"
                  width="92%"
                  height="40px"
                ></img>
              </div>
              <div className="col-lg-6 col-md-12 d-flex justify-content-start">
                <div className="mt-5 mb-5 text-black pe-3">
                  <h3 style={{ color: "#003366" }}>
                    Decoding Food Labels: A Beginner’s Guide to Understanding
                    Nutritional Labels
                  </h3>
                  <span className="lh-lg">
                    When it comes to making healthier food choices, one of the
                    most powerful tools at your disposal is the nutritional
                    label on the packaging. However these labels can be a maze
                    of numbers and terminology that seems more like a secret
                    code than a helpful guide. If you’re looking to navigate the
                    world of food …
                  </span>
                  <div className="pt-3">
                    <Link
                      to={"/bloginfo/6"}
                      className="text-decoration-none fw-semibold"
                    >
                      Read More »
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Blogs;
