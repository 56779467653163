import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

function PrivateBeforeLogin() {
  const [isLogin, setIsLogin] = useState(false);
  let navigate = useNavigate();
  useEffect(() => {
    if (secureLocalStorage.getItem("token") !== null) {
      setIsLogin(true);
      navigate("/notfound");
    }
  }, [secureLocalStorage.getItem("token")]);
  return <>{isLogin === false ? <Outlet /> : null}</>;
}

export default PrivateBeforeLogin;
