import axios from "axios";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function VerifyOtpMob({ public_id, closeOtp }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  // let { public_id } = useParams()
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const verifyOtp = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("otp", otp);
    formData.append("public_id", public_id);
    let url = process.env.REACT_APP_BASEURL + "mobile_verify_otp/";
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
      },
    };
    axios
      .post(url, formData, config)
      .then((res) => {
        setLoading(false);
        secureLocalStorage.setItem("token", res.data.access_token);
        secureLocalStorage.setItem("fullname", res.data.fullname);
        toast.success("Login success!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
          theme: "colored",
        });

        //This is to redirect user back to the same page he loggedin
        if (
          secureLocalStorage.getItem("prod_id") !== null &&
          secureLocalStorage.getItem("attr_id") !== null &&
          localStorage.getItem("homePageFlag")
        ) {
          navigate(
            `/product/${secureLocalStorage.getItem(
              "prod_id"
            )}/${secureLocalStorage.getItem(
              "attr_id"
            )}?homePageFlag=${localStorage.getItem("homePageFlag")}`
          );
        } else if (
          secureLocalStorage.getItem("prod_id") !== null &&
          secureLocalStorage.getItem("attr_id") !== null
        ) {
          navigate(
            `/product/${secureLocalStorage.getItem(
              "prod_id"
            )}/${secureLocalStorage.getItem("attr_id")}`
          );
        } else if (
          secureLocalStorage.getItem("public_id") &&
          localStorage.getItem("homePageFlag")
        ) {
          navigate(
            `/compare/${JSON.parse(
              secureLocalStorage.getItem("public_id")
            )}?homePageFlag=${localStorage.getItem("homePageFlag")}`
          );
        } else if (secureLocalStorage.getItem("public_id")) {
          navigate(
            `/compare/${JSON.parse(secureLocalStorage.getItem("public_id"))}`
          );
        } else {
          navigate("/");
        }
        closeOtp(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(`${error.response.data.error}`, {
          autoClose: 2000,
          theme: "colored",
        });
      });
  };
  return (
    <Row className="h-100 align-items-center d-flex justify-content-center ">
      <Col lg={6}>
        <h2 className="text-center">Verify OTP</h2>
        <p className="mt-2 text-center">
          Enter the OTP sent to your mobile number
        </p>

        <form
          onSubmit={(e) => {
            verifyOtp(e);
          }}
        >
          <input
            autoFocus
            type="password"
            className="form-control col-3"
            required
            onChange={(e) => {
              setOtp(e.target.value);
            }}
          />
          <Row className="d-flex justify-content-center mt-4">
            {loading ? (
              <button
                type="submit"
                className="btn text-white col-4"
                style={{ backgroundColor: "#5CB85C", width: "auto" }}
                disabled
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>{" "}
                Verifying...{" "}
              </button>
            ) : (
              <button
                type="submit"
                className="btn text-white col-3"
                style={{ backgroundColor: "#5CB85C", width: "auto" }}
              >
                Verify
              </button>
            )}
          </Row>
        </form>
      </Col>
    </Row>
  );
}

export default VerifyOtpMob;
