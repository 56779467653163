import React from 'react'

function NotFound() {
    return (
        <div className='d-flex flex-column justify-content-center align-items-center ' style={{ height: "90vh", }}>
            <img alt='404 Gif' src={require('../../assets/gifs/404.gif')} style={{ maxHeight: '100%', maxWidth: "100%" }} />
            <h1>404 Page not found</h1>
        </div>
    )
}

export default NotFound