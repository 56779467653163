import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import '../Compare/Compare.css'
import Skeleton from 'react-loading-skeleton';
function CompareSkeleton(props) {
    const data = props.data
    return (<>
        <Container fluid >
            <Row >
                {
                    data?.map(() => {
                        return (
                            <Col>
                                <div className='d-flex justify-content-center align-items-center mt-5 '>
                                    <Skeleton className={`img-size-compare-skele `} />
                                </div>
                                <Container>
                                    <Skeleton width={"50%"}></Skeleton>
                                    <div className="justify-content-center justify-content-start">
                                        <Skeleton height={60}></Skeleton>
                                        <Skeleton className='mt-4' ></Skeleton>
                                        <Skeleton height={80} ></Skeleton>
                                        <Skeleton className='mt-4' ></Skeleton>
                                        <Skeleton height={80} ></Skeleton>
                                        <Skeleton className='mt-4' ></Skeleton>
                                        <Skeleton height={100}  ></Skeleton>
                                    </div>
                                </Container>
                            </Col>
                        )
                    })
                }
                <Container fluid className='p-5' style={{ backgroundColor: '#ffff' }}>
                    <Skeleton height={500} ></Skeleton>
                </Container>
                <Container fluid className='p-5' style={{ backgroundColor: '#ffff' }}>
                    <Skeleton height={400} ></Skeleton>
                </Container>
                <Container fluid className='p-5' style={{ backgroundColor: '#ffff' }}>
                    <Skeleton height={300} ></Skeleton>
                </Container>
            </Row>
        </Container>

    </>
    )
}

export default CompareSkeleton