import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import "./Compare.css";
import secureLocalStorage from "react-secure-storage";
import CompareSkeleton from "../skeleton/CompareSkeleton";
import { useTranslation } from "react-i18next";
import ProductPlaceholder from "../../assets/images/prod_placeholder.png";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
function Compare() {
  const location = useLocation();
  const path = location.pathname.slice(9);
  const items = path.split("/");
  // Output: "1749621062826920/6925265268116521"    // const public_id = location.state

  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [energy, setEnergy] = useState([]);
  const [maxEnergy, setMaxEnergy] = useState("");
  const [protein, setProtein] = useState([]);
  const [maxProtein, setMaxProtein] = useState("");
  const [carb, setCarb] = useState([]);
  const [maxCarbs, setMaxCarbs] = useState("");
  const [totalSugar, setTotalSugar] = useState([]);
  const [maxTotalSugar, setMaxTotalSugar] = useState("");
  const [sugar, setSugar] = useState([]);
  const [maxSugar, setMaxSugar] = useState("");
  const [fiber, setFiber] = useState([]);
  const [maxFiber, setMaxFiber] = useState("");
  const [totalFat, setTotalFat] = useState([]);
  const [maxTotalFat, setMaxTotalFat] = useState("");
  const [satuFat, setSatuFat] = useState([]);
  const [maxSatuFat, setMaxSatuFat] = useState("");
  const [transFat, setTransFat] = useState([]);
  const [maxTransFat, setMaxTransFat] = useState("");
  const [choles, setCholes] = useState([]);
  const [maxCholes, setMaxCholes] = useState("");
  const [sodium, setSodium] = useState([]);
  const [maxSodium, setMaxSodium] = useState("");
  const [iron, setIron] = useState([]);
  const [maxIron, setMaxIron] = useState("");
  const col0 = "#003366";
  const col1 = "#5CB85C";
  const col2 = "#487BAD";
  const col3 = "#D7B58B";
  const [energyLabel, setEnergyLabel] = useState();
  const [proteinLabel, setProteinLabel] = useState();
  const [carbsLabel, setCarbsLabel] = useState();
  const [totalSugarLabel, setTotalSugarLabel] = useState();
  const [sugarLabel, setSugarLabel] = useState();
  const [fiberLabel, setFiberLabel] = useState();
  const [totalFatLabel, setTotalFatLabel] = useState();
  const [saturatedFatLabel, setSaturatedFatLabel] = useState();
  const [transFatLabel, setTransFatLabel] = useState();
  const [cholesterolLabel, setCholesterolLabel] = useState();
  const [sodiumLabel, setSodiumLabel] = useState();
  const [ironLabel, setIronLabel] = useState();
  const [flourLabel, setFlourLabel] = useState();
  const [typeofoilLabel, setTypeofoilLabel] = useState();
  const [colorLabel, setColorLabel] = useState();
  const [addedFlavourLabel, setAddedflavourLabel] = useState();
  const [dehydratedVeglabel, setdehydratedLabel] = useState();
  const [tasteFlavourLabel, setTasteFlavourLabel] = useState();
  const [shelfLifeLabel, setShelfLifeLabel] = useState();
  const [cookingTimelabel, setCokkingTimeLabel] = useState();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    SuggestedProductData();
  }, []);
  // API call for comparing food products
  const SuggestedProductData = () => {
    setLoading(true);
    const newSrting = path.split("/").join(",");
    let url =
      process.env.REACT_APP_BASEURL +
      `products_compare/?compare_data=${newSrting}`;
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
        Authorization: secureLocalStorage.getItem("token"),
      },
    };

    axios
      .get(url, config)
      .then((res) => {
        setAllData(res.data);
        if (res.data) {
          // Energy
          const energyLabelManagement = res.data.filter((item) => {
            if (item.energy !== "Not Available") {
              return item;
            }
          });
          setEnergyLabel(energyLabelManagement.length);

          // Protein
          const proteinLabelManagement = res.data.filter((item) => {
            if (item.protein !== "Not Available") {
              return item;
            }
          });
          setProteinLabel(proteinLabelManagement.length);

          // Carbs
          const carbsLabelManagement = res.data.filter((item) => {
            if (item.carbs !== "Not Available") {
              return item;
            }
          });
          setCarbsLabel(carbsLabelManagement.length);

          // Total Sugar

          const totalSugarLabelManagement = res.data.filter((item) => {
            if (item.total_sugar !== "Not Available") {
              return item;
            }
          });
          setTotalSugarLabel(totalSugarLabelManagement.length);

          // Sugar

          const sugarLabelManagement = res.data.filter((item) => {
            if (item.sugar !== "Not Available") {
              return item;
            }
          });
          setSugarLabel(sugarLabelManagement.length);

          // Fiber

          const fiberLabelManagement = res.data.filter((item) => {
            if (item.fiber !== "Not Available") {
              return item;
            }
          });
          setFiberLabel(fiberLabelManagement.length);

          // Total Fat
          const totalFatLabelManagement = res.data.filter((item) => {
            if (item.total_fat !== "Not Available") {
              return item;
            }
          });
          setTotalFatLabel(totalFatLabelManagement.length);

          // Saturated Fat

          const saturatedFatLabelManagement = res.data.filter((item) => {
            if (item.saturated_fat !== "Not Available") {
              return item;
            }
          });
          setSaturatedFatLabel(saturatedFatLabelManagement.length);

          // Trans Fat
          const transFatLabelManagement = res.data.filter((item) => {
            if (item.trans_fat !== "Not Available") {
              return item;
            }
          });
          setTransFatLabel(transFatLabelManagement.length);

          // Cholesterol
          const cholesterolLabelManagement = res.data.filter((item) => {
            if (item.cholesterol !== "Not Available") {
              return item;
            }
          });
          setCholesterolLabel(cholesterolLabelManagement.length);

          // sodium

          const sodiumLabelManagement = res.data.filter((item) => {
            if (item.sodium !== "Not Available") {
              return item;
            }
          });
          setSodiumLabel(sodiumLabelManagement.length);

          // iron

          const ironLabelManagement = res.data.filter((item) => {
            if (item.iron !== "Not Available") {
              return item;
            }
          });
          setIronLabel(ironLabelManagement.length);

          // flour

          const flourLabelManagement = res.data.filter((item) => {
            if (item.flour !== "Not Available") {
              return item;
            }
          });
          setFlourLabel(flourLabelManagement.length);

          // type_of_oil

          const typeofoilLabelManagement = res.data.filter((item) => {
            if (item.type_of_oil !== "Not Available") {
              return item;
            }
          });
          setTypeofoilLabel(typeofoilLabelManagement.length);

          // color

          const colorLabelManagement = res.data.filter((item) => {
            if (item.color !== "Not Available") {
              return item;
            }
          });
          setColorLabel(colorLabelManagement.length);

          // added_flavour

          const addedflavourLabelManagement = res.data.filter((item) => {
            if (item.added_flavour !== "Not Available") {
              return item;
            }
          });
          setAddedflavourLabel(addedflavourLabelManagement.length);

          // dehydrates_veg

          const dehydratesvegLabelManagement = res.data.filter((item) => {
            if (item.dehydrates_veg !== "Not Available") {
              return item;
            }
          });
          setdehydratedLabel(dehydratesvegLabelManagement.length);

          // test_flavour

          const tasteflavourLabelManagement = res.data.filter((item) => {
            if (item.test_flavour !== "Not Available") {
              return item;
            }
          });
          setTasteFlavourLabel(tasteflavourLabelManagement.length);

          // shelf_life

          const shelfLifeLabelManagement = res.data.filter((item) => {
            if (item.shelf_life !== "Not Available") {
              return item;
            }
          });
          setShelfLifeLabel(shelfLifeLabelManagement.length);

          // cooking_time

          const cookingTimeLabelManagement = res.data.filter((item) => {
            if (item.cooking_time !== "Not Available") {
              return item;
            }
          });
          setCokkingTimeLabel(cookingTimeLabelManagement.length);
        }
        const allProd = res.data;
        allProd.map((item, index) => {
          setEnergy((energy) => [...energy, item.energy]);
          setProtein((protein) => [...protein, item.protein]);
          setCarb((carbs) => [...carbs, item.carbs]);
          setTotalSugar((totalSugar) => [...totalSugar, item.total_sugar]);
          setSugar((sugar) => [...sugar, item.sugar]);
          setFiber((fiber) => [...fiber, item.fiber]);
          setTotalFat((totalFat) => [...totalFat, item.total_fat]);
          setSatuFat((satuFat) => [...satuFat, item.saturated_fat]);
          setTransFat((transFat) => [...transFat, item.trans_fat]);
          setCholes((choles) => [...choles, item.cholesterol]);
          setSodium((sodium) => [...sodium, item.sodium]);
          setIron((iron) => [...iron, item.iron]);
          if (allProd.length - 1 === index) {
            setLoading(false);
          }
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  // useEffect(() => {
  //     // let propsData = location.state.data
  //     if (secureLocalStorage.getItem('compare_data')) {
  //         secureLocalStorage.removeItem('compare_data')
  //     }
  //     setLoading(true)

  //     propsData.map((item, index) => {
  //         if ((secureLocalStorage.getItem("isGeolocationAvailable") && secureLocalStorage.getItem("isGeolocationAvailable") === true) && (secureLocalStorage.getItem("coords") !== null) && (secureLocalStorage.getItem("coords").latitude || secureLocalStorage.getItem("coords").longitude !== undefined)) {
  //         let url = process.env.REACT_APP_BASEURL + `products/${item.public_id}/?lat=${JSON.stringify(secureLocalStorage.getItem("coords").latitude)}&long=${JSON.stringify(secureLocalStorage.getItem("coords").longitude)}&type=compared&attr_id=${item.attr_id}`
  //         const config = {
  //             headers: {
  //                 "Access-Control-Allow-Origin": "*",
  //                 "Content-type": "application/json",
  //                 "Authorization": secureLocalStorage.getItem("token"),
  //             }
  //         };
  //         axios.get(url, config)
  //             .then(res => {
  //                 setAllData((allData) => [...allData, ...res.data])
  //                 setEnergy((energy) => [...energy, res.data[].energy])
  //                 setProtein((protein) => [...protein, res.data[0].protein])
  //                 setCarb((carbs) => [...carbs, res.data[0].carbs])
  //                 setTotalSugar((totalSugar) => [...totalSugar, res.data[0].total_sugar])
  //                 setSugar((sugar) => [...sugar, res.data[0].sugar])
  //                 setFiber((fiber) => [...fiber, res.data[0].fiber])
  //                 setTotalFat((totalFat) => [...totalFat, res.data[0].total_fat])
  //                 setSatuFat((satuFat) => [...satuFat, res.data[0].saturated_fat])
  //                 setTransFat((transFat) => [...transFat, res.data[0].trans_fat])
  //                 setCholes((choles) => [...choles, res.data[0].cholesterol])
  //                 setSodium((sodium) => [...sodium, res.data[0].sodium])
  //                 setIron((iron) => [...iron, res.data[0].iron])

  //                 // This is to make sure loading is set to false only after the very last data is prepared
  //                 if (propsData.length - 1 === index) {
  //                     setLoading(false)
  //                 }

  //             }
  //             )
  //             .catch(err => {
  //                 setLoading(false)
  //             })
  //         return null
  //     }else{
  //             let url = process.env.REACT_APP_BASEURL + `products/${item.public_id}/?&type=compared&attr_id=${item.attr_id}`
  //             const config = {
  //                 headers: {
  //                     "Access-Control-Allow-Origin": "*",
  //                     "Content-type": "application/json",
  //                     "Authorization": secureLocalStorage.getItem("token"),
  //                 }
  //             };
  //             axios.get(url, config)
  //                 .then(res => {
  //                     setAllData((allData) => [...allData, ...res.data])
  //                     setEnergy((energy) => [...energy, res.data[0].energy])
  //                     setProtein((protein) => [...protein, res.data[0].protein])
  //                     setCarb((carbs) => [...carbs, res.data[0].carbs])
  //                     setTotalSugar((totalSugar) => [...totalSugar, res.data[0].total_sugar])
  //                     setSugar((sugar) => [...sugar, res.data[0].sugar])
  //                     setFiber((fiber) => [...fiber, res.data[0].fiber])
  //                     setTotalFat((totalFat) => [...totalFat, res.data[0].total_fat])
  //                     setSatuFat((satuFat) => [...satuFat, res.data[0].saturated_fat])
  //                     setTransFat((transFat) => [...transFat, res.data[0].trans_fat])
  //                     setCholes((choles) => [...choles, res.data[0].cholesterol])
  //                     setSodium((sodium) => [...sodium, res.data[0].sodium])
  //                     setIron((iron) => [...iron, res.data[0].iron])
  //                     // This is to make sure loading is set to false only after the very last data is prepared
  //                     if (propsData.length - 1 === index) {
  //                         setLoading(false)
  //                     }
  //                 }
  //                 )
  //                 .catch(err => {
  //                     setLoading(false)
  //                 })
  //             return null

  //     }

  // }
  //     )
  // }, [])
  const navigate = useNavigate();
  useEffect(() => {
    setMaxEnergy(Math.max(...energy));
    setMaxProtein(Math.max(...protein));
    setMaxCarbs(Math.max(...carb));
    setMaxTotalSugar(Math.max(...totalSugar));
    setMaxSugar(Math.max(...sugar));
    setMaxFiber(Math.max(...fiber));
    setMaxTotalFat(Math.max(...totalFat));
    setMaxSatuFat(Math.max(...satuFat));
    setMaxTransFat(Math.max(...transFat));
    setMaxCholes(Math.max(...choles));
    setMaxSodium(Math.max(...sodium));
    setMaxIron(Math.max(...iron));
  }, [iron]);
  const { t } = useTranslation();
  const checkUser = (item) => {
    if (secureLocalStorage.getItem("token") !== null) {
      navigate(`/product/${item.public_id}/`);
    } else {
      // setShowModal(true)
      secureLocalStorage.setItem("prod_id", `${item.public_id}`);
      // secureLocalStorage.setItem("attr_id", `${item.attr_id}`)
    }
  };

  return (
    <>
      <div className="pt-5 contact-bg" style={{ paddingBottom: "75px" }}></div>
      <div style={{ marginTop: 40 }}>
        <div className="position-relative">
          <button
            onClick={() => {
              localStorage.removeItem("homePageFlag");
              if (searchParams.get("homePageFlag")) {
                navigate("/");
              } else {
                navigate(`/products/${localStorage.getItem("search")}`);
              }
            }}
            style={{ backgroundColor: "#5CB85C" }}
            className="btn text-white ms-2 position-absolute top-0 start-0 translate-middle-y sm_screenbtn"
          >
            Go Back
          </button>
        </div>
        <h1 className="text-center mb-3">Compare Products</h1>
        {loading ? (
          <CompareSkeleton data={items} />
        ) : (
          <>
            <Container fluid>
              <Row>
                {allData?.map((item, index) => {
                  return (
                    <Col className={`custom-bg-${index} `} key={item.public_id}>
                      {/* <div className='d-flex justify-content-center align-items-center mt-5 '>
                                                    <img src={item?.img} className={`img-size-compare custom-img-${index}-border`} />
                                                </div> */}
                      <div
                        className="mt-2"
                        onClick={() => {
                          checkUser(item);
                        }}
                        style={{
                          background: `url(${
                            item.img ? item.img : ProductPlaceholder
                          })`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "contain",
                          width: "100%",
                          height: "300px",
                          cursor: "pointer",
                        }}
                      ></div>
                      <Container className="ps-2 pt-2">
                        {/* **************  Product name ************** */}
                        <h5 className={`prod-name-${index}-color`}>
                          Brand Name
                        </h5>
                        <p style={{ color: "#616161" }}>{item?.brand_name}</p>
                        <h5 className={`prod-name-${index}-color`}>
                          Product Name
                        </h5>
                        <p style={{ color: "#616161" }}>{item?.product_name}</p>
                        <h5 className={`prod-name-${index}-color`}>
                          Product Description
                        </h5>
                        <p
                          className={`disc-${index}-color`}
                          style={{ color: "#616161" }}
                        >
                          {item?.product_description === "Not Available"
                            ? item?.product_name
                            : `${item?.product_description.slice(0, 150)}...`}
                        </p>
                        <h5 style={{ color: "#003366" }}>Key points</h5>
                        <div className="d-flex flex-column justify-content-center">
                          {item?.product_veg_nonveg ===
                          "Not Available" ? null : (
                            <div className="d-flex justify-content-start  align-items-center mt-2">
                              <div
                                className="d-flex justify-content-center  align-items-center text-white "
                                style={{
                                  minWidth: "105px",
                                  height: "30px",
                                  backgroundColor: `${
                                    index === 0
                                      ? "#003366"
                                      : index === 1
                                      ? "#5CB85C"
                                      : index === 2
                                      ? "#487BAD"
                                      : index === 3
                                      ? "#D7B58B"
                                      : null
                                  }`,
                                  borderTopLeftRadius: "6px",
                                  borderBottomLeftRadius: "6px",
                                }}
                              >
                                Type
                              </div>
                              <div
                                className="d-flex justify-content-center align-items-center "
                                style={{
                                  minWidth: "125px",
                                  height: "30px",
                                  backgroundColor: `${
                                    index === 0
                                      ? "#E3E3E3"
                                      : index === 1
                                      ? "#fff"
                                      : index === 2
                                      ? "#E3E3E3"
                                      : index === 3
                                      ? "#fff"
                                      : null
                                  }`,
                                  borderTopRightRadius: "6px",
                                  borderBottomRightRadius: "6px",
                                  flexGrow: 0,
                                  flexShrink: 0,
                                  flexBasis: "auto",
                                }}
                              >
                                {item?.product_veg_nonveg === "veg"
                                  ? "Vegetarian"
                                  : item?.product_veg_nonveg === "non"
                                  ? "Non-vegetarian"
                                  : null}
                              </div>
                            </div>
                          )}
                          {item?.product_veg_nonveg ===
                          "Not Available" ? null : (
                            <div className="media_box d-flex justify-content-start  align-items-center mt-2">
                              <div
                                className="d-flex justify-content-center  align-items-center text-white "
                                style={{
                                  minWidth: "135px",
                                  height: "30px",
                                  backgroundColor: `${
                                    index === 0
                                      ? "#003366"
                                      : index === 1
                                      ? "#5CB85C"
                                      : index === 2
                                      ? "#487BAD"
                                      : index === 3
                                      ? "#D7B58B"
                                      : null
                                  }`,
                                  borderTopLeftRadius: "6px",
                                  borderBottomLeftRadius: "6px",
                                }}
                              >
                                Food Category
                              </div>
                              <div
                                className="d-flex justify-content-start align-items-start ps-2 pe-2"
                                style={{
                                  minWidth: "160px",
                                  height: "30px",
                                  backgroundColor: `${
                                    index === 0
                                      ? "#E3E3E3"
                                      : index === 1
                                      ? "#fff"
                                      : index === 2
                                      ? "#E3E3E3"
                                      : index === 3
                                      ? "#fff"
                                      : null
                                  }`,
                                  borderTopRightRadius: "6px",
                                  borderBottomRightRadius: "6px",
                                  flexGrow: 0,
                                  flexShrink: 0,
                                  flexBasis: "auto",
                                }}
                              >
                                {item?.food_category === "Not Available"
                                  ? null
                                  : item?.food_category}
                              </div>
                            </div>
                          )}
                        </div>
                        {/* **************  Fassi Certification ************** */}
                        <h5 style={{ marginTop: "30px", color: "#003366" }}>
                          Certification
                        </h5>
                        <div className="d-flex justify-content-start     flex-row flex-wrap pb-4">
                          {item?.has_certification ===
                          "Not Available" ? null : (
                            <div className="d-flex justify-content-center  align-items-center mt-2 ">
                              <div
                                className="d-flex justify-content-center  align-items-center text-white "
                                style={{
                                  width: "140px",
                                  height: "30px",
                                  backgroundColor: `${
                                    index === 0
                                      ? "#003366"
                                      : index === 1
                                      ? "#5CB85C"
                                      : index === 2
                                      ? "#487BAD"
                                      : index === 3
                                      ? "#D7B58B"
                                      : null
                                  }`,
                                  borderTopLeftRadius: "6px",
                                  borderBottomLeftRadius: "6px",
                                }}
                              >
                                Has certification
                              </div>
                              <div
                                className="d-flex justify-content-center align-items-center "
                                style={{
                                  minWidth: "104px",
                                  height: "30px",
                                  backgroundColor: `${
                                    index === 0
                                      ? "#E3E3E3"
                                      : index === 1
                                      ? "#fff"
                                      : index === 2
                                      ? "#E3E3E3"
                                      : index === 3
                                      ? "#fff"
                                      : null
                                  }`,
                                  borderTopRightRadius: "6px",
                                  borderBottomRightRadius: "6px",
                                  flexGrow: 0,
                                  flexShrink: 0,
                                  flexBasis: "auto",
                                }}
                              >
                                {item?.has_certification}
                              </div>
                            </div>
                          )}
                          {item.lic_no === "Not Available" ? null : (
                            <div className="d-flex justify-content-center  align-items-center mt-2">
                              <div
                                className="d-flex justify-content-center  align-items-center text-white "
                                style={{
                                  minWidth: "90px",
                                  height: "30px",
                                  backgroundColor: `${
                                    index === 0
                                      ? "#003366"
                                      : index === 1
                                      ? "#5CB85C"
                                      : index === 2
                                      ? "#487BAD"
                                      : index === 3
                                      ? "#D7B58B"
                                      : null
                                  }`,
                                  borderTopLeftRadius: "6px",
                                  borderBottomLeftRadius: "6px",
                                }}
                              >
                                Lic. No.
                              </div>
                              <div
                                className="d-flex justify-content-center align-items-center "
                                style={{
                                  minWidth: "160px",
                                  height: "30px",
                                  backgroundColor: `${
                                    index === 0
                                      ? "#E3E3E3"
                                      : index === 1
                                      ? "#fff"
                                      : index === 2
                                      ? "#E3E3E3"
                                      : index === 3
                                      ? "#fff"
                                      : null
                                  }`,
                                  borderTopRightRadius: "6px",
                                  borderBottomRightRadius: "6px",
                                  flexGrow: 0,
                                  flexShrink: 0,
                                  flexBasis: "auto",
                                }}
                              >
                                {item?.lic_no}
                              </div>
                            </div>
                          )}
                          {item?.religious_sentiments ===
                          "Not Available" ? null : (
                            <div className="d-flex justify-content-center  align-items-center mt-2">
                              <div
                                className="d-flex justify-content-center  align-items-center text-white "
                                style={{
                                  minWidth: "105px",
                                  height: "30px",
                                  backgroundColor: `${
                                    index === 0
                                      ? "#003366"
                                      : index === 1
                                      ? "#5CB85C"
                                      : index === 2
                                      ? "#487BAD"
                                      : index === 3
                                      ? "#D7B58B"
                                      : null
                                  }`,
                                  borderTopLeftRadius: "6px",
                                  borderBottomLeftRadius: "6px",
                                }}
                              >
                                Type
                              </div>
                              <div
                                className="d-flex justify-content-center align-items-center "
                                style={{
                                  minWidth: "110px",
                                  height: "30px",
                                  backgroundColor: `${
                                    index === 0
                                      ? "#E3E3E3"
                                      : index === 1
                                      ? "#fff"
                                      : index === 2
                                      ? "#E3E3E3"
                                      : index === 3
                                      ? "#fff"
                                      : null
                                  }`,
                                  borderTopRightRadius: "6px",
                                  borderBottomRightRadius: "6px",
                                  flexGrow: 0,
                                  flexShrink: 0,
                                  flexBasis: "auto",
                                }}
                              >
                                {item?.religious_sentiments === "Yes"
                                  ? "Non Halal"
                                  : item?.religious_sentiments === "No"
                                  ? "Halal"
                                  : null}
                              </div>
                            </div>
                          )}
                        </div>
                      </Container>
                    </Col>
                  );
                })}
              </Row>
            </Container>
            {/* **************  Nutrition information ************** */}
            {energyLabel === 0 &&
            proteinLabel === 0 &&
            carbsLabel === 0 &&
            totalSugarLabel === 0 &&
            sugarLabel === 0 &&
            fiberLabel === 0 &&
            totalFatLabel === 0 &&
            saturatedFatLabel === 0 &&
            transFatLabel === 0 &&
            cholesterolLabel === 0 &&
            sodiumLabel === 0 &&
            ironLabel === 0 ? null : (
              <Container fluid className="p-5">
                <Row>
                  <h4 className="text-center">Nutrition Information</h4>
                </Row>
                <Row>
                  <div className="d-flex flex-column flex-md-row justify-content-center align-items-center mb-3">
                    {allData.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="d-flex flex-row align-items-center"
                        >
                          <div
                            style={{
                              height: 15,
                              width: 15,
                              borderRadius: "100%",
                              backgroundColor: `${
                                index === 0
                                  ? col0
                                  : index === 1
                                  ? col1
                                  : index === 2
                                  ? col2
                                  : index === 3
                                  ? col3
                                  : null
                              }`,
                              marginRight: 5,
                            }}
                          ></div>
                          <div className="me-lg-3 text-start">
                            {`${item.product_name} (${item?.brand_name})`}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Row>
                {/* ******************* First row ******************* */}
                <Col>
                  <div className="d-flex flex-column flex-md-row   justify-content-around align-items-center">
                    {energyLabel === 0 ? null : (
                      <Card
                        className="d-flex w-100 p-2 me-lg-3 shadow"
                        style={{ backgroundColor: "#F4F4F4" }}
                      >
                        <div>
                          <h6>Energy</h6>
                          {allData.map((item, index) => {
                            return (
                              <div key={index}>
                                <p
                                  className={`text-end m-0 me-2 mt-2 p-text p-text-${index}-color`}
                                >
                                  {/* {item.energy === "Not Available"
                                    ? null
                                    : item.energy} */}
                                </p>
                                {item.energy === "Not Available" ? null : (
                                  <ProgressBar
                                    animateOnRender
                                    completed={Number(item.energy)}
                                    maxCompleted={Number(item.energy) + 100}
                                    bgColor={
                                      index === 0
                                        ? col0
                                        : index === 1
                                        ? col1
                                        : index === 2
                                        ? col2
                                        : index === 3
                                        ? col3
                                        : null
                                    }
                                    height={"10px"}
                                    isLabelVisible={false}
                                  />
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </Card>
                    )}
                    {proteinLabel === 0 ? null : (
                      <Card
                        className="d-flex w-100 p-2  me-lg-3 mt-3 mt-lg-0 shadow"
                        style={{ backgroundColor: "#F4F4F4" }}
                      >
                        <div>
                          <h6>Protein</h6>
                          {allData.map((item, index) => {
                            return (
                              <div key={index}>
                                <p
                                  className={`text-end m-0 me-2 mt-2 p-text p-text-${index}-color`}
                                >
                                  {/* {item.protein === "Not Available"
                                    ? null
                                    : item.protein} */}
                                </p>
                                {item.protein === "Not Available" ? null : (
                                  <ProgressBar
                                    animateOnRender
                                    completed={Number(item.protein)}
                                    maxCompleted={Number(item.protein) + 20}
                                    bgColor={
                                      index === 0
                                        ? col0
                                        : index === 1
                                        ? col1
                                        : index === 2
                                        ? col2
                                        : index === 3
                                        ? col3
                                        : null
                                    }
                                    height={"10px"}
                                    isLabelVisible={false}
                                  />
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </Card>
                    )}
                    {carbsLabel === 0 ? null : (
                      <Card
                        className="d-flex w-100 p-2 mt-3 mt-lg-0 shadow"
                        style={{ backgroundColor: "#F4F4F4" }}
                      >
                        <div>
                          <h6>Carbohydrate</h6>
                          {allData.map((item, index) => {
                            return (
                              <div key={index}>
                                <p
                                  className={`text-end m-0 me-2 mt-2 p-text p-text-${index}-color`}
                                >
                                  {/* {item.carbs === "Not Available"
                                    ? null
                                    : item.carbs} */}
                                </p>
                                {item.carbs === "Not Available" ? null : (
                                  <ProgressBar
                                    animateOnRender
                                    completed={Number(item.carbs)}
                                    maxCompleted={Number(item.carbs) + 50}
                                    bgColor={
                                      index === 0
                                        ? col0
                                        : index === 1
                                        ? col1
                                        : index === 2
                                        ? col2
                                        : index === 3
                                        ? col3
                                        : null
                                    }
                                    height={"10px"}
                                    isLabelVisible={false}
                                  />
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </Card>
                    )}
                  </div>
                </Col>
                {/* ******************* Second row ******************* */}
                <Col className="mt-lg-4">
                  <div className="d-flex flex-column flex-md-row   justify-content-around align-items-center">
                    {totalSugarLabel === 0 ? null : (
                      <Card
                        className="d-flex w-100 p-2 me-lg-3 mt-3 mt-lg-0 shadow"
                        style={{ backgroundColor: "#F4F4F4" }}
                      >
                        <div>
                          <h6>Total Sugar</h6>
                          {allData.map((item, index) => {
                            return (
                              <div key={index}>
                                <p
                                  className={`text-end m-0 me-2 mt-2 p-text p-text-${index}-color`}
                                >
                                  {/* {item.total_sugar === "Not Available"
                                    ? null
                                    : item.total_sugar} */}
                                </p>
                                {item.total_sugar === "Not Available" ? null : (
                                  <ProgressBar
                                    animateOnRender
                                    completed={Number(item.total_sugar)}
                                    maxCompleted={Number(item.total_sugar) + 20}
                                    bgColor={
                                      index === 0
                                        ? col0
                                        : index === 1
                                        ? col1
                                        : index === 2
                                        ? col2
                                        : index === 3
                                        ? col3
                                        : null
                                    }
                                    height={"10px"}
                                    isLabelVisible={false}
                                  />
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </Card>
                    )}
                    {sugarLabel === 0 ? null : (
                      <Card
                        className="d-flex w-100 p-2  me-lg-3 mt-3 mt-lg-0 shadow"
                        style={{ backgroundColor: "#F4F4F4" }}
                      >
                        <div>
                          <h6>Sugar (Sucrose)</h6>
                          {allData.map((item, index) => {
                            return (
                              <div key={index}>
                                <p
                                  className={`text-end m-0 me-2 mt-2 p-text p-text-${index}-color`}
                                >
                                  {/* {item.sugar === "Not Available"
                                    ? null
                                    : item.sugar} */}
                                </p>
                                {item.sugar === "Not Available" ? null : (
                                  <ProgressBar
                                    animateOnRender
                                    completed={Number(item.sugar)}
                                    maxCompleted={Number(item.sugar) + 20}
                                    bgColor={
                                      index === 0
                                        ? col0
                                        : index === 1
                                        ? col1
                                        : index === 2
                                        ? col2
                                        : index === 3
                                        ? col3
                                        : null
                                    }
                                    height={"10px"}
                                    isLabelVisible={false}
                                  />
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </Card>
                    )}
                    {fiberLabel === 0 ? null : (
                      <Card
                        className="d-flex w-100 p-2 mt-3 mt-lg-0 shadow"
                        style={{ backgroundColor: "#F4F4F4" }}
                      >
                        <div>
                          <h6>Fiber</h6>
                          {allData.map((item, index) => {
                            return (
                              <div key={index}>
                                <p
                                  className={`text-end m-0 me-2 mt-2 p-text p-text-${index}-color`}
                                >
                                  {/* {item.fiber === "Not Available"
                                    ? null
                                    : item.fiber} */}
                                </p>
                                {item.fiber === "Not Available" ? null : (
                                  <ProgressBar
                                    animateOnRender
                                    completed={Number(item.fiber)}
                                    maxCompleted={Number(item.fiber) + 3}
                                    bgColor={
                                      index === 0
                                        ? col0
                                        : index === 1
                                        ? col1
                                        : index === 2
                                        ? col2
                                        : index === 3
                                        ? col3
                                        : null
                                    }
                                    height={"10px"}
                                    isLabelVisible={false}
                                  />
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </Card>
                    )}
                  </div>
                </Col>
                {/* ******************* Third row ******************* */}
                <Col className="mt-lg-4 mt-3 mt-lg-0">
                  <div className="d-flex flex-column flex-md-row   justify-content-around align-items-center">
                    {totalFatLabel === 0 ? null : (
                      <Card
                        className="d-flex w-100 p-2 me-lg-3 shadow"
                        style={{ backgroundColor: "#F4F4F4" }}
                      >
                        <div>
                          <h6>Total Fat</h6>
                          {allData.map((item, index) => {
                            return (
                              <div key={index}>
                                <p
                                  className={`text-end m-0 me-2 mt-2 p-text p-text-${index}-color`}
                                >
                                  {/* {item.total_fat === "Not Available"
                                    ? null
                                    : item.total_fat} */}
                                </p>
                                {item.total_fat === "Not Available" ? null : (
                                  <ProgressBar
                                    animateOnRender
                                    completed={Number(item.total_fat)}
                                    maxCompleted={Number(item.total_fat) + 50}
                                    bgColor={
                                      index === 0
                                        ? col0
                                        : index === 1
                                        ? col1
                                        : index === 2
                                        ? col2
                                        : index === 3
                                        ? col3
                                        : null
                                    }
                                    height={"10px"}
                                    isLabelVisible={false}
                                  />
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </Card>
                    )}
                    {saturatedFatLabel === 0 ? null : (
                      <Card
                        className="d-flex w-100 p-2  me-lg-3  mt-3 mt-lg-0 shadow"
                        style={{ backgroundColor: "#F4F4F4" }}
                      >
                        <div>
                          <h6>Saturated Fat</h6>
                          {allData.map((item, index) => {
                            return (
                              <div key={index}>
                                <p
                                  className={`text-end m-0 me-2 mt-2 p-text p-text-${index}-color`}
                                >
                                  {/* {item.saturated_fat === "Not Available"
                                    ? null
                                    : item.saturated_fat} */}
                                </p>
                                {item.saturated_fat ===
                                "Not Available" ? null : (
                                  <ProgressBar
                                    animateOnRender
                                    completed={Number(item.saturated_fat)}
                                    maxCompleted={
                                      Number(item.saturated_fat) + 5
                                    }
                                    bgColor={
                                      index === 0
                                        ? col0
                                        : index === 1
                                        ? col1
                                        : index === 2
                                        ? col2
                                        : index === 3
                                        ? col3
                                        : null
                                    }
                                    height={"10px"}
                                    isLabelVisible={false}
                                  />
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </Card>
                    )}
                    {transFatLabel === 0 ? null : (
                      <Card
                        className="d-flex w-100 p-2 mt-3 mt-lg-0 shadow"
                        style={{ backgroundColor: "#F4F4F4" }}
                      >
                        <div>
                          <h6>Trans Fat</h6>
                          {allData.map((item, index) => {
                            return (
                              <div key={index}>
                                <p
                                  className={`text-end m-0 me-2 mt-2 p-text p-text-${index}-color`}
                                >
                                  {/* {item.trans_fat === "Not Available"
                                    ? null
                                    : item.trans_fat} */}
                                </p>
                                {item.trans_fat === "Not Available" ? null : (
                                  <ProgressBar
                                    animateOnRender
                                    completed={Number(item.trans_fat)}
                                    maxCompleted={Number(item.trans_fat) + 2}
                                    bgColor={
                                      index === 0
                                        ? col0
                                        : index === 1
                                        ? col1
                                        : index === 2
                                        ? col2
                                        : index === 3
                                        ? col3
                                        : null
                                    }
                                    height={"10px"}
                                    isLabelVisible={false}
                                  />
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </Card>
                    )}
                  </div>
                </Col>
                {/* ******************* Fourth row ******************* */}
                <Col className="mt-lg-4 mt-3 mt-lg-0">
                  <div className="d-flex flex-column flex-md-row   justify-content-around align-items-center">
                    {cholesterolLabel === 0 ? null : (
                      <Card
                        className="d-flex w-100 p-2 me-lg-3 shadow"
                        style={{ backgroundColor: "#F4F4F4" }}
                      >
                        <div>
                          <h6>Cholestrol</h6>
                          {allData.map((item, index) => {
                            return (
                              <div key={index}>
                                <p
                                  className={`text-end m-0 me-2 mt-2 p-text p-text-${index}-color`}
                                >
                                  {/* {item.cholesterol === "Not Available"
                                    ? null
                                    : item.cholesterol} */}
                                </p>
                                {item.cholesterol === "Not Available" ? null : (
                                  <ProgressBar
                                    animateOnRender
                                    completed={Number(item.cholesterol)}
                                    maxCompleted={Number(item.cholesterol) + 5}
                                    bgColor={
                                      index === 0
                                        ? col0
                                        : index === 1
                                        ? col1
                                        : index === 2
                                        ? col2
                                        : index === 3
                                        ? col3
                                        : null
                                    }
                                    height={"10px"}
                                    isLabelVisible={false}
                                  />
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </Card>
                    )}
                    {sodiumLabel === 0 ? null : (
                      <Card
                        className="d-flex w-100 p-2  me-lg-3 mt-3 mt-lg-0 shadow"
                        style={{ backgroundColor: "#F4F4F4" }}
                      >
                        <div>
                          <h6>Sodium</h6>
                          {allData.map((item, index) => {
                            return (
                              <div key={index}>
                                <p
                                  className={`text-end m-0 me-2 mt-2 p-text p-text-${index}-color`}
                                >
                                  {/* {item.sodium === "Not Available"
                                    ? null
                                    : item.sodium} */}
                                </p>
                                {item.sodium === "Not Available" ? null : (
                                  <ProgressBar
                                    animateOnRender
                                    completed={Number(item.sodium)}
                                    maxCompleted={Number(item.sodium) + 1000}
                                    bgColor={
                                      index === 0
                                        ? col0
                                        : index === 1
                                        ? col1
                                        : index === 2
                                        ? col2
                                        : index === 3
                                        ? col3
                                        : null
                                    }
                                    height={"10px"}
                                    isLabelVisible={false}
                                  />
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </Card>
                    )}
                    {ironLabel === 0 ? null : (
                      <Card
                        className="d-flex w-100 p-2  mt-3 mt-lg-0 shadow"
                        style={{ backgroundColor: "#F4F4F4" }}
                      >
                        <div>
                          <h6>Iron</h6>
                          {allData.map((item, index) => {
                            return (
                              <div key={index}>
                                <p
                                  className={`text-end m-0 me-2 mt-2 p-text p-text-${index}-color`}
                                >
                                  {/* {item.iron === "Not Available"
                                    ? null
                                    : item.iron} */}
                                </p>
                                {item.iron === "Not Available" ? null : (
                                  <ProgressBar
                                    animateOnRender
                                    completed={Number(item.iron)}
                                    maxCompleted={Number(item.iron) + 20}
                                    bgColor={
                                      index === 0
                                        ? col0
                                        : index === 1
                                        ? col1
                                        : index === 2
                                        ? col2
                                        : index === 3
                                        ? col3
                                        : null
                                    }
                                    height={"10px"}
                                    isLabelVisible={false}
                                  />
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </Card>
                    )}
                  </div>
                </Col>
              </Container>
            )}

            {/* ************** Ingredients ************** */}
            {flourLabel === 0 &&
            typeofoilLabel === 0 &&
            // colorLabel === 0 &&
            addedFlavourLabel === 0 &&
            dehydratedVeglabel === 0 ? null : (
              <Container fluid className="p-5 ">
                <Row>
                  <h4 className="text-center ">Ingredients</h4>
                </Row>
                <Row>
                  <div className="d-flex flex-column flex-md-row justify-content-center align-items-center mb-3">
                    {allData.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="d-flex flex-row align-items-center"
                        >
                          <div
                            style={{
                              height: 15,
                              minWidth: 15,
                              borderRadius: "100%",
                              backgroundColor: `${
                                index === 0
                                  ? col0
                                  : index === 1
                                  ? col1
                                  : index === 2
                                  ? col2
                                  : index === 3
                                  ? col3
                                  : null
                              }`,
                              marginRight: 5,
                            }}
                          ></div>
                          <div className="me-lg-3 text-start">
                            {`${item.product_name} (${item?.brand_name})`}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Row>
                {/* ******************* First row ******************* */}
                <Col>
                  <div className="d-flex flex-column flex-md-row   justify-content-around align-items-center">
                    {flourLabel === 0 ? null : (
                      <Card
                        className="d-flex w-100 p-2 me-lg-3 shadow"
                        style={{ backgroundColor: "#F4F4F4" }}
                      >
                        <div>
                          <h6>Flour</h6>
                          {allData.map((item, index) => {
                            return (
                              <h6
                                key={index}
                                className={`text-start m-0 ms-2 mt-2 p-text-${index}-color`}
                              >
                                {item.flour === "Not Available"
                                  ? null
                                  : item.flour}
                              </h6>
                            );
                          })}
                        </div>
                      </Card>
                    )}
                    {typeofoilLabel === 0 ? null : (
                      <Card
                        className="d-flex w-100 p-2  me-lg-3 mt-3 mt-lg-0 shadow"
                        style={{ backgroundColor: "#F4F4F4" }}
                      >
                        <div>
                          <h6>Type of oil</h6>
                          {allData.map((item, index) => {
                            return (
                              <h6
                                key={index}
                                className={`text-start m-0 ms-2 mt-2 p-text-${index}-color`}
                              >
                                {item.type_of_oil === "Not Available"
                                  ? null
                                  : item.type_of_oil}
                              </h6>
                            );
                          })}
                        </div>
                      </Card>
                    )}
                    {/* {colorLabel === 0 ? null : (
                      <Card
                        className="d-flex w-100 p-2 mt-3 mt-lg-0 shadow"
                        style={{ backgroundColor: "#F4F4F4" }}
                      >
                        <div>
                          <h6>Food color</h6>
                          {allData.map((item, index) => {
                            return (
                              <h6
                                key={index}
                                className={`text-start m-0 ms-2 mt-2 p-text-${index}-color`}
                              >
                                {item.color === "Not Available"
                                  ? null
                                  : item.color}
                              </h6>
                            );
                          })}
                        </div>
                      </Card>
                    )} */}
                  </div>
                </Col>
                {/* ******************* Second row ******************* */}
                <Col className="mt-lg-4 mt-3 mt-lg-0">
                  <div className="d-flex flex-column flex-md-row   justify-content-around align-items-center">
                    {addedFlavourLabel === 0 ? null : (
                      <Card
                        className="d-flex w-100 p-2 me-lg-3 shadow"
                        style={{ backgroundColor: "#F4F4F4" }}
                      >
                        <div>
                          <h6>Added Flavour</h6>
                          {allData.map((item, index) => {
                            return (
                              <h6
                                key={index}
                                className={`text-start m-0 ms-2 mt-2 p-text-${index}-color`}
                              >
                                {item.added_flavour === "Not Available"
                                  ? null
                                  : item.added_flavour}
                              </h6>
                            );
                          })}
                        </div>
                      </Card>
                    )}
                    {dehydratedVeglabel === 0 ? null : (
                      <Card
                        className="d-flex w-100 p-2  me-lg-3 mt-3 mt-lg-0 shadow"
                        style={{ backgroundColor: "#F4F4F4" }}
                      >
                        <div>
                          <h6>Vegetables (Dehydrated)</h6>
                          {allData.map((item, index) => {
                            return (
                              <h6
                                key={index}
                                className={`text-start m-0 ms-2 mt-2 p-text-${index}-color`}
                              >
                                {item.dehydrates_veg === "Not Available"
                                  ? null
                                  : item.dehydrates_veg}
                              </h6>
                            );
                          })}
                        </div>
                      </Card>
                    )}
                    {/* Adding empty div to make other card same For flex  */}
                    <div className=" d-flex w-100 p-2 mt-3 mt-lg-0"></div>
                  </div>
                </Col>
              </Container>
            )}

            {/* ************** Other ************** */}
            {tasteFlavourLabel === 0 &&
            shelfLifeLabel === 0 &&
            cookingTimelabel === 0 ? null : (
              <Container fluid className="p-5  mb-4">
                <Row>
                  <h4 className="text-center">Other</h4>
                </Row>
                <Row>
                  <div className="d-flex flex-column flex-md-row justify-content-center align-items-center mb-3">
                    {allData.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="d-flex flex-row align-items-center"
                        >
                          <div
                            style={{
                              height: 15,
                              width: 15,
                              borderRadius: "100%",
                              backgroundColor: `${
                                index === 0
                                  ? col0
                                  : index === 1
                                  ? col1
                                  : index === 2
                                  ? col2
                                  : index === 3
                                  ? col3
                                  : null
                              }`,
                              marginRight: 5,
                            }}
                          ></div>
                          <div className="me-lg-3 text-start">
                            {`${item.product_name} (${item?.brand_name})`}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Row>
                {/* ******************* First row ******************* */}
                <Col>
                  <div className="d-flex flex-column flex-md-row   justify-content-around align-items-center">
                    {tasteFlavourLabel === 0 ? null : (
                      <Card
                        className="d-flex w-100 p-2 me-lg-3 shadow"
                        style={{ backgroundColor: "#F4F4F4" }}
                      >
                        <div>
                          <h6>Taste</h6>
                          {allData.map((item, index) => {
                            return (
                              <h6
                                key={index}
                                className={`text-start m-0 ms-2 mt-2 p-text-${index}-color`}
                              >
                                {item.test_flavour === "Not Available"
                                  ? null
                                  : item.test_flavour}
                              </h6>
                            );
                          })}
                        </div>
                      </Card>
                    )}
                    {shelfLifeLabel === 0 ? null : (
                      <Card
                        className="d-flex w-100 p-2  me-lg-3 mt-3 mt-lg-0 shadow"
                        style={{ backgroundColor: "#F4F4F4" }}
                      >
                        <div>
                          <h6>Shelf Life (Best before)</h6>
                          {allData.map((item, index) => {
                            return (
                              <h6
                                key={index}
                                className={`text-start m-0 ms-2 mt-2 p-text-${index}-color `}
                              >
                                {item.shelf_life === "Not Available"
                                  ? null
                                  : item.shelf_life}
                              </h6>
                            );
                          })}
                        </div>
                      </Card>
                    )}
                    {cookingTimelabel === 0 ? null : (
                      <Card
                        className="d-flex w-100 p-2 mt-3 mt-lg-0 shadow"
                        style={{ backgroundColor: "#F4F4F4" }}
                      >
                        <div>
                          <h6>Cooking Time</h6>
                          {allData.map((item, index) => {
                            return (
                              <h6
                                key={index}
                                className={`text-start m-0 ms-2 mt-2 p-text-${index}-color`}
                              >
                                {item.cooking_time === "Not Available"
                                  ? null
                                  : item.cooking_time}
                              </h6>
                            );
                          })}
                        </div>
                      </Card>
                    )}
                  </div>
                </Col>
              </Container>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default Compare;
