import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Image1 from "../../assets/images/firstcard1.png";
import Image2 from "../../assets/images/secondcard1.png";
import Image3 from "../../assets/images/thirdcard1.png";
import ImageD from "../../assets/images/bg_homecol11.png";
import ImageE from "../../assets/images/Home1.png";

function FoodContent() {
  const { t } = useTranslation();
  return (
    <>
      <div className="">
        <div className="d-flex justify-content-center flex-column mt-5 pt-5">
          <h1
            className="text-center  mt-5"
            style={{
              fontSize: "30px",
              fontWeight: "500",
              lineHeight: "40px",
              color: "#003366",
            }}
          >
            Why Choose Poorna Satya (The Complete Truth)
          </h1>
          <Row className="d-flex justify-content-center align-items-center mt-5">
            <Col
              lg={4}
              md={12}
              sm={12}
              className="d-flex justify-content-center mt-2"
            >
              <div>
                <span className="first_img p-3">
                  <h4 style={{ color: "#034053", fontSize: "22px" }}>
                    Uncover the Details
                  </h4>
                  <p style={{ color: "#000", fontSize: "15px" }}>
                    Discover nutritional information,
                    <br />
                    ingredient lists, and more.
                  </p>
                </span>
                <img src={Image1} className="img-fluid" alt="nutritional"></img>
              </div>
            </Col>
            <Col
              lg={4}
              md={12}
              sm={12}
              className="d-flex justify-content-center mt-2"
            >
              <div>
                <span className="first_img p-3">
                  <h4 style={{ color: "#034053", fontSize: "22px" }}>
                    Compare Products
                  </h4>
                  <p style={{ color: "#000", fontSize: "15px" }}>
                    Easily compare different <br />
                    products side by side.
                  </p>
                </span>
                <img src={Image2} className="img-fluid" alt="compare"></img>
              </div>
            </Col>
            <Col
              lg={4}
              md={12}
              sm={12}
              className="d-flex justify-content-center mt-2"
            >
              <div>
                <span className="first_img p-3">
                  <h4 style={{ color: "#034053", fontSize: "22px" }}>
                    Get Recommendations
                  </h4>
                  <p style={{ color: "#000", fontSize: "15px" }}>
                    Find products that match your <br />
                    lifestyle and preferences.
                  </p>
                </span>
                <img src={Image3} className="img-fluid" alt="food"></img>
              </div>
            </Col>
          </Row>
        </div>
        {/* <div>
                    <Row className="d-flex justify-content-center align-items-center mt-4">
                        <Col lg={12} md={12} sm={12} className="d-flex justify-content-center mb-2">
                            <div>
                                <img src={Image4} className="img-fluid" alt="nutritional"></img>
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={12} className="d-flex justify-content-center mt-3">
                            <div>
                                <img src={Image5} className="img-fluid rounded" alt="compare"></img>
                            </div>
                        </Col>
                    </Row>
                </div> */}
        <div>
          <Row className="d-flex justify-content-center align-items-center mt-4 p-3 rounded-3 bg_homecard">
            <Col
              lg={5}
              md={12}
              sm={12}
              className="d-flex justify-content-center mb-2"
            >
              <div>
                <img src={ImageD} className="img-fluid" alt="nutritional"></img>
              </div>
            </Col>
            <Col
              lg={6}
              md={12}
              sm={12}
              className="d-flex justify-content-start mt-3"
            >
              <div className="mt-5 mb-5 text-black">
                <h3 style={{ color: "#034053" }}>
                  Do You Know What You're Eating?
                </h3>
                <span className="lh-lg">
                  In a world filled with diverse dietary preferences, knowing
                  the suitability of your food choices can be a game-changer.
                  Whether you follow a halal lifestyle, prefer satvik options,
                  or have unique dietary requirements, Poorna Satya is your
                  trusted companion.
                </span>
                <hr style={{ color: "#034053" }} className="p-0" />
                <h3 className="mt-3" style={{ color: "#034053" }}>
                  Unlock the Mystery: Halal or Satvik?
                </h3>
                <span className="lh-lg">
                  Discover the truth about your food's suitability for your
                  lifestyle. Poorna Satya empowers you with detailed insights
                  into whether a product aligns with your dietary preferences,
                  helping you make choices that resonate with your values.
                </span>
              </div>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center align-items-center mt-4 p-3 rounded-3 bg_homecard2">
            <Col
              lg={6}
              md={12}
              sm={12}
              className="d-flex justify-content-start mt-3"
            >
              <div className="mt-5 mb-5 text-black">
                <h3 style={{ color: "#034053" }}>
                  Elevate Your Food Experience with Poorna Satya
                </h3>
                <ul className="lh-lg">
                  <li>
                    Find out if your favorite products are halal or satvik.
                  </li>
                  <li>Explore comprehensive nutritional information.</li>
                  <li>Compare and make informed choices.</li>
                  <li>Never wonder what's in your food again.</li>
                  <li>
                    Take control of your health and make informed choices.
                  </li>
                </ul>
              </div>
            </Col>
            <Col
              lg={5}
              md={12}
              sm={12}
              className="d-flex justify-content-center mb-2"
            >
              <div>
                <img src={ImageE} className="img-fluid" alt="nutritional"></img>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* <div className="content-bg d-flex justify-content-center flex-column  align-items-center   py-2 container">
                <div className="text-center mt-5 pt-5">
                    <span style={{ fontSize: '28px', fontWeight: '500' }} >KNOW WHAT YOU ARE EATING</span>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    <Row className="d-flex flex-lg-row  flex-column justify-content-center  align-items-center  mt-5 pt-3  ">
                        <Col className="d-flex justify-content-center flex-row " lg={5}  >
                            <img src={nutrition}
                        
                            style={{ height: '280px', aspectRatio:'1/1' }}
                            ></img>
                            <div className="d-flex flex-column  col-6">
                                <span className="ps-4 content-heading" >Nutritional Content</span>
                                <p className="content-p ps-4">Search your food choice and get the nutrional content in just one click.</p>
                            </div>
                        </Col>
                        <Col className="d-flex justify-content-center flex-row " lg={5}  >
                            <img src={comapre}
                            style={{ height: '280px', aspectRatio: '1/1' }} 
                            ></img>
                            <div className="d-flex flex-column  col-6">
                                <span className="ps-4 content-heading" >Compare options</span>
                                <p className="mt-2 ps-4 content-p" >Compare your food choices across brands and variants. Eat healthy.</p>
                            </div></Col>

                    </Row>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    <Row className="d-flex flex-lg-row  flex-column justify-content-center  align-items-center   mt-lg-5 mt-3 ">
                        <Col className="d-flex justify-content-center flex-row " lg={5}  >
                            <img src={food} className="ps-3"

                            style={{ height: '280px', aspectRatio:'1/1' }}
                            ></img>
                            <div className="d-flex flex-column  col-6">
                                <span className="ps-4 content-heading" >Food Certification</span>
                                <p className="content-p ps-4">Check the food certification and
                                    accredition details of what your
                                    are eating.</p>
                            </div>
                        </Col>
                        <Col className="d-flex justify-content-center flex-row " lg={5}  >
                            <img src={recommend}
                            style={{ height: '280px', aspectRatio: '1/1' }} 
                            ></img>
                            <div className="d-flex flex-column  col-6">
                                <span className="ps-4 content-heading" >Recommendations</span>
                                <p className="mt-2 ps-4 content-p" >Based on data and research, we recommend the food as per your preferences and health target</p>
                            </div></Col>

                    </Row>
                </div>
               
            </div> */}
      {/* <div className="mt-5 mb-5 d-flex justify-content-center ">
<Col lg={12}>
                    <Carousel variant="dark">
                        <Carousel.Item>
                            <span>fsjkajdfkasdfkljasf</span>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block "
                                src="holder.js/800x400?text=Second slide&bg=eee"
                                alt="Second slide"
                            />
                            <Carousel.Caption>
                                <h5>Second slide label</h5>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block "
                                src="holder.js/800x400?text=Third slide&bg=e5e5e5"
                                alt="Third slide"
                            />
                            <Carousel.Caption>
                                <h5>Third slide label</h5>
                                <p>
                                    Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                                </p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
</Col>
               
</div> */}
    </>
  );
}
export default FoodContent;
