import React, { useEffect, useState } from "react";
import ProductPlaceholder from "../../assets/images/prod_placeholder.png";
import "./Products.css";
import { useTranslation } from "react-i18next";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import LoginOtpMob from "../LoginOtpMob/LoginOtpMob";

function TrendingProduct() {
  // let [compareProducts, setCompareProducts] = useState([])
  let [compareProductOne, setCompareProductOne] = useState([]);
  let [compareProductTwo, setCompareProductTwo] = useState([]);
  let [compareProductThree, setCompareProductThree] = useState([]);
  // const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [logIn, setLogIng] = useState(false);

  const { t } = useTranslation();
  let navigate = useNavigate();
  useEffect(() => {
    // SuggestedProductData();
    ComparProductData();
  }, []);
  // const SuggestedProductData = () => {
  //     setIsLoading(true)

  //     let url = process.env.REACT_APP_BASEURL + "suggested-products/"
  //     const config = {
  //         headers: {

  //             "Access-Control-Allow-Origin": "*",
  //             "Content-type": "application/json",
  //         }
  //     };

  //     axios.get(url, config)
  //         .then(res => {

  //             setProducts(res.data)

  //             setIsLoading(false)
  //         }
  //         ).catch(err => {

  //             setIsLoading(false)
  //         })
  // }

  // API for POPULAR COMPARISIONS List on homepage
  const ComparProductData = () => {
    // setIsLoading(true)

    let url = process.env.REACT_APP_BASEURL + "compare_list/";
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
      },
    };

    axios
      .get(url, config)
      .then((res) => {
        // setCompareProducts(res.data)
        setCompareProductOne(res.data[0]);
        setCompareProductTwo(res.data[1]);
        setCompareProductThree(res.data[2]);
        // setIsLoading(false)
      })
      .catch((err) => {
        // setIsLoading(false)
      });
  };
  // const checkUser = (item) => {
  //     if (secureLocalStorage.getItem('token') !== null) {
  //         navigate(`/product/${item.public_id}/${item.attr_id}`)
  //     }
  //     else {
  //         setShowModal(true)
  //         secureLocalStorage.setItem("prod_id", `${item.public_id}`)
  //         secureLocalStorage.setItem("attr_id", `${item.attr_id}`)
  //     }
  // }
  const handleCompareClick = () => {
    if (secureLocalStorage.getItem("token") !== null) {
      const publicIds = compareProductOne
        .map((item) => item.public_id)
        .join("/");
      navigate(`/compare/${publicIds}?homePageFlag=true`);
    } else {
      setShowModal(true);
      const publicIds = compareProductOne
        .map((item) => item.public_id)
        .join("/");
      secureLocalStorage.setItem("public_id", JSON.stringify(publicIds));
      localStorage.setItem("homePageFlag", true);
    }
  };
  const handleCompareClick2 = () => {
    if (secureLocalStorage.getItem("token") !== null) {
      const publicIds = compareProductTwo
        .map((item) => item.public_id)
        .join("/");
      navigate(`/compare/${publicIds}?homePageFlag=true`);
    } else {
      setShowModal(true);
      const publicIds = compareProductTwo
        .map((item) => item.public_id)
        .join("/");
      secureLocalStorage.setItem("public_id", JSON.stringify(publicIds));
      localStorage.setItem("homePageFlag", true);
    }
  };
  const handleCompareClick3 = () => {
    if (secureLocalStorage.getItem("token") !== null) {
      const publicIds = compareProductThree
        .map((item) => item.public_id)
        .join("/");
      navigate(`/compare/${publicIds}?homePageFlag=true`);
    } else {
      setShowModal(true);
      const publicIds = compareProductThree
        .map((item) => item.public_id)
        .join("/");
      secureLocalStorage.setItem("public_id", JSON.stringify(publicIds));
      localStorage.setItem("homePageFlag", true);
    }
  };
  return (
    <>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ color: "#034053" }}
          >
            Login to continue
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center pt-3 fs-5">
            In order to view the details please login first.
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            style={{ width: 120 }}
            className="signin-button "
            onClick={() => {
              setLogIng(true);
            }}
          >
            Login
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={logIn}
        onHide={() => setLogIng(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="p-0"
      >
        {/* <Modal.Header closeButton>

                </Modal.Header> */}
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center">
            <LoginOtpMob closemodal={setLogIng} />
          </div>
        </Modal.Body>
      </Modal>
      {/* <div style={{ background: '#F9F8F8' }} className="py-2">
                <div className="text-center mb-5 mt-3">
                    <span style={{ fontSize: '28px', fontWeight: 500, }}>TRENDING PRODUCTS</span>
                </div>
                <Col className='d-flex flex-direction-row flex-wrap justify-content-center mb-5' >

                    {isLoading ? <SuggestedProductSkeleton /> :

                        products.slice(0, 3).map((item, index) => {
                            return (
                                <>


                                    <div key={item.public_id} className="card card-custom mb-4 me-sm-0 me-md-4  " style={{
                                        background: `url(${item.img !== null ? item.img : ProductPlaceholder})`,
                                        backgroundRepeat: 'no-repeat', backgroundPosition: "center", backgroundSize: 'auto', width: 300, height: 214
                                    }}>
                                        <div className="mx-3 my-2 d-flex justify-content-end  align-items-center  " >

                                            <div className='d-flex align-items-center '>


                                            </div>
                                        </div>
                                        <div className="card-body d-flex align-items-center justify-content-center" style={{
                                            background: "linear-gradient(to top,rgb(8, 8, 8),transparent)"
                                        }}>
                                            <div className="d-flex align-items-center ">
                                                <h4 style={{ color: "white", }}>
                                                    <b>
                                                        <TextTruncate
                                                            line={2}
                                                            element="span"
                                                            truncateText="…"
                                                            text={item.product_name}
                                                        />
                                                    </b></h4>
                                            </div>

                                            <div className='card-button'  >

                                                <button onClick={() => { checkUser(item) }} className='btn btn-secondary button-view' style={{ backgroundColor: "#C8811A", border: 'none' }}>View details</button>

                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }
                    {
                        products.length === 0 && isLoading !== true ?
                            <h3>No results found</h3>
                            : null
                    }
                </Col>
            </div> */}
      {compareProductOne?.length > 0 &&
      compareProductTwo?.length > 0 &&
      compareProductThree?.length > 0 ? (
        <div
          style={{ paddingRight: "29px" }}
          className="background-popular py-2"
        >
          <div className="text-center mb-5 mt-3">
            <span
              style={{ fontSize: "28px", fontWeight: 500, color: "#003366" }}
            >
              POPULAR COMPARISIONS
            </span>
            <p style={{ fontSize: "18px", fontWeight: 500, color: "black" }}>
              See what others are comparing
            </p>
          </div>
          <Row className="d-flex flex-lg-row flex-md-row flex-sm-column flex-wrap justify-content-around mb-5">
            <Col className="d-flex flex-row col-lg-3 col-md-5 col-sm-12 col-10 ">
              {compareProductOne?.length > 0 &&
                compareProductOne.map((item, i) => {
                  return (
                    <div
                      onClick={handleCompareClick}
                      style={{ cursor: "pointer" }}
                    >
                      <div className=" d-flex flex-wrap">
                        <div className="d-flex justify-content-center align-items-center ">
                          {compareProductOne.length - 1 <= i ? (
                            <img
                              src={require("../../assets/images/vs.png")}
                              alt="trend"
                              style={{ position: "absolute", zIndex: "999" }}
                            />
                          ) : null}
                        </div>
                        <div className=" d-flex  ">
                          {/* <div key={item.public_id} className="card mb-4   " style={{
                                                background: `url(${item.img !== null ? item.img : ProductPlaceholder})`,
                                                backgroundRepeat: 'no-repeat', backgroundPosition: "center"
                                            }}>
                                                <div className="compare-card"></div>
                                            </div> */}
                          <div
                            key={item.public_id}
                            className="card mb-4"
                            style={{
                              background: `url(${
                                item.img !== null
                                  ? item.img
                                  : ProductPlaceholder
                              })`,
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                              backgroundSize: "contain",
                              width: "100%",
                              cursor: "pointer",
                            }}
                          >
                            <div className="compare-card"></div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-11 text-center"
                        style={{ fontWeight: "500" }}
                      >
                        <span>{item.product_name}</span>
                      </div>
                    </div>
                  );
                })}
            </Col>
            <Col className="d-flex flex-row col-lg-3 col-md-5 col-sm-12 col-10 ">
              {compareProductOne?.length > 0 &&
                compareProductTwo.map((item, i) => {
                  return (
                    <div
                      onClick={handleCompareClick2}
                      style={{ cursor: "pointer" }}
                    >
                      <div className=" d-flex flex-wrap">
                        <div className="d-flex justify-content-center align-items-center ">
                          {compareProductOne.length - 1 <= i ? (
                            <img
                              src={require("../../assets/images/vs.png")}
                              alt="trend"
                              style={{ position: "absolute", zIndex: "999" }}
                            />
                          ) : null}
                        </div>
                        <div className=" d-flex  ">
                          {/* <div key={item.public_id} className="card  mb-4   " style={{
                                                background: `url(${item.img !== null ? item.img : ProductPlaceholder})`,
                                                backgroundRepeat: 'no-repeat', backgroundPosition: "center"
                                            }}>
                                                <div className="compare-card"></div>
                                            </div> */}
                          <div
                            key={item.public_id}
                            className="card mb-4"
                            style={{
                              background: `url(${
                                item.img !== null
                                  ? item.img
                                  : ProductPlaceholder
                              })`,
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                              backgroundSize: "contain",
                              width: "100%",
                              cursor: "pointer",
                            }}
                          >
                            <div className="compare-card"></div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-11 text-center"
                        style={{ fontWeight: "500" }}
                      >
                        <span>{item.product_name}</span>
                      </div>
                    </div>
                  );
                })}
            </Col>
            <Col className="d-flex flex-row col-lg-3 col-md-5 col-sm-12 col-10 ">
              {compareProductOne?.length > 0 &&
                compareProductThree.map((item, i) => {
                  return (
                    <div
                      onClick={handleCompareClick3}
                      style={{ cursor: "pointer" }}
                    >
                      <div className=" d-flex flex-wrap">
                        <div className="d-flex justify-content-center align-items-center ">
                          {compareProductOne.length - 1 <= i ? (
                            <img
                              src={require("../../assets/images/vs.png")}
                              alt="trend"
                              style={{ position: "absolute", zIndex: "999" }}
                            />
                          ) : null}
                        </div>
                        <div className=" d-flex  ">
                          {/* <div key={item.public_id} className="card  mb-4   " style={{
                                                background: `url(${item.img !== null ? item.img : ProductPlaceholder})`,
                                                backgroundRepeat: 'no-repeat', backgroundPosition: "center",
                                            }}>
                                                <div className="compare-card"></div>
                                            </div> */}
                          <div
                            key={item.public_id}
                            className="card mb-4"
                            style={{
                              background: `url(${
                                item.img !== null
                                  ? item.img
                                  : ProductPlaceholder
                              })`,
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                              backgroundSize: "contain",
                              width: "100%",
                              cursor: "pointer",
                            }}
                          >
                            <div className="compare-card"></div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-11 text-center"
                        style={{ fontWeight: "500" }}
                      >
                        <span>{item.product_name}</span>
                      </div>
                    </div>
                  );
                })}
            </Col>
          </Row>
        </div>
      ) : null}
    </>
  );
}

export default TrendingProduct;
