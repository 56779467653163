import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./SuggestedProduct.css";
import { useTranslation } from "react-i18next";
import secureLocalStorage from "react-secure-storage";
import LoginOtpMob from "../LoginOtpMob/LoginOtpMob";
const SuggestedProduct = () => {
  let navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [logIn, setLogIng] = useState(false);
  const { t } = useTranslation();
  const viewProduct = (prodId, attributeId) => {
    if (secureLocalStorage.getItem("token") !== null) {
      navigate(`/product/${prodId}/${attributeId}?homePageFlag=true`);
    } else {
      setShowModal(true);
      secureLocalStorage.setItem("prod_id", `${prodId}`);
      secureLocalStorage.setItem("attr_id", `${attributeId}`);
      localStorage.setItem("homePageFlag", true);
    }
  };
  return (
    <div>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ color: "#034053" }}
          >
            Login to continue
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="fs-5">
          <p className="text-center  pt-3">
            In order to view the details please login first.
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            style={{ width: 120 }}
            className="signin-button  "
            onClick={() => {
              setLogIng(true);
            }}
          >
            Login
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={logIn}
        onHide={() => setLogIng(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="p-0"
      >
        {/* <Modal.Header closeButton>

              </Modal.Header> */}
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center">
            <LoginOtpMob closemodal={setLogIng}></LoginOtpMob>
          </div>
        </Modal.Body>
      </Modal>

      <div className="background-trend py-5">
        <div className="d-flex justify-content-center ps-4">
          <span
            style={{
              color: "#003366",
              textAlign: "center",
              fontSize: "28px",
              fontWeight: "500",
            }}
          >
            TRENDING PRODUCTS
          </span>
        </div>
        <div className="d-flex justify-content-center ps-4">
          <span
            style={{
              color: "black",
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "500",
            }}
          >
            Stay in the loop with the latest trends in the world of nutrition.
          </span>
        </div>
        <div className="d-flex justify-content-center suggested-product-container mt-3 ">
          {/* <Row className='trendproduct-responsive'>
        <Col lg={6} md={12} sm={12}  className="d-flex justify-content-center mt-2 ">
                          <div onClick={() => { viewProduct("2568403567888616/1201394907410920") }} style={{cursor:'pointer'}}>

                        <img src={require('../../assets/images/trend_1.png')} alt="product1"></img>
                    </div>
                </Col>
             <Col lg={6} md={12} className="d-flex justify-content-center mt-2">
                    <div onClick={() => { viewProduct("3626450509231945/2450751675279496") }} style={{cursor:'pointer'}}>

                        <img src={require('../../assets/images/trend_2.png')} alt="product2"></img>
                    </div>
                </Col>
                <Col className="d-flex justify-content-center mt-2" lg={12}>
                    <div onClick={() => { viewProduct("8890302046816456/6281564784489448") }} style={{cursor:'pointer'}}> 

                        <img src={require('../../assets/images/trend_3.png')} className='suggested-product-image' alt="product3"></img>
                    </div>
                </Col>
        </Row>
        <Row className='trendproduct-responsive' >
            <Col lg={12} className="d-flex justify-content-center mt-2">
            <div  onClick={() => { viewProduct("1892723132158633/4873113954262568") }} style={{cursor:'pointer'}}>

                        <img src={require('../../assets/images/trend_4.png')} alt="product4" className='suggested-product-image'></img>
                    </div>
            </Col>
        </Row> */}
          <Row className="trendproduct-responsive">
            <Col
              lg={3}
              md={6}
              sm={12}
              className="d-flex justify-content-center mt-2 "
            >
              <div
                onClick={() => {
                  viewProduct("3899777145525417", "224961804894056");
                }}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={require("../../assets/images/trend_1.png")}
                  alt="product1"
                ></img>
              </div>
            </Col>
            <Col
              lg={3}
              md={6}
              sm={12}
              className="d-flex justify-content-center mt-2"
            >
              <div
                onClick={() => {
                  viewProduct("3888105547580201", "8594485908261545");
                }}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={require("../../assets/images/trend_2.png")}
                  alt="product2"
                ></img>
              </div>
            </Col>
            <Col
              lg={3}
              md={6}
              sm={12}
              className="d-flex justify-content-center mt-2 "
            >
              <div
                onClick={() => {
                  viewProduct("3936293260022985", "7002067890794217");
                }}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={require("../../assets/images/trend_3.png")}
                  alt="product3"
                ></img>
              </div>
            </Col>
            <Col
              lg={3}
              md={6}
              sm={12}
              className="d-flex justify-content-center mt-2"
            >
              <div
                onClick={() => {
                  viewProduct("7907955914477416", "522521253398248");
                }}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={require("../../assets/images/trend_4.png")}
                  alt="product4"
                ></img>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default SuggestedProduct;

// import React, { useEffect, useState } from "react";
// import TextTruncate from 'react-text-truncate';
// import ProductPlaceholder from '../../assets/images/prod_placeholder.png'
// import Card from 'react-bootstrap/Card';
// import './Products.css';
// import { useTranslation } from 'react-i18next';
// import secureLocalStorage from 'react-secure-storage';
// import axios from "axios";
// import { Button, Col, Modal, Row, } from 'react-bootstrap'
// import ProductSkeleton from "../skeleton/productSkeleton";
// import { useNavigate } from "react-router";
// import { AiOutlineArrowRight } from "react-icons/ai";
// import { BsArrowRight } from "react-icons/bs";
// import SuggestedProductSkeleton from "../skeleton/SuggstedProductSkeleton";
// import './SuggestedProduct.css'
// function SuggestedProduct() {
//     let [products, setProducts] = useState([])
//     const [isLoading, setIsLoading] = useState(false);
//     const [showModal, setShowModal] = useState(false)
//     let [trend1, setTrend1] = useState({
//         name: '',
//         img: "",
//         public_id: "",
//         allergen_info: "",
//         product_description: ""
//     });
//     let [trend2, setTrend2] = useState({
//         name: '',
//         img: "",
//         public_id: "",
//         allergen_info: "",
//         product_description: ""
//     });
//     let [trend3, setTrend3] = useState({
//         name: '',
//         img: "",
//         public_id: "",
//         allergen_info: "",
//         product_description: ""
//     });
//     let [trend4, setTrend4] = useState({
//         name: '',
//         img: "",
//         public_id: "",
//         allergen_info: "",
//         product_description: ""
//     });

//     const { t } = useTranslation();
//     let navigate = useNavigate()
//     useEffect(() => {
//         SuggestedProductData();
//     }, [])
//     const SuggestedProductData = () => {
//         setIsLoading(true)

//         let url = process.env.REACT_APP_BASEURL + "suggested-products/"
//         const config = {
//             headers: {

//                 "Access-Control-Allow-Origin": "*",
//                 "Content-type": "application/json",
//             }
//         };

//         axios.get(url, config)
//             .then(res => {
//                 setProducts(res.data)
//                 setTrend1({
//                     name: res.data[0].product_name,
//                     img: res.data[0].img,
//                     public_id: res.data[0].public_id,
//                     allergen_info: res.data[0].allergen_info,
//                     product_description: res.data[0].product_description
//                 })
//                 setTrend2({
//                     name: res.data[1].product_name,
//                     img: res.data[1].img,
//                     public_id: res.data[1].public_id,
//                     allergen_info: res.data[1].allergen_info,
//                     product_description: res.data[1].product_description
//                 })
//                 setTrend3({
//                     name: res.data[2].product_name,
//                     img: res.data[2].img,
//                     public_id: res.data[2].public_id,
//                     allergen_info: res.data[2].allergen_info,
//                     product_description: res.data[2].product_description
//                 })
//                 setTrend4({
//                     name: res.data[3].product_name,
//                     img: res.data[3].img,
//                     public_id: res.data[3].public_id,
//                     allergen_info: res.data[3].allergen_info,
//                     product_description: res.data[3].product_description
//                 })

//                 setIsLoading(false)
//             }
//             ).catch(err => {

//                 setIsLoading(false)
//             })
//     }
//     const checkUser = (item) => {
//         if (secureLocalStorage.getItem('token') !== null) {
//             navigate(`/product/${item.public_id}`)
//         }
//         else {
//             setShowModal(true)
//             secureLocalStorage.setItem("prod_id", `${item.public_id}`)
//             secureLocalStorage.setItem("attr_id", `${item.attr_id}`)
//         }
//     }

//     return (
//         <>
//             <Modal
//                 show={showModal}
//                 onHide={() => { setShowModal(false) }}
//                 size="md"
//                 aria-labelledby="contained-modal-title-vcenter"
//                 centered
//             >
//                 <Modal.Header closeButton>
//                     <Modal.Title id="contained-modal-title-vcenter">
//                         {t("products.login_popup.login_to_conti")}
//                     </Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <p>
//                         {t("products.login_popup.login_desc")}
//                     </p>
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button style={{ width: 120 }} className='signin-button ' onClick={() => { navigate('/login') }}>{t("products.login_popup.login")}</Button>
//                 </Modal.Footer>
//             </Modal>

// <div className="background-trend">

//             <div className="d-flex justify-content-center w-98 flex-md-column flex-lg-row flex-column">
//                 <Row className='flex-row w-100 w-lg-50 w-md-100 justify-content-center'>
//                     <Col lg={12} className='d-flex flex-direction-row flex-wrap justify-content-center ' >
//                         <div key={trend1.public_id} onClick={() => { checkUser(trend1) }} className={`card mb-4 me-sm-0 me-md-4 `} style={{

//                             background: `url(${trend1.img !== null ? trend1.img : ProductPlaceholder})`,
//                             backgroundRepeat: 'no-repeat', backgroundPosition: "center", backgroundSize: 'auto', width: "100%", height: 390, cursor: 'pointer'
//                         }}>
//                             <div className="mx-3 my-2 d-flex justify-content-end  align-items-center  " >
//                                 <div className='d-flex align-items-center '>
//                                 </div>
//                             </div>
//                             <div className="card-body d-flex align-items-center justify-content-center" style={{
//                                 background: "linear-gradient(to top,rgb(3, 4,5,3),transparent)",
//                             }}>
//                                 <div className="d-flex justify-content-center flex-column" >
//                                     <h4 style={{ color: "#fff", textAlign: 'center' }}>
//                                         <b>
//                                             <TextTruncate
//                                                 line={2}
//                                                 element="span"
//                                                 truncateText="…"
//                                                 text={trend1.name}
//                                             />
//                                         </b></h4>
//                                     <p style={{ color: '#fff', textAlign: 'center' }}><b>
//                                         <TextTruncate
//                                             line={3}
//                                             element="span"
//                                             truncateText="…"
//                                             text={trend1.allergen_info !== "nan" && trend1.allergen_info !== 'Not Available' ? trend1.allergen_info : null}
//                                         /></b></p>
//                                     <p style={{ fontSize: '14px', color: '#fff', textAlign: 'center' }}>
//                                         <TextTruncate
//                                             line={3}
//                                             element="span"
//                                             truncateText="…"
//                                             text={trend1.product_description}

//                                         />
//                                     </p>
//                                     <div className="text-center" style={{ color: '#ffc107', fontSize: '18px', fontWeight: '500', cursor: 'pointer' }}>
//                                         <span onClick={() => { checkUser(trend1) }} >View Details <BsArrowRight /></span>
//                                     </div>

//                                 </div>

//                                 {/* <div className='card-button'  >
//                                     <button onClick={() => { checkUser(trend1) }} className='btn btn-secondary ' style={{ backgroundColor: "#C8811A", border: 'none' }}>View details</button>
//                                 </div> */}
//                             </div>
//                         </div>
//                     </Col>
//                     <Col lg={6} className='d-flex flex-direction-row flex-wrap justify-content-center mb-5' >
//                         <div key={trend2.public_id} onClick={() => { checkUser(trend2) }} className={`card mb-4 me-md-4 `} style={{
//                             background: `url(${trend2.img !== null ? trend2.img : ProductPlaceholder})`,
//                             backgroundRepeat: 'no-repeat', backgroundPosition: "center", backgroundSize: 'auto', width: "   100%", height: 328, cursor: 'pointer'
//                         }}>
//                             <div className="mx-3 my-2 d-flex justify-content-end  align-items-center  " >
//                                 <div className='d-flex align-items-center '>
//                                 </div>
//                             </div>
//                             <div className="card-body d-flex align-items-center justify-content-center" style={{
//                                 background: "linear-gradient(to top,rgb(8, 8, 8),transparent)"
//                             }}>
//                                 <div >

//                                     <h4 style={{ color: "#fff", textAlign: 'center' }}>

//                                         <b>
//                                             <TextTruncate
//                                                 line={2}
//                                                 element="span"
//                                                 truncateText="…"
//                                                 text={trend2.name}
//                                             />
//                                         </b></h4>
//                                     <p style={{ color: '#fff', textAlign: 'center' }}><b>
//                                         <TextTruncate
//                                             line={3}
//                                             element="span"
//                                             truncateText="…"
//                                             text={trend2.allergen_info !== "nan" && trend2.allergen_info !== 'Not Available' ? trend2.allergen_info : null}
//                                         /></b></p>
//                                     <p style={{ fontSize: '14px', color: '#fff', textAlign: 'center' }}>
//                                         <TextTruncate
//                                             line={3}
//                                             element="span"
//                                             truncateText="…"
//                                             text={trend2.product_description}

//                                         />
//                                     </p>
//                                     <div className="text-center" style={{ color: '#ffc107', fontSize: '18px', fontWeight: '500', cursor: 'pointer' }}>
//                                         <span onClick={() => { checkUser(trend2) }} >View Details <BsArrowRight /></span>
//                                     </div>
//                                 </div>

//                                 {/* <div className='card-button'  >
//                                     <button onClick={() => { checkUser(trend2) }} className='btn btn-secondary button-view' style={{ backgroundColor: "#C8811A", border: 'none' }}>View details</button>
//                                 </div> */}
//                             </div>
//                         </div>
//                     </Col>
//                     <Col lg={6} className='d-flex flex-direction-row flex-wrap justify-content-center mb-5' >
//                         <div key={trend3.public_id} onClick={() => { checkUser(trend3) }} className={`card me-md-4  `} style={{
//                             background: `url(${trend3.img !== null ? trend3.img : ProductPlaceholder})`,
//                             backgroundRepeat: 'no-repeat', backgroundPosition: "center", backgroundSize: 'auto', width: "100%", height: 328, cursor: 'pointer'
//                         }}>
//                             <div className="mx-3 my-2 d-flex justify-content-end  align-items-center  " >
//                                 <div className='d-flex align-items-center '>
//                                 </div>
//                             </div>
//                             <div className="card-body d-flex align-items-center justify-content-center" style={{
//                                 background: "linear-gradient(to top,rgb(8, 8, 8),transparent)"
//                             }}>
//                                 <div >
//                                     <h4 style={{ color: "#fff", textAlign: 'center' }}>
//                                         <b>
//                                             <TextTruncate
//                                                 line={2}
//                                                 element="span"
//                                                 truncateText="…"
//                                                 text={trend3.name}
//                                             />
//                                         </b></h4>
//                                     <p style={{ color: '#fff', textAlign: 'center' }}><b>
//                                         <TextTruncate
//                                             line={3}
//                                             element="span"
//                                             truncateText="…"
//                                             text={trend3.allergen_info !== "nan" && trend3.allergen_info !== 'Not Available' ? trend3.allergen_info : null}
//                                         /></b></p>
//                                     <p style={{ fontSize: '14px', color: '#fff', textAlign: 'center' }}>
//                                         <TextTruncate
//                                             line={3}
//                                             element="span"
//                                             truncateText="…"
//                                             text={trend3.product_description}

//                                         />
//                                     </p>
//                                     <div className="text-center" style={{ color: '#ffc107', fontSize: '18px', fontWeight: '500', cursor: 'pointer' }}>
//                                         <span onClick={() => { checkUser(trend3) }} >View Details <BsArrowRight /></span>
//                                     </div>
//                                 </div>

//                                 {/* <div className='card-button'  >
//                                     <button onClick={() => { checkUser(trend3) }} className='btn btn-secondary button-view' style={{ backgroundColor: "#C8811A", border: 'none' }}>View details</button>
//                                 </div> */}
//                             </div>
//                         </div>
//                     </Col>
//                 </Row>
//                 <Row className='flex-row w-100 w-lg-50 w-md-100'>
//                     <Col lg={12} className='d-flex flex-direction-row flex-wrap justify-content-center mb-5' >
//                         <div key={trend4.public_id} onClick={() => { checkUser(trend4) }} className={`card mb-4 me-sm-0 me-md-4 lastcard`} style={{
//                             background: `url(${trend4.img !== null ? trend4.img : ProductPlaceholder})`,
//                             backgroundRepeat: 'no-repeat', backgroundPosition: "center", backgroundSize: 'contain', width: "100%", cursor: 'pointer'
//                         }}>
//                             <div className="mx-3 my-2 d-flex justify-content-end  align-items-center  " >
//                                 <div className='d-flex align-items-center '>
//                                 </div>
//                             </div>
//                             <div className="card-body d-flex align-items-center justify-content-center" style={{
//                                 background: "linear-gradient(to top,rgb(8, 8, 8),transparent)"
//                             }}>
//                                 <div >
//                                     <h4 style={{ color: "#fff", textAlign: 'center' }}>
//                                         <b>
//                                             <TextTruncate
//                                                 line={2}
//                                                 element="span"
//                                                 truncateText="…"
//                                                 text={trend4.name}
//                                             />
//                                         </b></h4>
//                                     <p style={{ color: '#fff', textAlign: 'center' }}><b>
//                                         <TextTruncate
//                                             line={3}
//                                             element="span"
//                                             truncateText="…"
//                                             text={trend4.allergen_info !== "nan" && trend4.allergen_info !== 'Not Available' ? trend4.allergen_info : null}
//                                         /></b></p>
//                                     <p style={{ fontSize: '14px', color: '#fff', textAlign: 'center' }}>
//                                         <TextTruncate
//                                             line={3}
//                                             element="span"
//                                             truncateText="…"
//                                             text={trend4.product_description}

//                                         />
//                                     </p>

//                                 </div>
//                                 <div className="text-center mt-5 pt-5" style={{ color: '#ffc107', fontSize: '18px', fontWeight: '500', cursor: 'pointer' }}>
//                                         <span onClick={() => { checkUser(trend4) }} >View Details <BsArrowRight /></span>
//                                     </div>
//                                 {/*
//                                 <div className='card-button'  >
//                                     <button onClick={() => { checkUser(trend4) }} className='btn btn-secondary button-view' style={{ backgroundColor: "#C8811A", border: 'none' }}>View details</button>
//                                 </div> */}
//                             </div>
//                         </div>
//                     </Col>

//                 </Row>

//             </div>
//             </div>
//         </>
//     )
// }

// export default SuggestedProduct;
