import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import LogoShort from "../../assets/images/newlogo.png";
import LogoShort2 from "../../assets/images/newlogo.png";
import { MdLanguage } from "react-icons/md";
import { useTranslation } from "react-i18next";
import axios from "axios";
import "./Navigation.css";
import { useLocation, useNavigate } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { MoonLoader } from "react-spinners";
import { globalCheckErr } from "../../Check401";
import LoginOtpMob from "../LoginOtpMob/LoginOtpMob";
function Navigation() {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lan) => {
    i18n.changeLanguage(lan);
  };

  const navigate = useNavigate();
  let location = useLocation();
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [navbarColor, setNavbarColor] = useState("nav-bg2");
  const [navbarLogo, setNavbarLogo] = useState("100px");
  const [navbarLogoChange, setNavbarLogoChange] = useState(LogoShort);
  const [navbarText, setNavbarText] = useState("black");
  const [profileDetails, setProfileDetails] = useState({});
  const [logginOut, setLoggingOut] = useState(false);
  const [logIn, setLogIng] = useState(false);
  const [foodCategory, setFoodCategory] = useState();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isNavbarTransparent = currentScrollPos === 0;
      setNavbarColor(isNavbarTransparent ? "nav-bg2" : "nav-bg");
      setNavbarLogo(isNavbarTransparent ? "100px" : "60px");
      setNavbarText(isNavbarTransparent ? "black" : "black");
      setNavbarLogoChange(isNavbarTransparent ? LogoShort : LogoShort2);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    getFoodCategory();
    if (secureLocalStorage.getItem("token")) {
      setIsLoggedin(true);
      getProfile();
    } else {
      setIsLoggedin(false);
    }
  }, [secureLocalStorage.getItem("token")]);

  const getProfile = () => {
    let url_profile = process.env.REACT_APP_BASEURL + "profile/";
    const config_profile = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
        Authorization: secureLocalStorage.getItem("token"),
      },
    };
    axios
      .get(url_profile, config_profile)
      .then((res) => {
        setProfileDetails(res.data);
        console.log(profileDetails);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          globalCheckErr();
          navigate("/");
        } else {
          toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
        }
      });
  };
  const sweerAlert = () => {
    Swal.fire({
      title: "Are you sure you want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        logout();
      }
    });
  };
  const logout = () => {
    setLoggingOut(true);
    let url = process.env.REACT_APP_BASEURL + "logout/";
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
        Authorization: secureLocalStorage.getItem("token"),
      },
    };
    axios
      .delete(url, config)
      .then((res) => {
        setLoggingOut(false);
        setIsLoggedin(false);
        navigate("/");
        toast.success("Logout success!", {
          position: "top-right",
          autoClose: 2000,
          theme: "colored",
        });
        secureLocalStorage.clear();
      })
      .catch((err) => {
        setLoggingOut(false);
        toast.error("Somting went wrong!", {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      });
  };

  const closePopUp = (data) => {
    setLogIng(data);
  };

  // API for food category list in products navigation item

  const getFoodCategory = () => {
    let url = process.env.REACT_APP_BASEURL + "foodCategory/";
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
        Authorization: secureLocalStorage.getItem("token"),
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        setFoodCategory(res.data.sort());
      })
      .catch((err) => {
        if (err) {
          toast.error(
            "Getting some issue while fetching list of food category. Please try again!",
            {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
            }
          );
        }
      });
  };

  return (
    <Navbar
      fixed="top"
      collapseOnSelect
      expand="lg"
      className={navbarColor}
      style={{ transition: "background 0.5s ease" }}
    >
      <Modal
        show={logginOut}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center p-3">
            <MoonLoader color="#d67e36" loading speedMultiplier={0.7} />
          </div>
          <h5 className="m-0 text-center mt-3 p-3">Logging out...</h5>
        </Modal.Body>
      </Modal>
      <Modal
        show={logIn}
        // onHide={()=>setLogIng(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="p-0"
      >
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center">
            <LoginOtpMob closePopUp={closePopUp} closemodal={setLogIng} />
          </div>
        </Modal.Body>
      </Modal>
      <Container>
        <Navbar.Brand
          onClick={() => {
            navigate("/");
          }}
          style={{ cursor: "pointer" }}
        >
          <img
            alt="Short logo"
            src={navbarLogoChange}
            className="d-lg-inline-block d-none d-md-none d-sm-none align-top"
            style={{ width: navbarLogo }}
          />
          <img
            alt="Short logo"
            src={LogoShort2}
            className="d-sm-inline-block d-inline-block d-lg-none d-md-inline-block  "
            style={{ width: "60px" }}
          />
          {/* &nbsp;
                    &nbsp;
                    <img
                        alt="Logo text"
                        src={LogoText}
                        className="d-inline-block align-center"
                    /> */}
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto align-items-center ">
            <Nav.Link
              href="#"
              onClick={() => {
                navigate("/");
              }}
              style={{ color: navbarText }}
            >
              Home
            </Nav.Link>
            <Nav.Link
              href="#"
              onClick={() => {
                navigate("/blogs");
              }}
              style={{ color: navbarText }}
            >
              Blogs
            </Nav.Link>
            <Nav.Link
              href="#"
              onClick={() => {
                navigate("/about");
              }}
              style={{ color: navbarText }}
            >
              About{" "}
            </Nav.Link>
            <NavDropdown
              title={"Products"}
              style={{ color: "white" }}
              align="end"
              id="collasible-nav-dropdown"
              scrollable
            >
              <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                <NavDropdown.Item href="#" disabled>
                  Product List:{" "}
                </NavDropdown.Item>
                <NavDropdown.Divider />
                {foodCategory?.length > 0 &&
                  foodCategory.map((item) => {
                    return (
                      <>
                        <NavDropdown.Item
                          href="#"
                          onClick={() => {
                            navigate(`/products/${item} `, { state: item });
                            localStorage.setItem("foodCategory", item);
                            localStorage.setItem("search", item);
                          }}
                        >
                          {" "}
                          {item}{" "}
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                      </>
                    );
                  })}
              </div>
            </NavDropdown>
            <Nav.Link
              href="#"
              onClick={() => {
                navigate("/contact");
              }}
              style={{ color: navbarText }}
            >
              Contact Us
            </Nav.Link>

            {/* <NavDropdown className='p-0' title={<MdLanguage />} align="end" id="collasible-nav-dropdown">
                            <NavDropdown.Item href="#" onClick={() => { changeLanguage('en') }} >English</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#" onClick={() => { changeLanguage('hi') }}>
                                हिन्दी
                            </NavDropdown.Item>
                        </NavDropdown> */}
            {isLoggedin ? (
              <NavDropdown
                className="p-0 "
                title={
                  profileDetails.profile !== null ? (
                    <img
                      alt="Short logo"
                      className="profile-menu"
                      src={profileDetails.profile}
                    />
                  ) : profileDetails?.image_url !== null ? (
                    <img
                      alt="Short logo"
                      className="profile-menu"
                      src={profileDetails.image_url}
                    />
                  ) : (
                    <img
                      alt="Short logo"
                      className="profile-menu"
                      src={
                        require("../../assets/images/placeholder.png") ||
                        profileDetails.image_url
                      }
                    />
                  )
                }
                align="end"
                id="collasible-nav-dropdown"
              >
                <NavDropdown.Item
                  href="#"
                  className="p-3 d-flex  align-items-center "
                >
                  {profileDetails.profile !== null ? (
                    <img
                      alt="Short logo"
                      className="profile-menu"
                      src={profileDetails.profile}
                    />
                  ) : profileDetails?.image_url !== null ? (
                    <img
                      alt="Short logo"
                      className="profile-menu"
                      src={profileDetails.image_url}
                    />
                  ) : (
                    <img
                      alt="Short logo"
                      className="profile-menu"
                      src={require("../../assets/images/placeholder.png")}
                    />
                  )}
                  <span style={{ marginLeft: "0px" }}>
                    {profileDetails.fullname}
                  </span>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                {profileDetails.social_login !== true ? (
                  <>
                    <NavDropdown.Item
                      href="#"
                      onClick={() => {
                        navigate("/update-profile");
                      }}
                    >
                      Update profile
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                  </>
                ) : null}
                <NavDropdown.Item
                  href="#"
                  onClick={() => {
                    sweerAlert();
                  }}
                >
                  Log out
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <Nav.Link href="#">
                <>
                  <Button
                    className="signin-button "
                    onClick={() => {
                      setLogIng(true);
                    }}
                    size="sm"
                    style={{ borderRadius: 20, minWidth: 100 }}
                  >
                    <div>
                      <FaUserCircle className="me-1" /> Sign in
                    </div>
                  </Button>
                </>
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
