import React from "react";
import '../ProductDetails/ProductDetails.css'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Col, Container, Row, } from 'react-bootstrap'
function ProductDetailSkeleton() {
    return (<>

        <Container fluid className='p-0 m-0 mt-0 product-background pt-5 ' >
            <div className="d-flex justify-content-center ">
                <Row className="p-5">
                    <Col className='d-flex justify-content-center justify-content-lg-end ' lg={5} style={{ border: '#F4F4F4' }} >
                        <div >
                            <Skeleton className="img-size" style={{ borderRadius: '50%' }} ></Skeleton>
                        </div>
                    </Col>
                    <Col className='d-flex justify-content-start align-items-center mt-2' lg={7}>
                        <Col>
                            <Row>
                                <Skeleton style={{ height: '30px', width: '200px' }}></Skeleton>
                            </Row>
                            <Row>
                                <Skeleton style={{ height: '30px', width: '300px' }}></Skeleton>
                            </Row>
                            <Row className='mt-4'>
                                <Skeleton style={{ height: '30px', width: '200px' }}></Skeleton>
                            </Row>
                            <Row className='mt-2'>
                                <Skeleton style={{ height: '100px', width: "100%" }}></Skeleton>
                            </Row>
                            <Row className='mt-4 '>
                                <div className="d-flex ">
                                    <div>
                                        <Skeleton style={{ height: '30px', width: '180px' }}></Skeleton>
                                    </div>
                                    <div>
                                        <Skeleton style={{ height: '30px', width: '30px', borderRadius: '50%', marginLeft: '5px' }}></Skeleton>
                                    </div>
                                </div>
                            </Row>
                            <Row className='mt-4 '>
                                <div className="d-flex ">
                                    <div>
                                        <Skeleton style={{ height: '30px', width: '150px' }}></Skeleton>
                                    </div>
                                    <div>
                                        <Skeleton style={{ height: '30px', width: '150px',  marginLeft: '5px' }}></Skeleton>
                                    </div>
                                </div>
                            </Row>
                        </Col>

                    </Col>
                </Row>
            </div>
        </Container>
        <Container>
            <Row className='mt-5 d-flex justify-content-center'>
                <Col lg={8} className="mb-4">
                    <div>
                        <Skeleton style={{ height: '100px', width: '100%' }}></Skeleton>
                    </div>
                </Col>
                <Col lg={8} className="mb-4" >
                    <div>
                        <Skeleton style={{ height: '100px', width: '100%' }}></Skeleton>
                    </div>
                </Col>
                <Col lg={8} className="mb-4">
                    <div>
                        <Skeleton style={{ height: '100px', width: '100%' }}></Skeleton>
                    </div>
                </Col>
                <Col lg={8} className="mb-4">
                    <div>
                        <Skeleton style={{ height: '100px', width: '100%' }}></Skeleton>
                    </div>
                </Col>

            </Row>
        </Container>
        {/* <Container fluid className='p-5 mb-4 bg-white'>
            <Row>
                <h4 className='text-center'><Skeleton style={{ width: '200px', padding: '5px' }}></Skeleton></h4>
            </Row>
            <Row className='d-flex  mt-2'>
                <Col>
                    <div className='d-flex  justify-content-around flex-wrap'>
                        <div className='d-flex justify-content-center  align-items-center mt-2 mt-md-0'>
                            <div className='d-flex justify-content-center  align-items-center text-white ' >
                                <Skeleton style={{ width: "150px", height: "47px", borderTopLeftRadius: "8px", borderBottomLeftRadius: '8px' }}> </Skeleton>
                            </div>
                            <div className='d-flex justify-content-center align-items-center ' >
                                <Skeleton style={{ width: "80px", height: "47px", borderTopRightRadius: "8px", borderBottomRightRadius: '8px', flexGrow: 0, flexShrink: 0, flexBasis: 'auto' }}></Skeleton>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center  align-items-center sm-mt-4 mt-2 mt-md-0'>

                            <div className='d-flex justify-content-center align-items-center text-white'>
                                <Skeleton style={{ width: "90px", height: "47px", borderTopLeftRadius: "8px", borderBottomLeftRadius: '8px' }}></Skeleton>
                            </div>
                            <div className='d-flex justify-content-center align-items-center  '>
                                <Skeleton style={{ width: "180px", height: "47px", borderTopRightRadius: "8px", borderBottomRightRadius: '8px', flexGrow: 0, flexShrink: 0, flexBasis: 'auto' }}></Skeleton>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center  align-items-center mt-2 mt-md-0'>

                            <div className='d-flex justify-content-center align-items-center  text-white'>
                                <Skeleton style={{ width: "100px", height: "47px", borderTopLeftRadius: "8px", borderBottomLeftRadius: '8px' }}></Skeleton>
                            </div>
                            <div className='d-flex justify-content-center align-items-center  '>
                                <Skeleton style={{ width: "100px", height: "47px", borderTopRightRadius: "8px", borderBottomRightRadius: '8px', flexGrow: 0, flexShrink: 0, flexBasis: 'auto' }}></Skeleton>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        <Container fluid className='p-5 mb-4' style={{ backgroundColor: '#F4F4F4' }}>
            <Row>
                <h4 className='text-center'><Skeleton style={{ width: '250px' }}></Skeleton></h4>
            </Row>
            <Row className='d-flex '>
                <Col>
                    <div className='d-flex  justify-content-center flex-wrap'>
                        <div className='d-flex justify-content-center  align-items-center mt-2  me-2'>
                            <div className='d-flex justify-content-center  align-items-center text-white ' >
                                <Skeleton style={{ width: "120px", height: "47px", borderTopLeftRadius: "8px", borderBottomLeftRadius: '8px' }}></Skeleton>
                            </div>
                            <div className='d-flex justify-content-center align-items-center '>
                                <Skeleton style={{ width: "50px", height: "47px", borderTopRightRadius: "8px", borderBottomRightRadius: '8px', flexGrow: 0, flexShrink: 0, flexBasis: 'auto' }}></Skeleton>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center  align-items-center mt-2  me-2'>

                            <div className='d-flex justify-content-center align-items-center text-white' >
                                <Skeleton style={{ width: "100px", height: "47px", borderTopLeftRadius: "8px", borderBottomLeftRadius: '8px' }}></Skeleton>
                            </div>
                            <div className='d-flex justify-content-center align-items-center  '>
                                <Skeleton style={{ width: "50px", height: "47px", borderTopRightRadius: "8px", borderBottomRightRadius: '8px', flexGrow: 0, flexShrink: 0, flexBasis: 'auto' }}></Skeleton>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center  align-items-center mt-2  me-2'>
                            <div className='d-flex justify-content-center align-items-center  text-white' >
                                <Skeleton style={{ width: "100px", height: "47px", borderTopLeftRadius: "8px", borderBottomLeftRadius: '8px' }}></Skeleton>
                            </div>
                            <div className='d-flex justify-content-center align-items-center  ' >
                                <Skeleton style={{ width: "50px", height: "47px", borderTopRightRadius: "8px", borderBottomRightRadius: '8px', flexGrow: 0, flexShrink: 0, flexBasis: 'auto' }}></Skeleton>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center  align-items-center mt-2  me-2'>
                            <div className='d-flex justify-content-center align-items-center  text-white' >
                                <Skeleton style={{ width: "150px", height: "47px", borderTopLeftRadius: "8px", borderBottomLeftRadius: '8px' }}></Skeleton>
                            </div>
                            <div className='d-flex justify-content-center align-items-center  '>
                                <Skeleton style={{ width: "55px", height: "47px", borderTopRightRadius: "8px", borderBottomRightRadius: '8px', flexGrow: 0, flexShrink: 0, flexBasis: 'auto' }}></Skeleton>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center  align-items-center mt-2  me-2'>
                            <div className='d-flex justify-content-center align-items-center  text-white' >
                                <Skeleton style={{ width: "160px", height: "47px", borderTopLeftRadius: "8px", borderBottomLeftRadius: '8px' }}></Skeleton>
                            </div>
                            <div className='d-flex justify-content-center align-items-center  ' >
                                <Skeleton style={{ width: "50px", height: "47px", borderTopRightRadius: "8px", borderBottomRightRadius: '8px', flexGrow: 0, flexShrink: 0, flexBasis: 'auto' }}></Skeleton>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center  align-items-center mt-2  me-2'>
                            <div className='d-flex justify-content-center align-items-center  text-white'>
                                <Skeleton style={{ width: "100px", height: "47px", borderTopLeftRadius: "8px", borderBottomLeftRadius: '8px' }}></Skeleton>
                            </div>
                            <div className='d-flex justify-content-center align-items-center  ' >
                                <Skeleton style={{ width: "50px", height: "47px", borderTopRightRadius: "8px", borderBottomRightRadius: '8px', flexGrow: 0, flexShrink: 0, flexBasis: 'auto' }}></Skeleton>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center  align-items-center mt-2  me-2'>
                            <div className='d-flex justify-content-center align-items-center  text-white' >
                                <Skeleton style={{ width: "120px", height: "47px", borderTopLeftRadius: "8px", borderBottomLeftRadius: '8px' }}></Skeleton>
                            </div>
                            <div className='d-flex justify-content-center align-items-center  ' >
                                <Skeleton style={{ width: "50px", height: "47px", borderTopRightRadius: "8px", borderBottomRightRadius: '8px', flexGrow: 0, flexShrink: 0, flexBasis: 'auto' }}></Skeleton>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center  align-items-center mt-2  me-2'>
                            <div className='d-flex justify-content-center align-items-center  text-white' >
                                <Skeleton style={{ width: "170px", height: "47px", borderTopLeftRadius: "8px", borderBottomLeftRadius: '8px' }}></Skeleton>
                            </div>
                            <div className='d-flex justify-content-center align-items-center  ' >
                                <Skeleton style={{ width: "50px", height: "47px", borderTopRightRadius: "8px", borderBottomRightRadius: '8px', flexGrow: 0, flexShrink: 0, flexBasis: 'auto' }}></Skeleton>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center  align-items-center mt-2  me-2'>
                            <div className='d-flex justify-content-center align-items-center  text-white' >
                                <Skeleton style={{ width: "120px", height: "47px", borderTopLeftRadius: "8px", borderBottomLeftRadius: '8px' }}></Skeleton>
                            </div>
                            <div className='d-flex justify-content-center align-items-center  ' >
                                <Skeleton style={{ width: "50px", height: "47px", borderTopRightRadius: "8px", borderBottomRightRadius: '8px', flexGrow: 0, flexShrink: 0, flexBasis: 'auto' }}></Skeleton>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center  align-items-center mt-2  me-2'>
                            <div className='d-flex justify-content-center align-items-center  text-white' >
                                <Skeleton style={{ width: "120px", height: "47px", borderTopLeftRadius: "8px", borderBottomLeftRadius: '8px' }}></Skeleton>
                            </div>
                            <div className='d-flex justify-content-center align-items-center  ' >
                                <Skeleton style={{ width: "50px", height: "47px", borderTopRightRadius: "8px", borderBottomRightRadius: '8px', flexGrow: 0, flexShrink: 0, flexBasis: 'auto' }}></Skeleton>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center  align-items-center mt-2  me-2'>
                            <div className='d-flex justify-content-center align-items-center  text-white' >
                                <Skeleton style={{ width: "120px", height: "47px", borderTopLeftRadius: "8px", borderBottomLeftRadius: '8px' }}></Skeleton>
                            </div>
                            <div className='d-flex justify-content-center align-items-center  ' >
                                <Skeleton style={{ width: "80px", height: "47px", borderTopRightRadius: "8px", borderBottomRightRadius: '8px', }}></Skeleton>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center  align-items-center mt-2  me-2'>
                            <div className='d-flex justify-content-center align-items-center  text-white' >
                                <Skeleton style={{ width: "100px", height: "47px", borderTopLeftRadius: "8px", borderBottomLeftRadius: '8px' }}></Skeleton>
                            </div>
                            <div className='d-flex justify-content-center align-items-center  ' >
                                <Skeleton style={{ width: "50px", height: "47px", borderTopRightRadius: "8px", borderBottomRightRadius: '8px' }}></Skeleton>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        <Container fluid className='p-5 mb-4 bg-white'>
            <Row>
                <h4 className='text-center'><Skeleton style={{ width: '200px' }}></Skeleton></h4>
            </Row>
            <Row className='d-flex  mt-4'>
                <Col>
                    <div className='d-flex  justify-content-around flex-wrap '>
                        <div className='d-flex justify-content-center  align-items-center '>
                            <div className='d-flex justify-content-center  align-items-center text-white '>
                                <Skeleton style={{ width: "100px", height: "47px", borderTopLeftRadius: "8px", borderBottomLeftRadius: '8px' }}></Skeleton>
                            </div>
                            <div className='d-flex justify-content-center align-items-center '>
                                <Skeleton style={{ width: "250px", height: "47px", borderTopRightRadius: "8px", borderBottomRightRadius: '8px', flexGrow: 0, flexShrink: 0, flexBasis: 'auto' }}></Skeleton>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center  align-items-center mt-2'>

                            <div className='d-flex justify-content-center align-items-center text-white' >
                                <Skeleton style={{ width: "150px", height: "47px", borderTopLeftRadius: "8px", borderBottomLeftRadius: '8px' }}></Skeleton>
                            </div>
                            <div className='d-flex justify-content-center align-items-center  '>
                                <Skeleton style={{ width: "180px", height: "47px", borderTopRightRadius: "8px", borderBottomRightRadius: '8px', flexGrow: 0, flexShrink: 0, flexBasis: 'auto' }}></Skeleton>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center  align-items-center mt-2'>
                            <div className='d-flex justify-content-center align-items-center  text-white' >
                                <Skeleton style={{ width: "130px", height: "47px", borderTopLeftRadius: "8px", borderBottomLeftRadius: '8px' }}></Skeleton>
                            </div>
                            <div className='d-flex justify-content-center align-items-center  ' style={{ width: "200px", height: "47px", backgroundColor: '#E3E3E3', borderTopRightRadius: "8px", borderBottomRightRadius: '8px', flexGrow: 0, flexShrink: 0, flexBasis: 'auto' }}>
                                <Skeleton></Skeleton>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center  align-items-center mt-2'>
                            <div className='d-flex justify-content-center align-items-center  text-white'>
                                <Skeleton style={{ width: "160px", height: "47px", borderTopLeftRadius: "8px", borderBottomLeftRadius: '8px' }}></Skeleton>
                            </div>
                            <div className='d-flex justify-content-center align-items-center  '  >
                                <Skeleton style={{ width: "160px", height: "47px", borderTopLeftRadius: "8px", borderBottomLeftRadius: '8px' }}></Skeleton>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        <Container fluid className='p-5 mb-4' style={{ backgroundColor: '#F4F4F4' }}>
            <Row>
                <h4 className='text-center'><Skeleton style={{ width: '200px' }}></Skeleton></h4>
            </Row>
            <Row className='d-flex  mt-4'>
                <Col>
                    <div className='d-flex  justify-content-around flex-wrap'>
                        <div className='d-flex justify-content-center  align-items-center '>
                            <div className='d-flex justify-content-center  align-items-center text-white ' >
                                <Skeleton style={{ width: "100px", height: "47px", borderTopLeftRadius: "8px", borderBottomLeftRadius: '8px' }}></Skeleton>
                            </div>
                            <div className='d-flex justify-content-center align-items-center ' >
                                <Skeleton style={{ width: "180px", height: "47px", borderTopRightRadius: "8px", borderBottomRightRadius: '8px', flexGrow: 0, flexShrink: 0, flexBasis: 'auto' }}></Skeleton>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center  align-items-center mt-2'>
                            <div className='d-flex justify-content-center align-items-center text-white' >
                                <Skeleton style={{ width: "200px", height: "47px", borderTopLeftRadius: "8px", borderBottomLeftRadius: '8px' }}></Skeleton>
                            </div>
                            <div className='d-flex justify-content-center align-items-center  ' >
                                <Skeleton style={{ width: "160px", height: "47px", borderTopRightRadius: "8px", borderBottomRightRadius: '8px', flexGrow: 0, flexShrink: 0, flexBasis: 'auto' }}></Skeleton>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center  align-items-center mt-2'>

                            <div className='d-flex justify-content-center align-items-center  text-white' >
                                <Skeleton style={{ width: "150px", height: "47px", borderTopLeftRadius: "8px", borderBottomLeftRadius: '8px' }}></Skeleton>
                            </div>
                            <div className='d-flex justify-content-center align-items-center  ' >
                                <Skeleton style={{ width: "190px", height: "47px", borderTopRightRadius: "8px", borderBottomRightRadius: '8px', flexGrow: 0, flexShrink: 0, flexBasis: 'auto' }}></Skeleton>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container> */}

    </>)
}
export default ProductDetailSkeleton;