import React, { useState } from "react";
import { Col, Container, Row, FormControl } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "../Contact/Contact.css";
import Image1 from "../../assets/images/bg_about.png";
import Image2 from "../../assets/images/aboutus1.png";
import Image3 from "../../assets/images/aboutimg2.png";
import Image4 from "../../assets/images/aboutimg3.png";
import Image5 from "../../assets/images/aboutus2.png";
import Image6 from "../../assets/images/footimg.png";
import { useNavigate } from "react-router-dom";
import { BsFacebook, BsYoutube, BsInstagram } from "react-icons/bs";
import { AiFillTwitterCircle, AiOutlineInstagram } from "react-icons/ai";
import { FaLinkedinIn, FaQuora } from "react-icons/fa";
const About = () => {
  let navigate = useNavigate();
  const [search, setSearch] = useState("");
  const handleSubmit = () => {
    if (search.trim && search !== "") {
      navigate(`/products/${search}`);
    }
  };
  return (
    <>
      <div className="pt-5 contact-bg" style={{ paddingBottom: "75px" }}></div>
      <Container fluid className="p-0">
        <div>
          <div className="bg_img">
            <img src={Image1} className="img-fluid"></img>
            <span className="centered">
              Welcome To Poorna Satya
              <br />
              <span className="centered_p">Where Truth Meets Nutrition</span>
            </span>
          </div>
          {/* <div className="row text-white bg_img_about">
            <span className="d-flex justify-content-center align-items-center">
              <span className="centered_new">Welcome To Poorna Satya</span><br/>
              <span className="centered_p">Where Truth Meets Nutrition</span>
            </span>
          </div> */}
          <Container>
            <Row className="d-flex justify-content-center align-items-center mt-4">
              <Col
                lg={12}
                md={12}
                sm={12}
                className="d-flex justify-content-center mb-4"
              >
                <div className="row p-3 rounded-3 bg_aboutcard">
                  <div className="col-lg-5 col-md-12 d-flex justify-content-center">
                    <img src={Image2} className="img-fluid"></img>
                  </div>
                  <div className="col-lg-6 col-md-12 d-flex justify-content-start">
                    <div className="mt-5 mb-5 text-black">
                      <h3 style={{ color: "#003366" }}>
                        Discover the Complete Truth About Your Food
                      </h3>
                      <span className="lh-lg">
                        At Poorna Satya, we put the power of knowledge in your
                        hands from the moment you arrive. We believe that making
                        informed choices about what you eat is the key to a
                        healthier and more mindful lifestyle.
                      </span>
                      <h3 className="mt-3" style={{ color: "#003366" }}>
                        Start Your Food Journey Now
                      </h3>
                      <span className="lh-lg">
                        Search for your favourite food: Our user-friendly search
                        bar is your gateway to uncovering the truth about what's
                        on your plate. Simply type in a product, and let the
                        exploration begin.
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                lg={12}
                md={12}
                sm={12}
                style={{ backgroundColor: "#F8F4EF" }}
                className="d-flex justify-content-start mb-4 rounded p-4"
              >
                <div>
                  <h3 style={{ color: "#003366" }}>Why Poorna Satya?</h3>
                  <ul className="lh-lg" style={{ color: "black" }}>
                    <li>
                      <span className="fw-bold">Transparency:</span> We're
                      committed to providing you with unfiltered, comprehensive
                      information about food products.
                    </li>
                    <li>
                      <span className="fw-bold">Empowerment:</span> Take control
                      of your health by understanding the nutritional content
                      and ingredients of your favourite products.
                    </li>
                    <li>
                      <span className="fw-bold">Community:</span> Join our
                      community of like-minded individuals who value
                      transparency and well-being.
                    </li>
                    <li>
                      <span className="fw-bold">Rewards:</span> Contribute to
                      our database and earn exciting rewards for helping others
                      make informed choices.
                    </li>
                  </ul>
                </div>
              </Col>
              <Col
                lg={12}
                md={12}
                sm={12}
                className="d-flex justify-content-center mb-3 rounded-3 p-4"
                style={{ backgroundColor: "#F1F8EF" }}
              >
                <div>
                  <h3 style={{ color: "#003366" }}>Explore Your Lifestyle</h3>
                  <span className="lh-lg" style={{ color: "black" }}>
                    Whether you follow a Halal, Satvik, Vegan, or any other
                    lifestyle, Poorna Satya is your trusted companion. We're
                    here to ensure that you have the information you need to
                    make food choices that align with your values and
                    preferences.
                  </span>
                </div>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center align-items-center">
              <Col
                lg={3}
                md={6}
                sm={12}
                className="d-flex justify-content-center"
              >
                <div>
                  <span className="aboutcards_img p-4">
                    <h4 style={{ color: "#034053", fontSize: "22px" }}>
                      Nutritional Content
                    </h4>
                    <p style={{ color: "#000", fontSize: "16px" }}>
                      Search your food choice and <br />
                      get the nutritional content in <br />
                      just one click.
                    </p>
                  </span>
                  <img
                    src={require("../../assets/images/aboutcard12.png")}
                    className="img-fluid"
                    alt="product1"
                  ></img>
                </div>
              </Col>
              <Col
                lg={3}
                md={6}
                sm={12}
                className="d-flex justify-content-center"
              >
                <div>
                  <span className="aboutcards_img p-4">
                    <h4 style={{ color: "#034053", fontSize: "22px" }}>
                      Compare options
                    </h4>
                    <p style={{ color: "#000", fontSize: "16px" }}>
                      Compare your food choices <br />
                      across brands and variants.
                      <br />
                      Eat healthy.{" "}
                    </p>
                  </span>
                  <img
                    src={require("../../assets/images/aboutcard22.png")}
                    className="img-fluid"
                    alt="product2"
                  ></img>
                </div>
              </Col>
              <Col
                lg={3}
                md={6}
                sm={12}
                className="d-flex justify-content-center"
              >
                <div>
                  <span className="aboutcards_img p-4">
                    <h4 style={{ color: "#034053", fontSize: "22px" }}>
                      Food Certification
                    </h4>
                    <p style={{ color: "#000", fontSize: "16px" }}>
                      Check the food certification and <br />
                      accredition details of what your <br />
                      are eating.
                    </p>
                  </span>
                  <img
                    src={require("../../assets/images/aboutcard32.png")}
                    className="img-fluid"
                    alt="product3"
                  ></img>
                </div>
              </Col>
              <Col
                lg={3}
                md={6}
                sm={12}
                className="d-flex justify-content-center"
              >
                <div className="con_rel">
                  <span className="aboutcards_img p-4">
                    <h4 style={{ color: "#034053", fontSize: "22px" }}>
                      Recommendations
                    </h4>
                    <p style={{ color: "#000", fontSize: "16px" }}>
                      Based on data and research, we <br />
                      recommend the food as per your <br />
                      prefer eats.
                    </p>
                  </span>
                  <div className="middle_text">
                    <div className="tex_div">Coming Soon</div>
                  </div>
                  <img
                    src={require("../../assets/images/aboutcard42.png")}
                    className="img-fluid img_shad"
                    alt="product4"
                  ></img>
                </div>
                {/* <img
                  src={require("../../assets/images/aboutcard4.png")}
                  className="img-fluid"
                  alt="product4"
                ></img> */}
              </Col>
            </Row>
            <Row
              style={{ backgroundColor: "#F8F4EF" }}
              className="d-flex justify-content-center align-items-center mt-3 p-3 rounded-3"
            >
              <Col
                lg={4}
                md={12}
                sm={12}
                className="d-flex justify-content-center mb-2"
              >
                <div>
                  <img src={Image5} className="img-fluid"></img>
                </div>
              </Col>
              <Col
                lg={8}
                md={12}
                sm={12}
                className="d-flex justify-content-start mt-3"
              >
                <div className="mt-5 mb-5 text-black">
                  <h3 style={{ color: "#034053", paddingBottom: "12px" }}>
                    How It Works
                  </h3>
                  <span className="lh-lg">
                    <span className="fw-semibold">Search :</span> Use the search
                    bar to explore detailed information about your favourite
                    products
                  </span>
                  <br />
                  <span className="lh-lg">
                    <span className="fw-semibold">Discover :</span> Dive into
                    comprehensive details, including ingredients and nutritional
                    value
                  </span>
                  <br />
                  <span className="lh-lg">
                    <span className="fw-semibold">Contribute :</span> Join our
                    community and contribute to our database to earn rewards
                  </span>
                </div>
              </Col>
            </Row>
            <Row
              style={{ backgroundColor: "#003366" }}
              className="box_card_GST mt-4 text-white p-5 rounded-3"
            >
              <Col
                lg={12}
                md={12}
                sm={12}
                style={{}}
                className="d-flex flex-column justify-content-center align-items-center mb-3"
              >
                <h3>Get Started Today</h3>
                <p>
                  Type in a brand or the product name, hit search, and embark on
                  a journey to a better you.
                </p>
                <Row className="d-flex justify-content-center align-items-center w-50">
                  <Col className="mt-2" lg={9} md={7}>
                    <FormControl
                      style={{ boxShadow: "none" }}
                      className="search-inputs"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      type="text"
                      placeholder="Search for Products or Barnd"
                    />
                  </Col>
                  <Col sm className="text-center mt-2 " lg={3} md={5}>
                    <button
                      type="submit"
                      onClick={() => {
                        handleSubmit();
                      }}
                      className="search-button btn-lg small"
                    >
                      Search
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="box_card d-flex justify-content-center align-items-center mt-4 rounded-3 p-3">
              <Col className="text-center">
                <div
                  style={{ color: "#034053" }}
                  className="h3 fw-bold mt-3 mb-3"
                >
                  Join the Food Revolution
                </div>
                <span style={{ color: "#616161" }} className="text-start">
                  Be part of the movement towards healthier living and informed
                  choices. Ready to know what you're consuming like never
                  before? Start your journey now!
                </span>
                {/* <p style={{ color: "#616161" }} className="mt-2">
                                    {" "}
                                    Start your journey by searching for the truth about your food.
                                </p> */}
                <div
                  style={{ color: "#034053" }}
                  className="h5 fw-bold mt-3 mb-3"
                >
                  <span className="align-middle">Follow Us</span>
                  <span className="ms-3">
                    <a
                      href="https://www.instagram.com/ps_thecompletetruth/"
                      style={{
                        textDecoration: "none",
                        color: "#003366",
                        fontSize: "20px",
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <BsInstagram className="fs-5 me-2" />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/poorna-satya"
                      style={{
                        textDecoration: "none",
                        color: "#003366",
                        fontSize: "20px",
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaLinkedinIn className="fs-5 me-2" />
                    </a>
                    <a
                      href="https://www.quora.com/profile/Poorna-Satya-1"
                      style={{
                        textDecoration: "none",
                        color: "#003366",
                        fontSize: "20px",
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaQuora className="fs-5 me-2" />
                    </a>
                    <a
                      href="https://www.youtube.com/@PoornaSatyaTheCompleteTruth/featured"
                      style={{
                        textDecoration: "none",
                        color: "#003366",
                        fontSize: "20px",
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <BsYoutube className="fs-5 me-2" />
                    </a>
                    <a
                      href="https://www.facebook.com/ps.thecompletetruth/"
                      style={{
                        textDecoration: "none",
                        color: "#003366",
                        fontSize: "20px",
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <BsFacebook className="fs-5 me-2" />
                    </a>

                    <a
                      href="https://twitter.com/poorna__satya"
                      style={{
                        textDecoration: "none",
                        color: "#003366",
                        fontSize: "20px",
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <AiFillTwitterCircle className="fs-5 me-2" />
                    </a>
                  </span>
                </div>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center align-items-center mt-3 mb-3">
              <span
                className="h3 text-center mb-5"
                style={{ color: "#003366" }}
              >
                True Lineage India Team
              </span>
              <Col
                lg={12}
                md={12}
                sm={12}
                className="d-flex justify-content-center mt-3 mb-3"
              >
                <div
                  className="card border-0 rounded-3 shadow"
                  style={{ maxWidth: "26rem" }}
                >
                  <div className="card-body p-3 img_position_r">
                    <span className="img_position">
                      <img
                        src={require("../../assets/images/foundernew.png")}
                        className="img-fluid"
                        alt="product2"
                      ></img>
                    </span>
                    <span className="text-center mt-5">
                      <h5 style={{ color: "#003366" }}>Kushagr Madan</h5>
                      <h5 style={{ color: "#5CB85C" }}>Founder</h5>
                      <p className="d-flex justify-content-center align-items-center small">
                        Kushagr Madan, a student with DPS R.K. Puram, the
                        visionary mind behind our portal, ignited the spark of
                        this innovation and the Poorna Satya brand. His
                        groundbreaking idea set the foundation for our journey
                        towards a positive change in our society.
                      </p>
                    </span>
                  </div>
                </div>
              </Col>
              {/* <Col
                lg={3}
                md={6}
                sm={12}
                className="d-flex justify-content-center mt-5 mb-3"
              >
                <div
                  className="card border-0 rounded-3 shadow m-1"
                  style={{ maxWidth: "19rem", height: "18rem" }}
                >
                  <div className="card-body p-3 img_position_r">
                    <span className="img_position">
                      <img
                        src={require("../../assets/images/ceonew.png")}
                        className="img-fluid"
                        alt="product2"
                      ></img>
                    </span>
                    <span className="d-flex flex-column justify-content-center align-items-center mt-0">
                      <h5 style={{ color: "#003366" }}>Chahat Aggarwal</h5>
                      <h5 style={{ color: "#5CB85C" }}>CEO</h5>
                      <p className="lh-base small text-center">
                        Chahat Aggarwal, our visionary CEO, has spearheaded
                        multi-million dollar tech startups. With a blend of
                        strong opinions and diplomatic finesse, she's leading us
                        toward a brighter future.
                      </p>
                    </span>
                  </div>
                </div>
              </Col> */}
              <Col
                lg={3}
                md={6}
                sm={12}
                className="d-flex justify-content-center mt-5 mb-3"
              >
                <div
                  className="card border-0 rounded-3 shadow m-1"
                  style={{ maxWidth: "19rem", height: "18rem" }}
                >
                  <div className="card-body p-3 img_position_r">
                    <span className="img_position">
                      <img
                        src={require("../../assets/images/programOwnernew.png")}
                        className="img-fluid"
                        alt="product3"
                      ></img>
                    </span>
                    <span className="d-flex flex-column justify-content-center align-items-center mt-0">
                      <h5 style={{ color: "#003366" }}>Dr. Pratichi Mishra</h5>
                      <h5 style={{ color: "#5CB85C" }}>Program Owner</h5>
                      <p className="lh-base small text-center">
                        Dr. Pratichi Mishra, our healthcare expert, is committed
                        to pioneering innovative solutions and awareness for the
                        people of India.
                      </p>
                    </span>
                  </div>
                </div>
              </Col>
              <Col
                lg={3}
                md={6}
                sm={12}
                className="d-flex justify-content-center mt-5 mb-3"
              >
                <div
                  className="card border-0 rounded-3 shadow m-1"
                  style={{ maxWidth: "19rem", height: "18rem" }}
                >
                  <div className="card-body p-3 img_position_r">
                    <span className="img_position">
                      <img
                        src={require("../../assets/images/promoternew1.png")}
                        className="img-fluid"
                        alt="product3"
                      ></img>
                    </span>
                    <span className="d-flex flex-column justify-content-center align-items-center mt-0">
                      <h5 style={{ color: "#003366" }}>Dev Datt Sharma</h5>
                      <h5 style={{ color: "#5CB85C" }}>Promoter</h5>
                      <p className="lh-base small text-center">
                        Accomplished Pharmaceutical Industry Promoter with
                        interests in Indian and Overseas markets. Seed stage
                        investor with healthcare and fintech startups.
                      </p>
                    </span>
                  </div>
                </div>
              </Col>
              <Col
                lg={3}
                md={6}
                sm={12}
                className="d-flex justify-content-center mt-5 mb-3"
              >
                <div
                  className="card border-0 rounded-3 shadow m-1"
                  style={{ maxWidth: "19rem", height: "18rem" }}
                >
                  <div className="card-body p-3 img_position_r">
                    <span className="img_position">
                      <img
                        src={require("../../assets/images/promoternew2a.png")}
                        className="img-fluid"
                        alt="product3"
                      ></img>
                    </span>
                    <span className="d-flex flex-column justify-content-center align-items-center mt-0">
                      <h5 style={{ color: "#003366" }}>Nidhi V Chawla</h5>
                      <h5 style={{ color: "#5CB85C" }}>Promoter</h5>
                      <p className="lh-base p-0 small text-center">
                        An MBA and Masters in Psychology, she has extensive
                        experience in modalities, including mental health
                        dynamics and understanding of the human psyche. Investor
                        in mental wellness based healthcare sector.
                      </p>
                    </span>
                  </div>
                </div>
              </Col>
              {/* <Col
                lg={3}
                md={6}
                sm={12}
                className="d-flex justify-content-center mt-5 mb-3"
              >
                <div
                  className="card border-0 rounded-3 shadow m-1"
                  style={{ maxWidth: "19rem", height: "18rem" }}
                >
                  <div className="card-body p-3 img_position_r">
                    <span className="img_position">
                      <img
                        src={require("../../assets/images/programOwnernew.png")}
                        className="img-fluid"
                        alt="product3"
                      ></img>
                    </span>
                    <span className="d-flex flex-column justify-content-center align-items-center mt-0">
                      <h5 style={{ color: "#003366" }}>Dr. Pratichi Mishra</h5>
                      <h5 style={{ color: "#5CB85C" }}>Program Owner</h5>
                      <p className="lh-base small text-center">
                        Dr. Pratichi Mishra, our healthcare expert, is committed
                        to pioneering innovative solutions and awareness for the
                        people of India.
                      </p>
                    </span>
                  </div>
                </div>
              </Col> */}
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
};

export default About;
