import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Image1 from "../../assets/images/nutritionFacts.png";

const BlogInfo = () => {
  const { value } = useParams();
  return (
    <>
      <div className="pt-5" style={{ paddingBottom: "75px" }}></div>
      <Container className="mt-5 p-0">
        <Row>
          <Col
            lg={12}
            md={12}
            sm={12}
            className="d-flex justify-content-center mb-4 card border-0 shadow rounded-4"
          >
            {value == 1 ? (
              <>
                <div className="mt-5 mb-5 text-black m-5">
                  <h2 className="fw-samibold">
                    Halal vs. Satvik Food Choices: A Tasty Dive into Tradition
                  </h2>
                  <h6 className="mb-3" style={{ color: "#34b4f6" }}>
                    Leave a Comment / By poorna_satya / October 4, 2023
                  </h6>
                  <span className="lh-lg">
                    In the global smorgasbord of culinary delights, two dietary
                    paths, Halal and Satvik, stand out like flavorful bookends.
                    They're not just about what you eat; they're about
                    tradition, culture, and even a bit of soul food. Join us on
                    a delicious journey as we explore the unique characteristics
                    of Halal and Satvik food choices and how they satisfy not
                    only your taste buds but also your inner zen.
                  </span>
                  <h6 className="mb-3 mt-3 fw-bold">
                    Halal : Where Tradition Meets Transparency
                  </h6>
                  <h6 className="mb-3 mt-3">
                    The Basics : Halal, a word that translates to "permissible"
                    in Arabic, is a culinary lifestyle rooted in Islamic
                    tradition. So, what makes a dish Halal? Well, it's all about
                    keeping it clean:
                  </h6>
                  <ol>
                    <li className="lh-lg">
                      The Slaughter Rule : In Halal cuisine, animals must be
                      slaughtered following Islamic guidelines. It's all done in
                      the name of Allah by a sane and mature Muslim, ensuring a
                      humane process.
                    </li>
                    <li className="lh-lg">
                      Forbidden Ingredients : Pork and its by-products, alcohol,
                      and any form of blood are off the Halal menu.
                      Cross-contamination with non-Halal foods is also a big
                      no-no.
                    </li>
                  </ol>
                  <h6 className="mb-3 mt-3 fw-bold">
                    Satvik : Food for the Mind, Body, and Spirit
                  </h6>
                  <h6 className="mb-3 mt-3">
                    The Basics : Satvik, a term rooted in Sanskrit, embodies
                    purity and clarity. It's a lifestyle that's part of Ayurveda
                    and Hindu philosophy, all about inner peace through what you
                    eat. Key ingredients? Simplicity and serenity:
                  </h6>
                  <ol>
                    <li className="lh-lg">
                      Freshness is the Key: Satvik dishes are best when they're
                      made from fresh, unprocessed ingredients. You won't find
                      many cans and packages here.
                    </li>
                    <li className="lh-lg">
                      Pure and Simple: The focus is on pure foods like fruits,
                      veggies, grains, nuts, and dairy. Think of it as a
                      harmonious food symphony.
                    </li>
                    <li className="lh-lg">
                      Balanced Vibes: Satvik cuisine leans towards minimizing
                      strong flavors and spices. It's all about maintaining
                      balanced energies within.
                    </li>
                  </ol>
                  <span className="lh-lg">
                    Health and Harmony: Satvik food isn't just about feeding the
                    body; it's about nourishing the mind and spirit. It's a
                    culinary philosophy that seeks inner tranquility and a
                    peaceful existence. Food isn't just fuel; it's a path to
                    serenity.
                  </span>
                  <h6 className="mb-3 mt-3 fw-bold">
                    Comparing Halal and Satvik:
                  </h6>
                  <h6 className="mb-3 mt-3">
                    These two culinary worlds might seem different, but they
                    share some intriguing similarities:
                  </h6>
                  <ol>
                    <li className="lh-lg">
                      Purity Rules: Both Halal and Satvik cuisines are obsessed
                      with purity. Halal emphasizes the cleanliness of the food
                      itself, while Satvik focuses on purity of mind and spirit
                      through pure food.
                    </li>
                    <li className="lh-lg">
                      Mindful Munching: They both encourage mindfulness when
                      choosing what to eat. Whether it's the source of your meat
                      or the freshness of your produce, both paths are all about
                      staying woke when it comes to food.
                    </li>
                  </ol>
                  <h6 className="lh-lg">
                    Health Boost: Halal promotes humane treatment of animals and
                    avoiding certain ingredients, while Satvik champions a diet
                    rich in fruits and veggies. Both can contribute to a
                    healthier lifestyle.
                  </h6>
                  <h6 className="lh-lg">
                    In the end, whether you choose Halal or Satvik food, it's
                    like selecting a favorite book from your culinary library.
                    Each has its own unique flavor, cultural significance, and
                    health benefits. What's most important is that your food
                    choices resonate with your values and bring joy to your
                    taste buds and soul.
                  </h6>
                  <h6 className="lh-lg">
                    No matter which path you follow, Poornasatya is here to be
                    your culinary compass, helping you make informed choices
                    that align with your lifestyle and values.
                  </h6>
                </div>
              </>
            ) : value == 2 ? (
              <>
                <div className="mt-5 mb-5 text-black m-5">
                  <h2 className="fw-samibold">
                    Poorna Satya: Your Gateway to Healthier Choices, Delivered
                    to Your Doorstep
                  </h2>
                  <h6 className="mb-3" style={{ color: "#34b4f6" }}>
                    Leave a Comment / By poorna_satya / October 4, 2023
                  </h6>
                  <span className="lh-lg">
                    In today’s fast-paced world, making healthy food choices can
                    often feel like a challenge. We’re bombarded with an array
                    of products, each claiming to be a healthier option. But how
                    can you be sure? This is where Poorna Satya steps in,
                    revolutionizing the way we approach nutrition and wellness.
                    Poorna Satya is not just a product; it’s a game-changer that
                    promises to enhance your daily life while making a profound
                    impact on personal and global health.
                  </span>
                  <h6 className="mb-3 mt-3 fw-bold">Unveiling Poorna Satya:</h6>
                  <span className="lh-lg">
                    Imagine having a vast database of thousands of market-ready
                    food products right at your fingertips, complete with all
                    the associated nutritional data and dietary restrictions in
                    one place. With Poorna Satya, this becomes your reality.
                    Poorna Satya is a comprehensive platform that simplifies the
                    process of choosing healthier foods and brings them to your
                    doorstep within minutes.
                  </span>
                  <h6 className="mb-3 mt-3 fw-bold">
                    Benefits for Daily Life:
                  </h6>
                  <ol>
                    <li className="lh-lg">
                      <span className="fw-bold">
                        {" "}
                        Effortless Healthier Choices :{" "}
                      </span>
                      Poorna Satya takes the guesswork out of your daily food
                      decisions. Whether you’re managing specific dietary
                      restrictions, aiming to lose weight, or simply want to
                      make better choices, the platform guides you toward
                      healthier options effortlessly based on your unique health
                      profile.
                    </li>
                    <li className="lh-lg">
                      <span className="fw-bold">
                        {" "}
                        Personalised Nutrition :{" "}
                      </span>
                      Poorna Satya doesn’t believe in a one-size-fits-all
                      approach. It understands that each individual has unique
                      dietary needs and preferences. It offers personalised
                      recommendations based on your health profile and
                      lifestyle, ensuring that every choice aligns with your
                      well-being.
                    </li>
                    <li className="lh-lg">
                      <span className="fw-bold"> Convenience Redefined : </span>
                      Say goodbye to hours spent scrutinising food labels at the
                      supermarket. With Poorna Satya, you can make informed
                      choices from the comfort of your home, and your selected
                      products are delivered straight to your doorstep within
                      minutes.
                    </li>
                  </ol>
                  <h6 className="mb-3 mt-3 fw-bold">
                    Real-World Implications on Personal Health:
                  </h6>
                  <h6 className="mb-3 mt-3">
                    Poorna Satya isn’t just a convenient service; it’s a
                    catalyst for positive change in personal health:
                  </h6>
                  <ol>
                    <li className="lh-lg">
                      <span className="fw-bold"> Improved Nutrition : </span>
                      By simplifying the process of choosing healthier foods,
                      Poorna Satya helps individuals adopt more balanced diets,
                      reducing the risk of lifestyle-related diseases such as
                      obesity, diabetes, and heart conditions.
                    </li>
                    <li className="lh-lg">
                      <span className="fw-bold"> Dietary Adherence : </span>
                      For those with dietary restrictions or specific health
                      goals, Poorna Satya ensures they stay on track. It becomes
                      a reliable partner in achieving and maintaining dietary
                      compliance.
                    </li>
                    <li className="lh-lg">
                      <span className="fw-bold">
                        {" "}
                        Time and Energy Savings :{" "}
                      </span>
                      Poorna Satya liberates individuals from the time-consuming
                      task of researching and shopping for healthier foods. This
                      frees up time for other aspects of life and reduces
                      stress.
                    </li>
                  </ol>
                  <h6 className="mb-3 mt-3 fw-bold">
                    Real-World Implications on Global Health:
                  </h6>
                  <h6 className="mb-3 mt-3">
                    Beyond personal health, Poorna Satya contributes to a
                    healthier world:
                  </h6>
                  <ol>
                    <li className="lh-lg">
                      <span className="fw-bold"> Sustainable Choices : </span>
                      By promoting healthier and more sustainable food choices,
                      Poorna Satya supports the shift towards a more sustainable
                      food system, reducing the environmental impact of our
                      diets.
                    </li>
                    <li className="lh-lg">
                      <span className="fw-bold">
                        {" "}
                        Reduced Healthcare Costs :{" "}
                      </span>
                      As individuals adopt healthier eating habits, the burden
                      on healthcare systems is alleviated, leading to reduced
                      healthcare costs and improved overall well-being on a
                      societal level.
                    </li>
                  </ol>
                  <span className="lh-lg">
                    Poorna Satya is more than just a product; it’s a gateway to
                    a healthier and more convenient way of living. With its
                    ability to simplify food choices, personalise nutrition, and
                    revolutionise the way we access healthier options, Poorna
                    Satya is poised to make a substantial impact on both
                    personal and global health. In a world where healthier
                    living is paramount, Poorna Satya emerges as a beacon of
                    change, empowering individuals to lead happier, healthier
                    lives, one choice at a time.
                  </span>
                </div>
              </>
            ) : value == 3 ? (
              <>
                <div className="mt-5 mb-5 text-black m-5">
                  <h2 className="fw-samibold">
                    Smart Shopping: Decoding Healthy Packaged Products on
                    Grocery Shelves
                  </h2>
                  <h6 className="mb-3" style={{ color: "#34b4f6" }}>
                    Leave a Comment / By poorna_satya / October 4, 2023
                  </h6>
                  <span className="lh-lg">
                    Every supermarket aisle is a labyrinth of choices. From
                    snacks to condiments, the array of packaged products is
                    overwhelming. However, when it comes to making healthier
                    choices, the real challenge lies in deciphering which
                    packaged products are truly the best for your well-being. In
                    this blog, we’ll delve into the difficulties of discerning
                    the healthiest options in your market.
                  </span>
                  <h6 className="mb-3 mt-3 fw-bold">The Paradox of Choice:</h6>
                  <span className="lh-lg">
                    Standing before the shelves stacked with colourful
                    packaging, you might ask yourself, “How do I choose the
                    healthiest option?” The paradox of choice often leaves
                    consumers confused and unsure about what’s truly best for
                    their health.
                  </span>
                  <h6 className="mb-3 mt-3 fw-bold">
                    Food Labels: A Double-Edged Sword:
                  </h6>
                  <h6 className="mb-3 mt-3">
                    Food labels, while informative, can also be cryptic. They’re
                    laden with nutritional jargon that may leave you scratching
                    your head. Consider these common challenges:
                  </h6>
                  <ol>
                    <li className="lh-lg">
                      <span className="fw-bold"> Misleading Marketing : </span>
                      Packages often sport phrases like “low fat,” “natural,” or
                      “sugar-free” to lure health-conscious buyers. But are
                      these claims always truthful?
                    </li>
                    <li className="lh-lg">
                      <span className="fw-bold"> Serving Sizes : </span>
                      The nutrition information is based on serving sizes, which
                      may not match your actual consumption, leading to
                      overestimation or underestimation of nutrients.
                    </li>
                    <li className="lh-lg">
                      <span className="fw-bold"> Hidden Ingredients : </span>
                      It’s not always easy to decipher ingredient lists. Some
                      harmful additives and preservatives go by different names
                      that may not be immediately recognizable.
                    </li>
                  </ol>
                  <h6 className="mb-3 mt-3">
                    Decoding food labels and a pinch of critical thinking can
                    help you avoid these traps. Let’s look at some common
                    scenarios where this knowledge can make a significant
                    difference:
                  </h6>
                  <ol>
                    <li className="lh-lg">
                      <span className="fw-bold"> Snack Aisle : </span>
                      You spot two packages of chips. One claims to be “baked”
                      while the other is “lightly salted.” Food education would
                      help you understand that “baked” doesn’t necessarily mean
                      healthy, and “lightly salted” may still contain excessive
                      sodium. By reading the ingredient list, you can identify
                      potential red flags such as trans fats or artificial
                      flavour enhancers. You can also check the sodium content
                      to see if the “lightly salted” claim holds true.
                    </li>
                    <li className="lh-lg">
                      <span className="fw-bold"> Breakfast Cereals : </span>
                      Choosing a breakfast cereal is a common dilemma.
                      Understanding how to read the sugar content and ingredient
                      list can guide you toward a healthier option.Start by
                      checking the sugar content on the nutrition label. Learn
                      to differentiate between naturally occurring sugars (e.g.,
                      from fruits) and added sugars (e.g., high-fructose corn
                      syrup). Opt for cereals with lower added sugar content.
                      Don’t hesitate to compare different cereal brands, and
                      choose options that contain whole grains, bran, or oats
                      for a more nutritious breakfast.
                    </li>
                    <li className="lh-lg">
                      <span className="fw-bold"> Condiments : </span>
                      Picking a sauce or dressing? Start by examining the
                      ingredient list. Look for sauces and dressings with
                      minimal and recognizable ingredients. If you see
                      unfamiliar or unpronounceable ingredients, it’s a sign to
                      reconsider. Some sauces or dressings may include health
                      claims like “low-sodium” or “heart-healthy.”Lookfor
                      certifications or seals indicating their nutritional
                      quality. The more you practise this, the easier it will
                      become to recognise reputable certification labels.
                    </li>
                  </ol>
                  <span className="lh-lg">
                    In the midst of the supermarket’s aisles, where countless
                    choices beckon, food labels are your compass. It equips you
                    with the knowledge to sift through marketing tactics and
                    uncover the healthiest packaged products. By investing in
                    your food education, you not only improve your own
                    well-being but also contribute to a society where informed
                    choices drive healthier consumption habits. In the complex
                    world of packaged products, education is the beacon of
                    clarity, guiding you towards choices that nourish your body
                    and mind.
                  </span>
                </div>
              </>
            ) : value == 4 ? (
              <>
                <div className="mt-5 mb-5 text-black m-5">
                  <h2 className="fw-samibold">
                    Weight Management Using food Labels: Tips and Tricks
                  </h2>
                  <h6 className="mb-3" style={{ color: "#34b4f6" }}>
                    Leave a Comment / By poorna_satya / October 4, 2023
                  </h6>
                  <span className="lh-lg">
                    Weight management is of paramount importance for overall
                    health and well-being. Maintaining a healthy weight not only
                    contributes to a more active and fulfilling life but also
                    plays a pivotal role in reducing the risk of numerous
                    chronic diseases, including heart disease, diabetes, and
                    certain types of cancer. Beyond physical health, weight
                    management can have profound effects on mental and emotional
                    well-being, boosting self-esteem and confidence. It’s a
                    journey that involves not only the balance of calories but
                    also the adoption of sustainable, health-promoting lifestyle
                    choices. In a world where sedentary lifestyles and unhealthy
                    dietary habits are prevalent, understanding the significance
                    of weight management is a crucial step towards achieving a
                    happier, healthier life.
                  </span>
                  <h6 className="mb-3 mt-3">
                    Now, let’s delve into some helpful tips and tricks for using
                    food labels to support your weight management goals:
                  </h6>
                  <ol>
                    <li className="lh-lg">
                      <span className="fw-bold"> Portion Control : </span>
                      Using the serving size as a reference point is an
                      effective way to keep your portion sizes in check. Many
                      people tend to underestimate the amount they eat, which
                      can lead to unintentional overconsumption of calories.{" "}
                      <span className="fw-bold">
                        {" "}
                        By adhering to the recommended serving sizes, you become
                        more mindful of how much you’re eating,{" "}
                      </span>
                      preventing the common pitfall of oversized portions that
                      can thwart your weight management efforts.
                    </li>
                    <li className="lh-lg">
                      <span className="fw-bold"> Mind the Calories : </span>
                      Calories are the energy units that our bodies use for
                      fuel. When it comes to weight management, paying close
                      attention to the calorie count is crucial. To lose weight,
                      you need to{" "}
                      <span className="fw-bold">
                        {" "}
                        create a calorie deficit by consuming fewer calories
                        than you burn through physical activity and daily
                        functions.{" "}
                      </span>
                      By monitoring your calorie intake, you gain insight into
                      the energy balance needed for effective weight loss. This
                      awareness allows you to make informed decisions about
                      portion sizes and food choices to achieve your weight
                      goals.
                    </li>
                    <li className="lh-lg">
                      <span className="fw-bold"> Fiber Focus : </span>
                      Fiber is a nutritional superhero when it comes to weight
                      management. Opting for foods that are higher in dietary
                      fiber, such as whole grains, fruits, and vegetables,
                      offers a multitude of benefits. Firstly,{" "}
                      <span className="fw-bold">
                        {" "}
                        fiber keeps you feeling full and satisfied for longer
                        periods, reducing the likelihood of overeating or
                        snacking between meals.{" "}
                      </span>
                      Additionally, high-fiber foods tend to have a{" "}
                      <span className="fw-bold">
                        lower calorie density,
                      </span>{" "}
                      which means you can eat more volume without consuming
                      excess calories. This makes them an ideal choice for those
                      looking to shed pounds while maintaining satiety and
                      proper nutrition.
                    </li>
                    <li className="lh-lg">
                      <span className="fw-bold"> Protein Power : </span>
                      Protein is an essential nutrient that plays a crucial role
                      in weight management. Incorporating foods rich in protein,
                      such as lean meats, poultry, fish, beans, and dairy
                      products, can be a game-changer for your weight loss
                      journey.{" "}
                      <span className="fw-bold">
                        {" "}
                        Protein helps preserve and build muscle mass, especially
                        when you’re reducing calorie intake during weight loss.{" "}
                      </span>
                      Muscle tissue burns more calories at rest than fat tissue,
                      so retaining muscle can boost your metabolism.
                      Additionally, protein has a high satiety factor, meaning
                      it helps you feel full and satisfied, reducing the
                      likelihood of overindulging in calorie-rich foods.
                    </li>
                    <li className="lh-lg">
                      <span className="fw-bold"> Sugar Check : </span>
                      Hidden sugars can be a major stumbling block on the path
                      to successful weight management. While it’s crucial to
                      watch for added sugars in obvious sources like sugary
                      beverages and desserts, it’s equally important to
                      scrutinize food labels for hidden sugars in seemingly
                      healthy products. These added sugars can contribute
                      unnecessary calories to your diet and make it harder to
                      shed pounds.{" "}
                      <span className="fw-bold">
                        {" "}
                        Reading ingredient lists and being aware of alternative
                        names for sugars (e.g., sucrose, high-fructose corn
                        syrup) can help you identify and avoid products that are
                        secretly loaded with sugar.{" "}
                      </span>
                      By reducing your sugar intake, you can create a more
                      conducive environment for weight loss and better overall
                      health.
                    </li>
                  </ol>
                  <span className="lh-lg">
                    Mastering food labels is a vital skill for anyone on a
                    weight management journey. Poorna Satya is your ally in the
                    quest for effective weight management: With Poorna Satya’s
                    personalized nutrition advice tailored to your specific
                    health goals and preferences, you’ll be well-equipped to
                    make informed decisions, leading to successful weight
                    management and a healthier, happier you. Whether you want to
                    lose weight or maintain a healthy lifestyle, our platform
                    can provide the guidance you need.
                  </span>
                </div>
              </>
            ) : value == 5 ? (
              <>
                <div className="mt-5 mb-5 text-black m-5">
                  <h2 className="fw-samibold">
                    Veganism vs. Vegetarianism: A Food Education Journey
                  </h2>
                  <h6 className="mb-3" style={{ color: "#34b4f6" }}>
                    Leave a Comment / By poorna_satya / October 4, 2023
                  </h6>
                  <span className="lh-lg">
                    In a country as diverse as India, food choices are deeply
                    intertwined with culture, tradition, and beliefs. The
                    decision to adopt a vegetarian or vegan lifestyle goes
                    beyond personal health; it often reflects ethical,
                    environmental, and philosophical values. In this blog, we’ll
                    explore the distinctions between veganism and vegetarianism,
                    emphasizing the importance of food education in helping
                    individuals make informed choices in the context of India’s
                    rich culinary tapestry.
                  </span>
                  <h6 className="mb-3 mt-3 fw-bold">
                    Vegetarianism: A Familiar Choice
                  </h6>
                  <h6 className="mb-3 mt-3">
                    Vegetarianism has been a prevalent dietary choice in India
                    for centuries. Many Indians follow vegetarianism for
                    cultural or religious reasons, with a significant portion of
                    the population abstaining from meat, poultry, and seafood
                    while still incorporating dairy and other animal products
                    into their diets.
                  </h6>
                  <h6 className="mb-3 mt-3 fw-bold">
                    Veganism: A Growing Movement
                  </h6>
                  <h6 className="mb-3 mt-3">
                    Veganism, on the other hand, takes the principles of
                    vegetarianism a step further. Vegans not only avoid all
                    forms of meat but also eschew dairy, eggs, and any other
                    animal-derived products. This choice is often motivated by
                    ethical concerns about animal welfare, environmental
                    sustainability, and health benefits.
                  </h6>
                  <h6 className="mb-3 mt-3 fw-bold">
                    The Food Education Imperative
                  </h6>
                  <h6 className="mb-3 mt-3">
                    Food is an integral part of the culture no matter where you
                    live, and education plays a pivotal role in helping people
                    understand the nuances of dietary choices. Here’s how food
                    education can contribute to informed decisions regarding
                    veganism and vegetarianism:
                  </h6>
                  <ol>
                    <li className="lh-lg">
                      <span className="fw-bold"> Nutritional Knowledge : </span>
                      Education can provide a comprehensive understanding of the
                      essential nutrients found in both plant-based and
                      animal-based foods. This knowledge is crucial to ensure
                      that individuals on vegan or vegetarian diets get all the
                      necessary nutrients for optimal health.
                    </li>
                    <li className="lh-lg">
                      <span className="fw-bold"> Ethical Awareness : </span>
                      Food education can highlight the ethical considerations
                      associated with animal agriculture, helping individuals
                      make choices aligned with their values. Learning about
                      factory farming practices and the impact of animal
                      agriculture on the environment can be eye-opening.
                    </li>
                    <li className="lh-lg">
                      <span className="fw-bold"> Culinary Skills : </span>
                      Food education can teach people how to prepare delicious
                      and nutritious vegan and vegetarian meals, dispelling the
                      myth that these diets are restrictive or bland.
                    </li>
                    <li className="lh-lg">
                      <span className="fw-bold"> Health Benefits : </span>
                      Understanding the potential health benefits of veganism
                      and vegetarianism, such as reduced risk of chronic
                      diseases, can motivate individuals to make healthier
                      choices.
                    </li>
                  </ol>
                  <h6 className="mb-3 mt-3 fw-bold">
                    Challenges and Misconceptions
                  </h6>
                  <h6 className="mb-3 mt-3">
                    Food education also helps address common challenges and
                    misconceptions associated with veganism and vegetarianism in
                    India:
                  </h6>
                  <ol>
                    <li className="lh-lg">
                      <span className="fw-bold"> Protein Concerns : </span>
                      Many worry about getting enough protein on a plant-based
                      diet. Education can highlight plant-based protein sources
                      like legumes, tofu, and tempeh.
                    </li>
                    <li className="lh-lg">
                      <span className="fw-bold"> Iron and Calcium : </span>
                      Learning about plant-based iron and calcium sources (e.g.,
                      leafy greens, fortified foods, nuts, and seeds) can
                      alleviate concerns about nutrient deficiencies.
                    </li>
                    <li className="lh-lg">
                      <span className="fw-bold"> Cultural Adaptation : </span>
                      Education can promote the adaptation of traditional
                      recipes to suit vegan or vegetarian preferences,
                      preserving cultural heritage while accommodating dietary
                      choices.
                    </li>
                  </ol>
                  <span className="lh-lg">
                    The choice between veganism and vegetarianism is deeply
                    personal and shaped by individual beliefs and values. Food
                    education is the bridge that connects these choices with
                    knowledge, understanding, and the ability to make informed
                    decisions. With food being an integral part of life,
                    fostering food education can empower individuals to embrace
                    veganism, vegetarianism, or any other dietary preference
                    with confidence, ensuring a harmonious coexistence of
                    diverse dietary choices in this culturally rich nation.
                    Ultimately, the journey towards mindful eating is one that
                    can enrich lives and contribute to a healthier planet. Armed
                    with knowledge and the support of Poorna Satya, you can
                    confidently adopt these dietary lifestyles.
                  </span>
                </div>
              </>
            ) : value == 6 ? (
              <>
                <div className="mt-5 mb-5 text-black m-5">
                  <h2 className="fw-samibold">
                    Decoding Food Labels: A Beginner’s Guide to Understanding
                    Nutritional Labels{" "}
                  </h2>
                  <h6 className="mb-3" style={{ color: "#34b4f6" }}>
                    Leave a Comment / By poorna_satya / October 4, 2023
                  </h6>
                  <span className="lh-lg">
                    When it comes to making healthier food choices, one of the
                    most powerful tools at your disposal is the nutritional
                    label on the packaging. However these labels can be a maze
                    of numbers and terminology that seems more like a secret
                    code than a helpful guide. If you’re looking to navigate the
                    world of food labels more effectively, this beginner’s guide
                    is for you.
                  </span>
                  <h6 className="mb-3 mt-3 fw-bold">
                    Understanding Indian Nutritional Labels:
                  </h6>
                  <span className="lh-lg">
                    Nutritional labels in India follow a standard format
                    mandated by the Food Safety and Standards Authority of India
                    (FSSAI). Here is a step-by-step guide on how to read these
                    labels you’ll find on most packaged food products:
                  </span>
                  <div>
                    <img
                      src={Image1}
                      className="img-fluid rounded-4"
                      width="90%"
                    ></img>
                  </div>
                  <ul style={{ listStyleType: "none" }}>
                    <li>
                      <h6 className="mb-3 mt-3 fw-bold">
                        Step 1: Check Serving Size
                      </h6>
                      <h6 className="mb-3 mt-3">
                        The serving size is the first thing you should look for.
                        It tells you the amount the nutritional information is
                        based on. It’s essential to compare this with the amount
                        you plan to eat.
                      </h6>
                      <h6 className="mb-3 mt-3">
                        Example: If the serving size is 1 cup of cereal, and you
                        plan to eat 2 cups, you need to double all the nutrient
                        values on the label.
                      </h6>
                      <h6 className="mb-3 mt-3">
                        Remember: all the information on the label is based on
                        this serving size!
                      </h6>
                    </li>
                    <li>
                      <h6 className="mb-3 mt-3 fw-bold">Step 2: Calories</h6>
                      <h6 className="mb-3 mt-3">
                        Next, check the calorie count per serving. Calories
                        represent the energy you get from the food.
                      </h6>
                      <h6 className="mb-3 mt-3">
                        Example: If a serving has 150 calories, that’s the
                        energy you’ll get from consuming one serving.
                      </h6>
                    </li>
                    <li>
                      <h6 className="mb-3 mt-3 fw-bold">
                        Step 3: Macros – Fats, Carbohydrates, and Proteins
                      </h6>
                      <ul style={{ listStyleType: "none" }}>
                        <li>
                          <h6 className="mb-3 mt-3 fw-bold">a. Fats</h6>
                          <h6 className="mb-3 mt-3">
                            Look for Total Fat, Saturated Fat, Trans Fat, and
                            Unsaturated Fat. Limit saturated and trans fats for
                            a healthier choice.
                          </h6>
                          <h6 className="mb-3 mt-3">
                            Example: Total Fat – 5g, Saturated Fat – 2g
                          </h6>
                          <h6 className="mb-3 mt-3">
                            Be mindful of your daily intake of saturated and
                            trans fats as they can contribute to heart-related
                            issues.
                          </h6>
                        </li>
                        <li>
                          <h6 className="mb-3 mt-3 fw-bold">
                            b. Carbohydrates
                          </h6>
                          <h6 className="mb-3 mt-3">
                            Check Total Carbohydrates and Dietary Fiber. Dietary
                            fiber is essential for digestion.
                          </h6>
                          <h6 className="mb-3 mt-3">
                            Example: Total Carbohydrates – 30g, Dietary Fiber –
                            5g
                          </h6>
                        </li>
                        <li>
                          <h6 className="mb-3 mt-3 fw-bold">c. Proteins</h6>
                          <h6 className="mb-3 mt-3">
                            Look for Protein content. Protein helps with muscle
                            maintenance and satiety.
                          </h6>
                          <h6 className="mb-3 mt-3">Example: Protein – 8g</h6>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <h6 className="mb-3 mt-3 fw-bold">Step 4: Sugars</h6>
                      <h6 className="mb-3 mt-3">
                        Check the Sugars line. Be aware of added sugars; they
                        contribute to calorie intake without adding nutritional
                        value.
                      </h6>
                      <h6 className="mb-3 mt-3">Example: Sugars – 10g</h6>
                      <h6 className="mb-3 mt-3">
                        High sugar content can contribute to various health
                        problems, including obesity and diabetes.
                      </h6>
                    </li>
                    <li>
                      <h6 className="mb-3 mt-3 fw-bold">
                        Step 5: Sodium (Salt)
                      </h6>
                      <h6 className="mb-3 mt-3">
                        Examine the Sodium content. High sodium intake can lead
                        to health issues like high blood pressure.
                      </h6>
                      <h6 className="mb-3 mt-3">Example: Sodium – 300mg</h6>
                    </li>
                    <li>
                      <h6 className="mb-3 mt-3 fw-bold">
                        Step 6: Vitamins and Minerals
                      </h6>
                      <h6 className="mb-3 mt-3">
                        Some labels provide information about vitamins and
                        minerals. They’re typically shown as a percentage of
                        your daily needs (% DV).
                      </h6>
                      <h6 className="mb-3 mt-3">
                        Example: Vitamin C – 10% DV, Calcium – 15% DV
                      </h6>
                    </li>
                    <li>
                      <h6 className="mb-3 mt-3 fw-bold">
                        Step 7: Ingredient List
                      </h6>
                      <h6 className="mb-3 mt-3">
                        Always check the ingredient list. Ingredients are listed
                        in descending order by weight, with the most prominent
                        ingredient listed first.. Look out for allergens or
                        additives you want to avoid.
                      </h6>
                      <h6 className="mb-3 mt-3">
                        Example: Ingredients: Whole Wheat Flour, Sugar, Palm
                        Oil, Raising Agents, Salt, etc.
                      </h6>
                    </li>
                    <li>
                      <h6 className="mb-3 mt-3 fw-bold">
                        Step 8: Additional Information
                      </h6>
                      <h6 className="mb-3 mt-3">
                        Some labels may provide extra information like allergen
                        warnings or health claims. Be attentive to these if they
                        apply to your dietary needs.
                      </h6>
                      <h6 className="mb-3 mt-3">
                        Example: “Contains nuts” or “High in fiber”
                      </h6>
                    </li>
                  </ul>
                  <span className="lh-lg">
                    Navigating nutritional labels can be a daunting task,
                    especially when you’re trying to make healthier choices for
                    yourself and your family. This is where Poorna Satya comes
                    in as your trusted guide. Poorna Satya offers personalized
                    nutrition advice based on your unique health profile and
                    dietary preferences. Whether you’re looking to manage your
                    weight, control diabetes, or simply make better food
                    choices, our platform tailors recommendations to your
                    specific needs. It helps you understand how a product aligns
                    with your dietary goals.
                  </span>
                  <p className="lh-lg mt-3">
                    Poorna Satya is your partner in this journey, simplifying
                    this 8-step journey to just 1. So, the next time you’re at
                    the grocery store, armed with this knowledge and the support
                    of Poorna Satya, you can confidently choose the best options
                    for your well-being.
                  </p>
                </div>
              </>
            ) : null}
          </Col>
          <Col
            lg={12}
            md={12}
            sm={12}
            className="d-flex justify-content-center mb-4 card border-0 shadow rounded-4"
          >
            <div className="mt-5 mb-5 text-black m-5">
              <h4>Leave a Comment</h4>
              <p>
                Your email address will not be published. Required fields are
                marked *
              </p>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Message*</Form.Label>
                  <textarea
                    className="form-control p-4"
                    placeholder="Type here..."
                  ></textarea>
                </Form.Group>
                <Row>
                  <Col lg={4} md={12}>
                    <Form.Group className="mb-3 m-1">
                      <Form.Label>Name*</Form.Label>
                      <Form.Control type="text" placeholder="Name*" />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={12}>
                    <Form.Group className="mb-3 m-1">
                      <Form.Label>Email*</Form.Label>
                      <Form.Control type="email" placeholder="Email*" />
                    </Form.Group>
                  </Col>
                  <Col lg={4} md={12}>
                    <Form.Group className="mb-3 m-1">
                      <Form.Label>Website</Form.Label>
                      <Form.Control type="text" placeholder="Website" />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="mt-3 mb-4">
                  <Form.Group className="">
                    <Form.Check label="Save my name, email, and website in this browser for the next time I comment." />
                  </Form.Group>
                </div>
                <div className="d-flex justify-content-start mt-3">
                  <Button
                    style={{
                      background: "#034053",
                      border: "#034053",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      paddingTop: "14px",
                      paddingBottom: "14px",
                    }}
                    // type="submit"
                  >
                    Post Comment
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BlogInfo;
