import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";

export const globalCheckErr = () => {
    secureLocalStorage.clear()
    toast.error(<div>
        Session expired! <br /> Please login again
    </div>,
        {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
        });
}