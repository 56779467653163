import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

function PrivateRoutes() {
  const [isLogin, setIsLogin] = useState(false);
  let navigate = useNavigate();
  useEffect(() => {
    if (secureLocalStorage.getItem("token") !== null) {
      setIsLogin(true);
    } else {
      navigate("/notfound");
    }
  }, [secureLocalStorage.getItem("token")]);
  return <>{isLogin === true ? <Outlet /> : null}</>;
}

export default PrivateRoutes;
