import axios from "axios";
import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import secureLocalStorage from "react-secure-storage";
import jwt_decode from "jwt-decode";
import "../Login/Login.css";
import LoggingInModal from "../LoggingInModal/LoggingInModal";
import VerifyOtpMob from "../VerifyOtpMob/VerifyOtpMob";
import { AiOutlineCloseCircle } from "react-icons/ai";

function LoginOtpMob({ closemodal, closePopUp }) {
  // let {id} = useParams()
  const { t } = useTranslation();
  const [number, setNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [socialLogging, setSocialLogging] = useState(false);
  const [isotpVerify, setIsotpVerify] = useState(false);
  const [publicId, setPublicId] = useState(null);
  const navigate = useNavigate();

  // API for Login with Mobile no and OTP
  const handleSubmit = (e) => {
    e.preventDefault();
    if (number.length < 10) {
      toast.error("Please enter a valid mobile number", {
        position: "top-right",
        autoClose: 2000,
        theme: "colored",
      });
    } else {
      setIsLoading(true);
      let url = process.env.REACT_APP_BASEURL + "mobile_login/";
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json",
        },
      };
      let data = {
        phone_no: number,
      };
      axios
        .post(url, data, config)
        .then((res) => {
          setIsLoading(false);
          toast.success("OTP sent successfully!", {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });

          setIsotpVerify(true);
          setPublicId(res.data.public_id);
          // navigate(`/verify-mob-otp/${res.data.public_id}`)
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(`${err.response.data.error}`, {
            position: "top-right",
            autoClose: 1000,
            theme: "colored",
          });
        });
    }
  };
  const [user, setUser] = useState({});

  // API for Login with google social login
  function handleCallbackResponse(response) {
    setSocialLogging(true);
    var userObject = jwt_decode(response.credential);
    setUser(userObject);
    if (user) {
      let googleURl = `${process.env.REACT_APP_BASEURL}social_login/`;
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json",
        },
      };
      let userData = {
        nbf: userObject.nbf,
        picture: userObject.picture,
        name: userObject.name,
        email: userObject.email,
      };
      axios
        .post(googleURl, userData, config)
        .then((res) => {
          setSocialLogging(false);
          toast.success("Login success!", {
            position: "top-right",
            autoClose: 2000,
            theme: "colored",
          });
          secureLocalStorage.setItem("token", res.data.access_token);
          secureLocalStorage.setItem("fullname", res.data.fullname);
          //This is to redirect user back to the same page he loggedin
          if (
            secureLocalStorage.getItem("prod_id") &&
            secureLocalStorage.getItem("attr_id") !== null &&
            localStorage.getItem("homePageFlag")
          ) {
            navigate(
              `/product/${secureLocalStorage.getItem(
                "prod_id"
              )}/${secureLocalStorage.getItem(
                "attr_id"
              )}?homePageFlag=${localStorage.getItem("homePageFlag")}`
            );
          } else if (
            secureLocalStorage.getItem("prod_id") &&
            secureLocalStorage.getItem("attr_id") !== null
          ) {
            navigate(
              `/product/${secureLocalStorage.getItem(
                "prod_id"
              )}/${secureLocalStorage.getItem("attr_id")}`
            );
          } else if (
            secureLocalStorage.getItem("public_id") &&
            localStorage.getItem("homePageFlag")
          ) {
            navigate(
              `/compare/${JSON.parse(
                secureLocalStorage.getItem("public_id")
              )}?homePageFlag=${localStorage.getItem("homePageFlag")}`
            );
          } else if (secureLocalStorage.getItem("public_id")) {
            navigate(
              `/compare/${JSON.parse(secureLocalStorage.getItem("public_id"))}`
            );
          } else {
            navigate("/");
          }
          closemodal(false);
        })
        .catch((err) => {
          setSocialLogging(false);
          if (err.code === "ECONNABORTED") {
            toast.error("Someting went wrong", {
              position: "top-right",
              autoClose: 3000,
              theme: "colored",
            });
          }
          toast.error(`${err.response.data.error}`, {
            position: "top-right",
            autoClose: 3000,
            theme: "colored",
          });
        });
    }
  }
  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleCallbackResponse,
    });

    google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      theme: "outline",
      size: "large",
    });
    const script = document.createElement("script");
    script.src = "https://connect.facebook.net/en_US/sdk.js";
    script.async = false;
    document.body.appendChild(script);

    script.onload = () => {
      window.FB.init({
        appId: "278864317867115",
        cookie: true,
        xfbml: true,
        version: "v18.0",
      });

      window.FB.getLoginStatus(function (response) {
        statusChangeCallback(response);
      });
    };
  }, []);

  const checkLoginState = () => {
    window.FB.getLoginStatus(function (response) {
      statusChangeCallback(response);
    });
  };
  const testAPI = () => {
    window.FB.api("/me", function (response) {
      console.log("Successful login for: " + JSON.parse(response));
      document.getElementById("status").innerHTML =
        "Thanks for logging in, " + response.name + "!";
    });
  };
  const statusChangeCallback = (response) => {
    if (response.status === "connected") {
      testAPI();
    } else {
      document.getElementById("status").innerHTML = "";
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center w-100 ">
        <Container
          className="mx-auto d-flex justify-content-center flex-column  d-none d-lg-flex d-md-none d-sm-none "
          style={{ height: "75vh" }}
        >
          <Row>
            <Col lg={12} className="d-flex justify-content-center">
              <div>
                {" "}
                <img
                  src={require("../../assets/images/loginlogo.png")}
                  alt="login"
                ></img>
              </div>
            </Col>
          </Row>
        </Container>
        <Container
          className="mx-auto d-flex justify-content-center flex-column login-background"
          style={{ height: "75vh" }}
        >
          <div
            className="text-end btn iconbtn"
            onClick={() => {
              closemodal(false);
              closePopUp(false);
            }}
          >
            <AiOutlineCloseCircle className="fs-4" />
          </div>
          <LoggingInModal flag={socialLogging} />

          {isotpVerify ? (
            <VerifyOtpMob
              public_id={publicId}
              closeOtp={closemodal}
            ></VerifyOtpMob>
          ) : (
            <Row className=" align-items-center d-flex justify-content-center">
              <Col lg={12}>
                <h2 className="text-center ">Login with OTP</h2>
                <p className="mt-3 text-center">Enter your mobile number</p>

                <form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <Row className="d-flex justify-content-center">
                    <Col lg={7}>
                      <input
                        autoFocus
                        type="text"
                        className="form-control "
                        value={number}
                        maxLength={10}
                        onInput={(e) => {
                          if (e.target.value.length > e.target.maxLength) {
                            e.target.value = e.target.value.slice(
                              0,
                              e.target.maxLength
                            );
                          }
                          setNumber(e.target.value);
                        }}
                        required
                        placeholder="Enter 10 digit mobile number"
                      />
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-center mt-4 mb-3">
                    {isLoading === false ? (
                      <button
                        type="submit"
                        className="btn text-white col-7 "
                        style={{ backgroundColor: "#5CB85C" }}
                      >
                        Send OTP
                      </button>
                    ) : (
                      <button
                        disabled
                        className="btn text-white col-7"
                        style={{ backgroundColor: "#5CB85C" }}
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>{" "}
                        Sending...{" "}
                      </button>
                    )}
                  </Row>

                  <div className="d-flex justify-content-around">
                    <hr style={{ width: "45%" }} />
                    <p>Or </p>
                    <hr style={{ width: "45%" }} />
                  </div>
                </form>
                <Row>
                  <div
                    id="signInDiv"
                    className="mt-3 col d-flex  justify-content-center"
                  ></div>
                </Row>
                {/* <Row className="text-center mt-3">
                  <div id="status"></div>
                  <div
                    className="fb-login-button"
                    data-scope="public_profile,email"
                    data-size="large"
                    data-button-type="login_with"
                    data-auto-logout-link="false"
                    data-use-continue-as="true"
                    onClick={checkLoginState}
                  >
                    Facebook Login
                  </div>
                </Row> */}
                {/* <p className="mt-2 text-center">
                                {t("login.not_a_member")} ?{" "}
                                <span
                                    className="text-primary "
                                    style={{ cursor: "pointer" }}
                                    onClick={() => navigate("/signup")}
                                >
                                    <u>{t("login.register")} </u>
                                </span>
                            </p>                             */}
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </>
  );
}

export default LoginOtpMob;
