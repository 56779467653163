import React from 'react'
import { Outlet, ScrollRestoration } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Footer from './Footer/Footer'
import Navigation from './Navigation/Navigation'
// This component is just for including nav bar in every route
// Here "Outlet" will bring the other routes when needed  
function RootLayout() {
    // useEffect(() => {
    //     if (secureLocalStorage.getItem('token')) {
    //         setIsLogin(true)
    //     }
    //     else {
    //         setIsLogin(false)
    //     }
    // }, secureLocalStorage.getItem('token'))
    return (
        <>
            <ScrollRestoration />
            <ToastContainer />
            <nav >
                <Navigation />
            </nav>
            <main style={{ minHeight: "100vh", marginTop: 0}}>
                <Outlet />
            </main>
            <footer >
                <Footer />
            </footer>
        </>


    )
}

export default RootLayout