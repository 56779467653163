import React, { useState } from "react";
import { Col, Container, Form, FormControl, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { useGeolocated } from "react-geolocated";
import SuggestedProduct from "../Products/SuggestedProduct";
import "./HomePage.css";
import FoodContent from "./FoodContent";
import TrendingProduct from "../Products/TrendingProducts";
import { BsFacebook, BsYoutube, BsInstagram } from "react-icons/bs";
import { AiFillTwitterCircle, AiOutlineInstagram } from "react-icons/ai";
import { FaLinkedinIn, FaQuora } from "react-icons/fa";

function Homepage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
    });
  secureLocalStorage.setItem("isGeolocationAvailable", isGeolocationAvailable);
  secureLocalStorage.setItem("isGeolocationEnabled", isGeolocationEnabled);
  secureLocalStorage.setItem("coords", coords);
  let handleSearch = (e) => {
    e.preventDefault();
    const pattern = /^[A-Za-z0-9_'" ]+$/;
    if (pattern.test(search.trim())) {
      navigate(`/products/${search}`, { state: search });
      localStorage.setItem("search", search);
    }
    localStorage.removeItem("product_veg/non-veg");
    localStorage.removeItem("religious_sentiments");
    localStorage.removeItem("foodCategory"); 
  };
  return (
    <Container
      className="d-flex flex-column justify-content-center align-items-center  background-img "
      fluid
    >
      <div>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "63vh" }}
        >
          <Row className="d-flex  justify-content-center">
            <div className="d-flex justify-content-center">
              <div className="d-flex flex-column">
                <span className="heading-font ">
                  Discover the Hidden Truths of Your Food
                </span>
                <p className="heading-font-p mt-3">
                  Explore Comprehensive Nutrition, Ingredients and Comparisons
                  for Every Product You Love
                </p>
              </div>
              {/* &nbsp;
                        &nbsp;
                        <img 
                            alt="Logo text"
                            src={LogoText}
                            height="60px"
                            width="80%"
                        /> */}
            </div>
            <Col>
              <Form>
                <Row className="d-flex justify-content-center align-items-center ">
                  <Col className="mt-2" lg={4} md={5}>
                    <FormControl
                      style={{ boxShadow: "none" }}
                      className="search-inputs"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      type="text"
                      placeholder="Search for Products or Brand"
                    />
                  </Col>
                  <Col className="marg_sm text-center mt-2" lg={1} md={5}>
                    <button
                      type="submit"
                      className="search-button btn-lg"
                      onClick={handleSearch}
                    >
                      Search{" "}
                    </button>
                  </Col>
                </Row>
              </Form>
            </Col>
            <div className="d-flex justify-content-center">
              <div className="d-flex flex-column align-items-center">
                <span
                  className="mt-4 text-center w-75"
                  style={{ color: "black" }}
                >
                  Type in a product or brand name, hit search, and dive into the
                  complete truth about what you're consuming.
                  {/* <p style={{ color: "black" }} className="mt-2"> */}
                  {/* </p> */}
                </span>
              </div>
            </div>
          </Row>
        </div>
      </div>
      <div className="container">
        <div className="margin-content">
          <div className="d-flex justify-content-center align-items-center ">
            <FoodContent />
          </div>
        </div>
        <div className="mt-3">
          {/* <div style={{ background: 'linear-gradient(258.6deg, #6F94A0 0.78%, #C09F6D 100%)', height: "100%", padding: '20px' }}> */}

          <div className="mt-2">
            <SuggestedProduct />
          </div>
          {/* </div> */}
          {/* <div className='d-flex justify-content-center mt-4  w-100 p-5  '>
                    <Carousel variant='dark' fade interval={3000} controls={false} indicators={true} touch >
                        <Carousel.Item className='d-block'>
                            <div className='p-4 ' style={{ backgroundColor: 'transparent' }}>
                                <Card.Body style={{ color: 'black' }} >
                                    <h3>{t('homepage.body.card.card_head_1')}</h3>
                                    <p>{t('homepage.body.card.card_body_1')}</p>
                                </Card.Body>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item className='d-block '>
                            <div className='p-4 ' style={{ backgroundColor: 'transparent' }}>
                                <Card.Body style={{ color: 'black' }} >
                                    <h3 style={{ color: 'black' }} >{t('homepage.body.card.card_head_2')}</h3>
                                    <p >{t('homepage.body.card.card_body_2')}</p>
                                </Card.Body>
                            </div>
                        </Carousel.Item>
                    </Carousel>
                </div> */}
          {/* <div className='mt-3 d-flex justify-content-center'>
                <span style={{ fontSize: '25px', fontWeight: "bold"}}>Show Testimonials</span>
            </div> */}
          {/* why poorna satya start */}
          {/* <div className='d-flex align-items-center '>
                    <Row className='d-flex justify-content-center align-items-center '>
                        <Col lg={5} sm={12} className='d-flex flex-column justify-content-center'>
                            <span style={{ fontWeight: 500, fontSize: "25px" }}>{t("homepage.body.complete_truth")}</span>
                            <p style={{ fontSize: '18px', fontWeight: '400', textAlign: 'justify', paddingTop: '20px' }}>Numquam blanditiis harum quisquam sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum</p>
                        </Col>
                        <Col lg={5} sm={12} className='d-flex justify-content-center'>
                            <img src={require('../../assets/gifs/find_product.gif')} alt="cart" style={{ width: '80%' }}></img>
                        </Col>
                    </Row>
                </div> */}
          {/* why poorna satya end */}
          <div>
            <TrendingProduct />
          </div>
          <div>
            <Row className="box_card d-flex justify-content-center align-items-center mb-4 rounded-3 p-3">
              <Col className="text-center">
                <div
                  style={{ color: "#034053" }}
                  className="h3 fw-bold mt-3 mb-3"
                >
                  Join the Food Revolution
                </div>
                <span style={{ color: "black" }} className="text-start">
                  Be part of the movement towards healthier living and informed
                  choices. Ready to know what you're consuming like never
                  before? Start your journey now!
                </span>
                {/* <p style={{ color: "#616161" }} className="mt-2">
                                    {" "}
                                    Start your journey by searching for the truth about your food.
                                </p> */}
                <div
                  style={{ color: "#034053" }}
                  className="h5 fw-bold mt-3 mb-3"
                >
                  <span className="align-middle">Follow Us</span>
                  <span className="ms-3">
                    <a
                      href="https://www.instagram.com/ps_thecompletetruth/"
                      style={{
                        textDecoration: "none",
                        color: "#003366",
                        fontSize: "20px",
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <BsInstagram className="fs-5 me-2" />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/poorna-satya"
                      style={{
                        textDecoration: "none",
                        color: "#003366",
                        fontSize: "20px",
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaLinkedinIn className="fs-5 me-2" />
                    </a>
                    <a
                      href="https://www.quora.com/profile/Poorna-Satya-1"
                      style={{
                        textDecoration: "none",
                        color: "#003366",
                        fontSize: "20px",
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaQuora className="fs-5 me-2" />
                    </a>
                    <a
                      href="https://www.youtube.com/@PoornaSatyaTheCompleteTruth/featured"
                      style={{
                        textDecoration: "none",
                        color: "#003366",
                        fontSize: "20px",
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <BsYoutube className="fs-5 me-2" />
                    </a>
                    <a
                      href="https://www.facebook.com/ps.thecompletetruth/"
                      style={{
                        textDecoration: "none",
                        color: "#003366",
                        fontSize: "20px",
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <BsFacebook className="fs-5 me-2" />
                    </a>

                    <a
                      href="https://twitter.com/poorna__satya"
                      style={{
                        textDecoration: "none",
                        color: "#003366",
                        fontSize: "20px",
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <AiFillTwitterCircle className="fs-5 me-2" />
                    </a>
                  </span>
                </div>
              </Col>
            </Row>
          </div>
          {/* <div className='d-flex justify-content-center   w-100 ps-5 pe-5 carousel-bg mt-5 ' >

                    <Carousel variant='dark' controls={true} indicators={true} touch >
                        <Carousel.Item className=' w-100'>
                            <div className='p-4  ' style={{ backgroundColor: 'transparent' }}>
                                <Card.Body style={{ color: 'black' }} className='p-4 d-flex justify-content-center '>
                                    <Row className='d-flex justify-content-center'>
                                        <Col className='d-flex flex-row ' lg={6}>
                                            <div>
                                                <img src={require('../../assets/images/user_1.png')} style={{ width: '150px' }}></img>
                                            </div>
                                            <div>
                                                <h3>Rakesh Ahuja</h3>
                                                <span className='text-white'>"Purna Satya is the one stop shop to know what we are eating and change eating habits."
                                                </span>
                                            </div>


                                        </Col>

                                    </Row>
                                </Card.Body>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item className=' w-100'>
                            <div className='p-4  ' style={{ backgroundColor: 'transparent' }}>
                                <Card.Body style={{ color: 'black' }} className='p-4 d-flex justify-content-center '>
                                    <Row className='d-flex justify-content-center'>
                                        <Col className='d-flex flex-row ' lg={6}>
                                            <div>
                                                <img src={require('../../assets/images/user_2.png')} style={{ width: '150px' }}></img>
                                            </div>
                                            <div>
                                                <h3>Saloni Singh</h3>
                                                <span className='text-white'>"I am able to compare food products in a simple click of the button and can make informed food choices"

                                                </span>
                                            </div>


                                        </Col>

                                    </Row>
                                </Card.Body>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item className=' w-100'>
                            <div className='p-4  ' style={{ backgroundColor: 'transparent' }}>
                                <Card.Body style={{ color: 'black' }} className='p-4 d-flex justify-content-center '>
                                    <Row className='d-flex justify-content-center'>
                                        <Col className='d-flex flex-row ' lg={6}>
                                            <div>
                                                <img src={require('../../assets/images/user_3.png')} style={{ width: '150px' }}></img>
                                            </div>
                                            <div>
                                                <h3>Jai Prakash</h3>
                                                <span className='text-white'>"I always check Purna Satya before buying packaged products, its most exhaustive and repliable source of foods and beverages quality"


                                                </span>
                                                
                                            </div>


                                        </Col>

                                    </Row>
                                </Card.Body>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item className=' w-100'>
                            <div className='p-4  ' style={{ backgroundColor: 'transparent' }}>
                                <Card.Body style={{ color: 'black' }} className='p-4 d-flex justify-content-center '>
                                    <Row className='d-flex justify-content-center'>
                                        <Col className='d-flex flex-row ' lg={6}>
                                            <div>
                                                <img src={require('../../assets/images/user_4.png')} style={{ width: '150px' }}></img>
                                            </div>
                                            <div>
                                                <h3>Puneet Gupta</h3>
                                                <span className='text-white'>"I use the content and rating of Purna Satya to build awareness amongst my friends and colleagues."


                                                </span>
                                            </div>


                                        </Col>

                                    </Row>
                                </Card.Body>
                            </div>
                        </Carousel.Item>
                    </Carousel>
                </div> */}
        </div>
      </div>
    </Container>
  );
}

export default Homepage;
